/* eslint-disable import/no-cycle */
/* eslint-disable import/no-unresolved */
import {
  Box,
  Flex,
  Image,
  ImageProps,
  SimpleGrid,
  SimpleGridProps,
  Text,
} from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import useApp from "../../../contexts/AppContext";

interface PhotoGridProps {
  title: string;
  subtitle?: string;
  imageUrls: string[];
  gridProps?: SimpleGridProps;
  imageProps?: ImageProps;
  swipperImageProps?: ImageProps;
}

export default function PhotoGrid({
  title,
  subtitle = "Click on the photo to preview in full",
  imageUrls,
  gridProps = {},
  imageProps = {},
  swipperImageProps = {},
}: PhotoGridProps) {
  const { setImagePreviewUrl } = useApp();
  const onImageClick = (newImageUrl: string) => {
    setImagePreviewUrl(newImageUrl);
  };

  return (
    <Box minWidth={0}>
      <Flex direction="column">
        <Text as="h2" textStyle="h2">
          {title}
        </Text>
        <Text textStyle="sub-heading" mt={0.5}>
          {subtitle}
        </Text>
      </Flex>

      <Box
        display={{ base: "inherit", md: "none" }}
        mt={6}
        overflow="hidden"
        sx={{
          // CSS workaround for incorrect width https://github.com/nolimits4web/swiper/issues/3599
          ".swiper-container": {
            maxWidth: "87vw",
          },
        }}
      >
        <Swiper
          modules={[Navigation, Scrollbar]}
          scrollbar={{ hide: true }}
          slidesPerView={1.2}
          spaceBetween={16}
          className="swiper-container"
        >
          {imageUrls.map((imageUrl: string) => (
            <SwiperSlide key={imageUrl}>
              <Flex
                justifyContent="left"
                overflow="hidden"
                backgroundColor="white"
                borderWidth="1px"
                borderColor="lightGray"
                borderRadius={16}
              >
                <Image
                  src={imageUrl}
                  fit="cover"
                  h={200}
                  w="100%"
                  onClick={() => onImageClick(imageUrl)}
                  {...swipperImageProps}
                />
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <Box
        mt={6}
        display={{ base: "none", md: "inherit" }}
        overflow="hidden"
        borderWidth="1px"
        borderColor="lightGray"
        borderRadius={16}
        backgroundColor="lightGray"
      >
        <SimpleGrid columns={2} gap="1px" {...gridProps}>
          {imageUrls.map((imageUrl: string) => (
            <Image
              src={imageUrl}
              key={imageUrl}
              fit="cover"
              h={360}
              w="100%"
              cursor="pointer"
              onClick={() => onImageClick(imageUrl)}
              backgroundColor="white"
              {...imageProps}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}
