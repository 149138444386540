/* eslint-disable import/no-cycle */
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import useAuth from "../../hooks/useAuth";
import EmailLogin from "../EmailLogin";
import PandaLogo from "../PandaLogo";
import SmsLogin from "../SmsLogin";

export default function LoginModal() {
  const { showLoginModal, setShowLoginModal, loginWithEmail } = useAuth();

  const onModalClose = () => {
    setShowLoginModal(false);
  };

  return (
    <>
      <Modal isOpen={showLoginModal} onClose={onModalClose} size="lg">
        <ModalOverlay />
        <ModalContent pb={8}>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <Stack
              direction={{ base: "column" }}
              h="100%"
              spacing={{ base: 4 }}
            >
              <Center>
                <PandaLogo boxSize={105} />
              </Center>

              {!loginWithEmail && <SmsLogin />}
              {loginWithEmail && <EmailLogin />}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
