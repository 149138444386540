/* eslint-disable import/no-cycle */
import {
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import useApp from "../../../contexts/AppContext";

export default function ImagePreviewModal() {
  const { imagePreviewUrl, setImagePreviewUrl } = useApp();

  const onModalClose = () => {
    setImagePreviewUrl(null);
  };

  if (!imagePreviewUrl) {
    return <></>;
  }

  return (
    <Modal
      id="preview-image"
      isOpen={!!imagePreviewUrl}
      onClose={onModalClose}
      size="3xl"
      isCentered
    >
      <ModalOverlay backgroundColor="#00000060" />
      <ModalContent borderRadius={16} overflow="hidden" maxHeight="100vh">
        <ModalCloseButton
          size="md"
          backgroundColor="white"
          borderWidth={1}
          borderColor="lightGray"
          borderRadius="50%"
        />
        <ModalBody padding={0}>
          <Center>
            <Image src={imagePreviewUrl} key={imagePreviewUrl} fit="contain" />
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
