import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  BoxProps,
  Button,
  ChakraProps,
  Input,
  Select,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { StripeError, loadStripe } from "@stripe/stripe-js";
import { ReactNode, Ref, forwardRef, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";

import StripeCardForm from "./StripeCardForm";
import USA_STATES from "../../constants/countryStates";
import isValidPostalCode from "../../utils/postalCodeValidation";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
);

export type PaymentMethodData = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address?: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
};

type PaymentMethodFormProps = {
  clientSecret: string;
  submitLabel?: string | ReactNode;
  onFormSubmit: (data: PaymentMethodData) => void;
  isLoading?: boolean;
  stripeError?: StripeError | null;
  initialEmail?: string;
  initialFirstName?: string;
  initialLastName?: string;
  initialPhoneNumber?: string;
  buttonStyle?: ChakraProps;
  addressRequired?: boolean;
} & BoxProps;

function PaymentMethodForm(
  props: PaymentMethodFormProps,
  ref: Ref<unknown> | undefined,
): JSX.Element {
  const {
    clientSecret,
    isLoading = false,
    onFormSubmit,
    submitLabel = "Pay Now",
    stripeError,
    initialEmail,
    initialFirstName,
    initialLastName,
    initialPhoneNumber,
    buttonStyle = {},
    addressRequired = false,
    ...style
  } = props;
  const [stripeValid, setStripeValid] = useState(false);

  const [firstName, setFirstName] = useState<string>(initialFirstName || "");
  const [lastName, setLastName] = useState<string>(initialLastName || "");
  const [phoneNumber, setPhoneNumber] = useState<string>(
    initialPhoneNumber || "",
  );
  const [email, setEmail] = useState<string>(initialEmail || "");
  const [line1, setLine1] = useState<string>("");
  const [line2, setLine2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");

  const [pristineStatus, setPristineStatus] = useState({
    phoneNumber: true,
    firstName: true,
    lastName: true,
    email: true,
    line1: true,
    line2: true,
    city: true,
    state: true,
    postalCode: true,
    country: true,
  });

  const onInputFocus = (e: any) => {
    setPristineStatus((current) => {
      return { ...current, [e.target.name]: false };
    });
  };

  const handleSubmit = () => {
    if (!onFormSubmit) {
      return;
    }

    onFormSubmit({
      firstName,
      lastName,
      phoneNumber,
      email,
      address: {
        line1,
        line2,
        city,
        state,
        postalCode,
        country: "US",
      },
    });
  };

  const isPayNowButtonDisabled =
    !firstName.length ||
    !lastName.length ||
    !phoneNumber.length ||
    !email.length ||
    !isValidPhoneNumber(phoneNumber) ||
    (addressRequired &&
      (!line1 || !city || !state || !isValidPostalCode(postalCode))) ||
    !stripeValid ||
    isLoading;

  return (
    <VStack
      w="full"
      alignItems="start"
      spacing={addressRequired ? 12 : 2}
      {...style}
    >
      {stripeError && stripeError.message && stripeError.message.length > 0 && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>{stripeError.message}</AlertTitle>
        </Alert>
      )}
      <VStack w="full" alignItems="start" spacing={4} {...style}>
        {addressRequired && (
          <Text fontWeight="semibold" textStyle="h4">
            Contact details
          </Text>
        )}
        <SimpleGrid w="full" columns={2} spacing={2}>
          {!initialFirstName && (
            <Box>
              <Box mb={2}>
                <Text fontWeight="semibold">First Name</Text>
              </Box>
              <Input
                name="firstName"
                value={firstName}
                size="lg"
                onChange={(e) => setFirstName(e.target.value)}
                onFocus={onInputFocus}
                isInvalid={!pristineStatus.firstName && !firstName.length}
              />
            </Box>
          )}
          {!initialLastName && (
            <Box>
              <Box mb={2}>
                <Text fontWeight="semibold">Last Name</Text>
              </Box>
              <Input
                name="lastName"
                value={lastName}
                size="lg"
                onChange={(e) => setLastName(e.target.value)}
                onFocus={onInputFocus}
                isInvalid={!pristineStatus.lastName && !lastName.length}
              />
            </Box>
          )}
        </SimpleGrid>
        {!initialEmail && (
          <Box w="full">
            <Box mb={2}>
              <Text fontWeight="semibold">Work E-Mail</Text>
            </Box>
            <Input
              type="email"
              name="email"
              value={email}
              onChange={(e) =>
                setEmail(e.target.value?.trim().toLowerCase() || "")
              }
              onFocus={onInputFocus}
              isInvalid={!pristineStatus.email && !email.length}
            />
          </Box>
        )}
        {!initialPhoneNumber && (
          <Box w="full">
            <Box mb={2}>
              <Text fontWeight="semibold">Mobile Number</Text>
            </Box>
            <PhoneInput
              name="phoneNumber"
              inputComponent={Input}
              country="US"
              value={phoneNumber}
              size="lg"
              onChange={(e) => setPhoneNumber(e || "")}
              onFocus={onInputFocus}
              placeholder="(444) 555-6789"
              isInvalid={
                !pristineStatus.phoneNumber && !isValidPhoneNumber(phoneNumber)
              }
            />
          </Box>
        )}
      </VStack>
      <VStack w="full" alignItems="start" spacing={4} {...style}>
        {addressRequired && (
          <>
            <Text fontWeight="semibold" textStyle="h4">
              Home address
            </Text>
            <VStack w="full" alignItems="start" spacing={2}>
              <Box>
                <Text fontWeight="semibold">Address</Text>
              </Box>
              <Input
                type="text"
                name="line1"
                size="lg"
                placeholder="Street address"
                value={line1}
                onChange={(e) => setLine1(e.target.value || "")}
                onFocus={onInputFocus}
                isInvalid={!pristineStatus.line1 && !line1.length}
              />

              <Input
                type="text"
                name="line2"
                size="lg"
                placeholder="Apt, suite, unit, building, floor, etc. (optional)"
                value={line2}
                onChange={(e) => setLine2(e.target.value || "")}
                onFocus={onInputFocus}
              />
            </VStack>

            <SimpleGrid
              w="full"
              spacing={2}
              templateColumns={{
                base: "1fr",
                md: "2fr 1fr",
              }}
            >
              <Box>
                <Box mb={2}>
                  <Text fontWeight="semibold">City</Text>
                </Box>
                <Input
                  name="city"
                  value={city}
                  size="lg"
                  onChange={(e) => setCity(e.target.value || "")}
                  onFocus={onInputFocus}
                  isInvalid={!pristineStatus.city && !city.length}
                />
              </Box>

              <Box>
                <Box mb={2}>
                  <Text fontWeight="semibold">State</Text>
                </Box>
                <Select
                  id="stateSelector"
                  size="lg"
                  name="state"
                  placeholder="Select"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  onFocus={onInputFocus}
                  isInvalid={!pristineStatus.state && !state.length}
                >
                  {USA_STATES.map((countryState) => (
                    <option value={countryState.value} key={countryState.value}>
                      {countryState.label}
                    </option>
                  ))}
                </Select>
              </Box>
            </SimpleGrid>

            <SimpleGrid
              w="full"
              spacing={2}
              templateColumns={{
                base: "1fr",
                md: "2fr 3fr",
              }}
            >
              <Box>
                <Box mb={2}>
                  <Text fontWeight="semibold">Zip code</Text>
                </Box>
                <Input
                  name="postalCode"
                  value={postalCode}
                  size="lg"
                  onChange={(e) => setPostalCode(e.target.value)}
                  onFocus={onInputFocus}
                  isInvalid={
                    !pristineStatus.postalCode && !isValidPostalCode(postalCode)
                  }
                />
              </Box>

              <Box>
                <Box mb={2}>
                  <Text fontWeight="semibold">Country</Text>
                </Box>
                <Input
                  name="country"
                  value="United States"
                  size="lg"
                  backgroundColor="#F6F6F4"
                  color="#251101"
                  isReadOnly
                  style={{ pointerEvents: "none" }}
                />
              </Box>
            </SimpleGrid>
          </>
        )}
      </VStack>
      <VStack w="full" alignItems="start" spacing={4} {...style}>
        {addressRequired && (
          <Text fontWeight="semibold" textStyle="h4">
            Payment information
          </Text>
        )}
        <Box w="full" pb={4}>
          <Box mb={2}>
            <Text fontWeight="semibold">Card Information</Text>
          </Box>
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
              appearance: {
                theme: "stripe",
                variables: {
                  colorText: "red",
                },
              },
              fonts: [
                {
                  cssSrc:
                    "https://fonts.googleapis.com/css2?family=Work+Sans:wght@350&display=swap",
                },
              ],
            }}
          >
            <StripeCardForm
              clientSecret={clientSecret}
              onValid={(valid) => {
                setStripeValid(valid);
              }}
              ref={ref}
              stripeError={stripeError}
            />
          </Elements>
        </Box>
        <Button
          size="lg"
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={isPayNowButtonDisabled}
          {...buttonStyle}
        >
          {submitLabel}
        </Button>
      </VStack>
    </VStack>
  );
}

export default forwardRef(PaymentMethodForm);
