import { createIcon } from "@chakra-ui/icons";

const RidepandaTextIcon = createIcon({
  displayName: "RidepandaTextIcon",
  viewBox: "0 0 87 19",
  path: [
    <path
      d="M12.974 1.93356C12.8956 1.64767 13.0653 1.35266 13.3519 1.2768L15.3376 0.751163C15.6214 0.676039 15.9126 0.844399 15.989 1.12784L16.4404 2.80109C16.5169 3.08502 16.3492 3.37736 16.0655 3.4546L14.091 3.99204C13.8072 4.06928 13.5145 3.90222 13.4366 3.61861L12.974 1.93356Z"
      fill="#221203"
    />,
    <path
      d="M12.099 13.3333C12.0911 13.0914 12.2471 12.8746 12.4788 12.8051L13.3288 12.55L13.3288 8.23437C13.3288 7.97389 13.3052 7.80813 13.2578 7.73709C13.2223 7.65421 13.1216 7.56541 12.9559 7.47069L12.4739 7.20779C12.2913 7.10821 12.1833 6.91159 12.1971 6.7041L12.2359 6.12281C12.252 5.88101 12.4293 5.68031 12.6674 5.63514C13.4562 5.4855 14.0792 5.37511 14.5365 5.30397C14.9221 5.23691 15.284 5.19062 15.6221 5.1651C15.8617 5.14702 16.0778 5.30336 16.142 5.53492C16.2299 5.85195 16.2868 6.10056 16.3125 6.28077L16.3125 11.964C16.3125 12.2126 16.3303 12.3784 16.3658 12.4612C16.4013 12.5441 16.502 12.6329 16.6677 12.7276L17.2525 13.0618C17.4275 13.1618 17.5312 13.352 17.5205 13.5533L17.4939 14.052C17.4788 14.3351 17.2448 14.5569 16.9613 14.5569L12.6552 14.5569C12.3674 14.5569 12.1315 14.3286 12.1221 14.041L12.099 13.3333Z"
      fill="#221203"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.270757 13.1676C0.258516 12.9179 0.421391 12.6932 0.662525 12.6271L1.54629 12.385L1.54628 5.61844C1.54628 5.3106 1.5226 5.10932 1.47524 5.0146C1.42788 4.90804 1.30356 4.81332 1.10228 4.73044L0.475718 4.38101C0.294588 4.28 0.188442 4.08314 0.203576 3.8763L0.254103 3.18576C0.272345 2.93646 0.461301 2.73287 0.709002 2.69924C2.3338 2.47865 3.90346 2.36836 5.41796 2.36836C6.69669 2.36836 7.72677 2.4986 8.50821 2.75908C9.28965 3.00772 9.85796 3.40436 10.2132 3.949C10.5802 4.4818 10.7637 5.1922 10.7637 6.0802C10.7637 6.81428 10.5743 7.45956 10.1954 8.01604C9.81653 8.56068 9.27781 8.981 8.57925 9.277L10.5432 12.4288C10.6139 12.5244 10.7038 12.6092 10.8578 12.6921L11.2557 12.933C11.4259 13.036 11.5246 13.2251 11.5118 13.4236L11.4866 13.8151C11.4717 14.0456 11.3102 14.2411 11.0852 14.2938C10.1907 14.5031 9.31032 14.6415 8.44418 14.7088C8.21101 14.7269 7.99757 14.5845 7.90951 14.3679C7.27605 12.8094 6.30255 11.2969 5.54229 9.8098L4.76085 9.79204L4.76085 11.7812C4.76085 12.0653 4.77861 12.2488 4.81413 12.3317C4.86149 12.4028 4.96805 12.4856 5.13381 12.5804L5.61776 12.8617C5.78966 12.9617 5.89169 13.1489 5.88245 13.3476L5.8501 14.0432C5.83686 14.3278 5.60226 14.5517 5.31734 14.5517L0.846435 14.5517C0.562034 14.5517 0.327666 14.3286 0.313742 14.0445L0.270757 13.1676ZM5.89749 7.80292C6.12261 7.80292 6.32639 7.7953 6.50885 7.78006C7.00625 7.73852 7.37324 7.34352 7.42438 6.84701C7.44837 6.61412 7.46037 6.36444 7.46037 6.09796C7.46037 5.50596 7.33013 5.08564 7.06965 4.837C6.82101 4.58836 6.40068 4.46404 5.80869 4.46404C5.47717 4.46404 5.13973 4.4818 4.79637 4.51732L4.79637 7.69636C5.10421 7.7674 5.47125 7.80292 5.89749 7.80292Z"
      fill="#221203"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4913 13.5091C19.1307 14.3616 20.0187 14.7878 21.1553 14.7878C22.0907 14.7878 22.9609 14.4977 23.766 13.9176C23.8489 14.2372 23.9318 14.5096 24.0147 14.7345C25.007 14.6817 26.0182 14.5348 27.0482 14.2938C27.2732 14.2411 27.4347 14.0456 27.4496 13.8151L27.4748 13.4236C27.4876 13.2251 27.3889 13.036 27.2187 12.933L26.8208 12.6921C26.6668 12.6092 26.5662 12.5264 26.5188 12.4435C26.4833 12.3606 26.4656 12.2126 26.4656 11.9995L26.4656 1.89404C26.438 1.69402 26.4003 1.50605 26.3527 1.33014C26.2933 1.11126 26.0828 0.975254 25.857 0.996074C25.5982 1.01994 25.2919 1.05878 24.9382 1.1126C24.4616 1.17478 23.7582 1.28682 22.8281 1.44875C22.5862 1.49086 22.4049 1.69333 22.3888 1.93832L22.3505 2.52065C22.3365 2.73317 22.4503 2.93364 22.64 3.03056L23.1267 3.27932C23.2924 3.37404 23.3931 3.46285 23.4286 3.54573C23.4641 3.62861 23.4819 3.80028 23.4819 4.06077V5.12637C22.9609 5.10268 22.6531 5.09084 22.5584 5.09084C20.9481 5.09085 19.7049 5.51117 18.8288 6.35181C17.9644 7.19245 17.5323 8.43565 17.5323 10.0814C17.5323 11.514 17.852 12.6566 18.4913 13.5091ZM23.4996 12.1238C23.0142 12.3843 22.5761 12.5145 22.1854 12.5145C21.6171 12.5145 21.1968 12.2896 20.9244 11.8396C20.664 11.3779 20.5337 10.6616 20.5337 9.69069C20.5337 9.21709 20.5574 8.78493 20.6048 8.39421C20.6951 7.79774 21.1721 7.34928 21.7738 7.30587C21.8919 7.29735 22.0114 7.29309 22.1321 7.29309C22.6886 7.29309 23.1444 7.37005 23.4996 7.52397V12.1238Z"
      fill="#221203"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.7889 14.77C30.4036 14.77 29.3321 14.3497 28.5743 13.5091C27.8284 12.6684 27.4554 11.5081 27.4554 10.0281C27.4554 8.99805 27.6389 8.11597 28.006 7.38189C28.373 6.63597 28.894 6.06765 29.5689 5.67692C30.2437 5.2862 31.0311 5.09084 31.9309 5.09084C33.2689 5.09084 34.293 5.51708 35.0034 6.36956C35.6579 7.14202 36.0012 8.21097 36.0335 9.57642C36.0395 9.82911 35.8553 10.0432 35.6067 10.089L30.6345 11.0049C30.8002 11.5022 31.0311 11.8692 31.3271 12.106C31.6349 12.331 32.0375 12.4435 32.5348 12.4435C32.8781 12.4435 33.2866 12.3961 33.7602 12.3014C34.0472 12.2368 34.3364 12.1592 34.6277 12.0686C34.9336 11.9734 35.2627 12.1504 35.3352 12.4625L35.5289 13.2963C35.5766 13.5013 35.5007 13.7177 35.3222 13.8294C34.9313 14.0738 34.458 14.2808 33.9023 14.4504C33.2037 14.6635 32.4993 14.77 31.7889 14.77ZM33.1209 8.51853C33.0972 8.0686 32.9669 7.743 32.7301 7.54172C32.5052 7.3286 32.1855 7.22204 31.7711 7.22204C31.1426 7.22204 30.6207 7.69289 30.516 8.31258C30.4653 8.61315 30.4456 8.92452 30.4569 9.24668L33.1209 8.51853Z"
      fill="#221203"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.474 16.5746C36.2322 16.6409 36.0693 16.8666 36.0824 17.117L36.1221 17.87C36.137 18.1533 36.371 18.3753 36.6547 18.3753L40.9729 18.3753C41.2557 18.3753 41.4893 18.1547 41.5054 17.8724L41.5287 17.4652C41.5403 17.2614 41.4347 17.0689 41.2566 16.9693L40.4999 16.546C40.3341 16.4632 40.2335 16.3803 40.1979 16.2974C40.1624 16.2145 40.1447 16.0428 40.1447 15.7824V14.8056C40.5235 14.8292 40.8018 14.8411 40.9794 14.8411C42.637 14.8411 43.9039 14.4208 44.78 13.5801C45.6562 12.7276 46.0943 11.5022 46.0943 9.9038C46.0943 8.3646 45.7746 7.1806 45.1352 6.3518C44.5077 5.51116 43.6019 5.09084 42.4179 5.09084C42.0035 5.09084 41.5832 5.1678 41.157 5.32172C40.7426 5.47564 40.3519 5.7006 39.9848 5.9966C39.9652 5.81956 39.9346 5.65341 39.8931 5.49815C39.8351 5.28098 39.622 5.15194 39.3984 5.17464C39.0651 5.20847 38.6745 5.26342 38.2266 5.33948C37.6109 5.4342 37.0248 5.53484 36.4683 5.6414C36.3973 5.65324 36.3322 5.66508 36.273 5.67692C36.1682 5.69788 36.0904 5.78652 36.0833 5.89316L36.0295 6.70056C36.0156 6.90987 36.1256 7.10791 36.3107 7.20663L36.8058 7.47068C36.9715 7.5654 37.0722 7.6542 37.1077 7.73708C37.1432 7.80812 37.161 7.97388 37.161 8.23436L37.161 16.3862L36.474 16.5746ZM41.7489 12.6304C41.6502 12.636 41.5476 12.6388 41.4411 12.6388C40.9202 12.6388 40.488 12.5678 40.1447 12.4257L40.1447 7.595C40.6064 7.39372 41.009 7.29308 41.3523 7.29308C41.9088 7.29308 42.341 7.54172 42.6488 8.039C42.9685 8.53628 43.1283 9.24076 43.1283 10.1524C43.1283 10.5195 43.0928 10.9161 43.0218 11.3424L43.0189 11.3625C42.9211 12.0372 42.4296 12.5915 41.7489 12.6304Z"
      fill="#221203"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.1136 14.7878C48.3085 14.7878 47.6632 14.5688 47.1778 14.1307C46.6923 13.6808 46.4496 13.1065 46.4496 12.408C46.4496 11.4726 46.9173 10.7385 47.8526 10.2057C48.7998 9.67292 50.1733 9.371 51.973 9.29996C51.973 8.74348 51.9256 8.335 51.8309 8.07452C51.748 7.8022 51.5882 7.61868 51.3514 7.52396C51.1264 7.4174 50.7771 7.36412 50.3035 7.36412C49.8181 7.36412 49.4747 7.38188 49.2734 7.4174L48.7048 8.5409C48.6081 8.7319 48.4065 8.84659 48.193 8.83216L47.633 8.79432C47.3952 8.77825 47.1962 8.6064 47.151 8.37232C47.0168 7.67803 46.9138 6.96505 46.842 6.23338C46.8181 5.99 46.9668 5.76277 47.201 5.69232C47.7529 5.52632 48.3431 5.39094 48.9715 5.2862C49.7648 5.15596 50.5048 5.09084 51.1915 5.09084C52.4466 5.09084 53.3464 5.33948 53.891 5.83676C54.4357 6.3222 54.708 7.13324 54.708 8.26988V11.9817C54.708 12.1948 54.7258 12.3428 54.7613 12.4257C54.8086 12.5086 54.9034 12.5915 55.0454 12.6744L55.4681 12.9174C55.6443 13.0187 55.7476 13.2113 55.7345 13.4141L55.7103 13.7887C55.6952 14.0234 55.5281 14.2209 55.2985 14.2718C54.3095 14.4913 53.4839 14.6308 52.8217 14.6903C52.593 14.7108 52.3873 14.5656 52.3282 14.3438C52.2808 14.1544 52.2394 13.9768 52.2038 13.811C51.7421 14.107 51.233 14.3438 50.6765 14.5214C50.1318 14.699 49.6109 14.7878 49.1136 14.7878ZM50.3035 12.7809C50.7771 12.7809 51.3514 12.6211 52.0262 12.3014L51.9552 10.9516C51.671 10.928 51.4816 10.9161 51.3869 10.9161C51.0672 10.9161 50.6942 10.9516 50.268 11.0227C50.0448 11.0537 49.846 11.0879 49.6715 11.1254C49.4101 11.1816 49.2024 11.3947 49.2024 11.662C49.2024 12.0172 49.2971 12.2955 49.4866 12.4968C49.6878 12.6862 49.9602 12.7809 50.3035 12.7809Z"
      fill="#221203"
    />,
    <path
      d="M56.6186 12.7341C56.3807 12.8031 56.2217 13.0269 56.2347 13.2743L56.2747 14.0339C56.2896 14.3171 56.5236 14.5392 56.8073 14.5392L60.946 14.5392C61.2295 14.5392 61.4635 14.3173 61.4786 14.0342L61.5052 13.5355C61.5159 13.3342 61.4123 13.144 61.2372 13.044L60.6525 12.7099C60.4986 12.6152 60.4038 12.5264 60.3683 12.4435C60.3328 12.3606 60.315 12.1948 60.315 11.9462V7.64828C60.9662 7.51804 61.505 7.45292 61.9312 7.45292C62.3337 7.45292 62.612 7.51212 62.7659 7.63052C62.9317 7.74892 63.0323 7.91468 63.0678 8.1278C63.1034 8.34092 63.1211 8.71388 63.1211 9.24668L63.1211 12.6033L62.6593 12.7353C62.4203 12.8036 62.2601 13.0279 62.2732 13.2761L62.3131 14.0339C62.328 14.3171 62.562 14.5392 62.8457 14.5392L66.7358 14.5392C67.0193 14.5392 67.2533 14.3173 67.2684 14.0342L67.2951 13.5322C67.3058 13.3326 67.2039 13.1438 67.0313 13.0431L66.46 12.7099C66.2942 12.6152 66.1936 12.5264 66.1581 12.4435C66.1225 12.3606 66.1048 12.1948 66.1048 11.9462V8.46524C66.1048 6.21564 65.1635 5.09084 63.2809 5.09084C62.8784 5.09084 62.4462 5.15596 61.9845 5.2862C61.5345 5.4046 60.9485 5.62956 60.2262 5.96108C60.167 5.66508 60.0901 5.39276 59.9953 5.14412C59.3086 5.1678 58.1897 5.33356 56.6387 5.6414C56.4104 5.68292 56.2414 5.87734 56.2321 6.10925L56.2079 6.71351C56.1998 6.91618 56.3074 7.10591 56.4855 7.20304L56.9761 7.47068C57.1301 7.5654 57.2248 7.6542 57.2603 7.73708C57.2958 7.80812 57.3136 7.97388 57.3136 8.23436L57.3136 12.5323L56.6186 12.7341Z"
      fill="#221203"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.7427 14.7878C69.606 14.7878 68.718 14.3616 68.0787 13.5091C67.4393 12.6566 67.1196 11.514 67.1196 10.0814C67.1196 8.43564 67.5518 7.19244 68.4161 6.3518C69.2923 5.51116 70.5355 5.09084 72.1457 5.09084C72.2404 5.09084 72.5483 5.10268 73.0692 5.12636V4.06076C73.0692 3.80028 73.0515 3.6286 73.0159 3.54572C72.9804 3.46284 72.8798 3.37404 72.714 3.27932L72.2273 3.03056C72.0377 2.93363 71.9239 2.73317 71.9379 2.52065L71.9762 1.93832C71.9923 1.69332 72.1736 1.49085 72.4155 1.44874C73.3455 1.28682 74.0489 1.17477 74.5255 1.1126C74.8792 1.05877 75.1855 1.01993 75.4443 0.996066C75.6701 0.975245 75.8807 1.11125 75.94 1.33013C75.9877 1.50604 76.0253 1.69401 76.0529 1.89404V11.9995C76.0529 12.2126 76.0707 12.3606 76.1062 12.4435C76.1535 12.5264 76.2542 12.6092 76.4081 12.6921L76.8061 12.933C76.9762 13.036 77.0749 13.2251 77.0621 13.4236L77.0369 13.815C77.022 14.0456 76.8605 14.2411 76.6355 14.2938C75.741 14.5031 74.8606 14.6415 73.9945 14.7088C73.7613 14.7269 73.5457 14.5851 73.4777 14.3614C73.4362 14.2253 73.3948 14.0773 73.3534 13.9176C72.5483 14.4977 71.678 14.7878 70.7427 14.7878ZM71.7727 12.5145C72.1635 12.5145 72.6015 12.3843 73.087 12.1238V7.52396C72.7318 7.37004 72.2759 7.29308 71.7195 7.29308C71.5987 7.29308 71.4793 7.29734 71.3611 7.30587C70.7594 7.34927 70.2825 7.79773 70.1921 8.3942C70.1447 8.78492 70.1211 9.21708 70.1211 9.69068C70.1211 10.6616 70.2513 11.3779 70.5118 11.8396C70.7841 12.2896 71.2044 12.5145 71.7727 12.5145Z"
      fill="#221203"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.0397 14.1307C78.5251 14.5688 79.1704 14.7878 79.9755 14.7878C80.4728 14.7878 80.9937 14.699 81.5384 14.5214C82.0949 14.3438 82.604 14.107 83.0657 13.811C83.1013 13.9768 83.1427 14.1544 83.1901 14.3438C83.2492 14.5656 83.4549 14.7108 83.6836 14.6903C84.3458 14.6308 85.1714 14.4913 86.1604 14.2718C86.39 14.2209 86.5571 14.0234 86.5722 13.7886L86.5964 13.4141C86.6095 13.2113 86.5062 13.0187 86.33 12.9174L85.9073 12.6744C85.7653 12.5915 85.6705 12.5086 85.6232 12.4257C85.5877 12.3428 85.5699 12.1948 85.5699 11.9817V8.26988C85.5699 7.13324 85.2976 6.32219 84.7529 5.83675C84.2083 5.33947 83.3085 5.09083 82.0534 5.09083C81.3667 5.09083 80.6267 5.15596 79.8334 5.2862C79.205 5.39094 78.6148 5.52631 78.0629 5.69232C77.8287 5.76276 77.68 5.98999 77.7039 6.23337C77.7757 6.96505 77.8787 7.67803 78.0129 8.37232C78.0581 8.6064 78.2571 8.77825 78.4949 8.79432L79.0549 8.83215C79.2685 8.84658 79.47 8.7319 79.5667 8.54089L80.1353 7.4174C80.3366 7.38188 80.68 7.36412 81.1654 7.36412C81.639 7.36412 81.9883 7.4174 82.2133 7.52396C82.4501 7.61868 82.6099 7.8022 82.6928 8.07452C82.7875 8.335 82.8349 8.74348 82.8349 9.29996C81.0352 9.371 79.6617 9.67292 78.7145 10.2057C77.7792 10.7385 77.3115 11.4726 77.3115 12.408C77.3115 13.1065 77.5542 13.6808 78.0397 14.1307ZM82.8881 12.3014C82.2133 12.6211 81.639 12.7809 81.1654 12.7809C80.8221 12.7809 80.5497 12.6862 80.3485 12.4968C80.159 12.2955 80.0643 12.0172 80.0643 11.662C80.0643 11.3946 80.272 11.1816 80.5334 11.1254C80.7079 11.0879 80.9067 11.0537 81.1299 11.0227C81.5561 10.9516 81.9291 10.9161 82.2488 10.9161C82.3435 10.9161 82.5329 10.928 82.8171 10.9516L82.8881 12.3014Z"
      fill="#221203"
    />,
  ],
});

export default RidepandaTextIcon;
