import { Maybe } from "graphql/jsutils/Maybe";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useMemo,
} from "react";

import useLocalStorage from "../../hooks/useLocalStorage";
import { Customer, TermLengthType } from "../../types";

type SpokeVehicle = {
  brand: string;
  model: string;
  color: string;
  size: string;
};

type SpokeCheckoutContextType = {
  customer: Maybe<Customer>;
  setCustomer: Dispatch<SetStateAction<Maybe<Customer>>>;
  vehicle: Maybe<SpokeVehicle>;
  setVehicle: Dispatch<SetStateAction<Maybe<SpokeVehicle>>>;
  termLength: Maybe<TermLengthType>;
  setTermLength: Dispatch<SetStateAction<Maybe<TermLengthType>>>;
  spokeId: Maybe<string>;
  setSpokeId: Dispatch<SetStateAction<Maybe<string>>>;
  leaseId: Maybe<string>;
  setLeaseId: Dispatch<SetStateAction<Maybe<string>>>;
  totalMonthlyPayment: Maybe<string>;
  setTotalMonthlyPayment: Dispatch<SetStateAction<Maybe<string>>>;
};

export const CheckoutContext = createContext<SpokeCheckoutContextType>(
  {} as SpokeCheckoutContextType,
);

type SpokeContextProviderProps = {
  children?: ReactNode;
};

export function SpokeContextProvider({ children }: SpokeContextProviderProps) {
  const [customer, setCustomer] = useLocalStorage(
    "ridepanda.spoke.customer",
    undefined,
  );
  const [vehicle, setVehicle] = useLocalStorage(
    "ridepanda.spoke.vehicle",
    undefined,
  );
  const [termLength, setTermLength] = useLocalStorage(
    "ridepanda.spoke.termLength",
    undefined,
  );
  const [spokeId, setSpokeId] = useLocalStorage(
    "ridepanda.spoke.id",
    undefined,
  );
  const [leaseId, setLeaseId] = useLocalStorage(
    "ridepanda.spoke.lease.id",
    undefined,
  );
  const [totalMonthlyPayment, setTotalMonthlyPayment] = useLocalStorage(
    "ridepanda.spoke.lease.totalMonthlyPayment",
    undefined,
  );

  const checkoutProviderValue = useMemo(
    () => ({
      customer,
      setCustomer,
      termLength,
      setTermLength,
      spokeId,
      setSpokeId,
      vehicle,
      setVehicle,
      leaseId,
      setLeaseId,
      totalMonthlyPayment,
      setTotalMonthlyPayment,
    }),
    [
      customer,
      setCustomer,
      termLength,
      setTermLength,
      spokeId,
      setSpokeId,
      vehicle,
      setVehicle,
      leaseId,
      setLeaseId,
      totalMonthlyPayment,
      setTotalMonthlyPayment,
    ],
  );

  return (
    <CheckoutContext.Provider value={checkoutProviderValue}>
      {children}
    </CheckoutContext.Provider>
  );
}

export default SpokeContextProvider;
