import { getItem, setItem } from "../hooks/useLocalStorage";
import { SuccessCache, SuccessCacheFor } from "../types";

const SUCCESS_CACHE_KEY = "ridepanda.success";

export function setSuccessCache<T extends SuccessCache<T["data"]>>({
  type,
  data,
}: SuccessCacheFor<T["type"], T["data"]>) {
  return setItem(SUCCESS_CACHE_KEY, {
    type,
    data,
  });
}

export function getSuccessCache<T extends SuccessCache<T["data"]>>() {
  return getItem<T>(SUCCESS_CACHE_KEY);
}
