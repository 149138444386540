/* eslint-disable import/no-cycle */
import { Button, HStack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import PaymentIcon from "react-payment-icons";
import { Info } from "luxon";
import UpdatePaymentMethodModal from "../../../components/UpdatePaymentMethodModal";

function PaymentMethod({ brand, last4, expirationMonth, expirationYear }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack align="left">
      <HStack>
        <PaymentIcon id={brand.toLocaleLowerCase()} style={{ width: 45 }} />
        <VStack alignItems="flex-start" spacing={0}>
          <Text fontSize="md" textTransform="capitalize">
            {brand} •••• {last4}
          </Text>
          <Text fontSize="md" color="gray.500" mt={0}>
            Expires {Info.months("short")[expirationMonth - 1]} {expirationYear}
          </Text>
        </VStack>
      </HStack>
      <Button size="xs" variant="outline" onClick={onOpen}>
        Update your card
      </Button>
      <UpdatePaymentMethodModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
}

export default PaymentMethod;
