import { gql } from "@apollo/client";

const GET_SPOKES = gql`
  query GetSpokes($locationId: ID!) {
    spokes(locationId: $locationId) {
      id
      address {
        city
        countryCode
        line1
        line2
        postalCode
        stateCode
      }
      fullAddress
      name
      websiteUrl
      googleMapsUrl
      businessHours
      email
      phone
      vehicleCategories {
        icon {
          id
          url
        }
        name
      }
      brands {
        logo {
          id
          url
        }
        name
      }
    }
  }
`;

export const CREATE_SPOKE_SIGN_UP_SESSION_MUTATION = gql`
  mutation SpokeSignupSessionCreate($spokeId: ID!, $locationId: ID!) {
    spokeSignUpSessionCreate(spokeId: $spokeId, locationId: $locationId) {
      clientSecret
      clientSecretType
    }
  }
`;

export const SPOKE_SUBMITTED_EMAIL_KEY = "ridepanda.spokeSubmittedEmail";

export default GET_SPOKES;
