import { Box, Image } from "@chakra-ui/react";

import GridItem from "../../../components/layout/Grid/GridItem";

export default function Background() {
  return (
    <GridItem
      area="image"
      colSpan={3}
      position="relative"
      bg="linear-gradient(156.43deg, #FBFBFB 23.9%, #FAFAFA 67.02%)"
      display={{ base: "none", md: "flex" }}
    >
      <Box display="flex">
        <Image
          src="/assets/landing-bikes.png"
          objectPosition="center"
          objectFit="cover"
          h="100vh"
          maxW="fit-content"
          transform={{
            base: "rotate(-6.12deg) scaleX(-1)",
            xl: "rotate(-6.12deg)",
          }}
          blendMode="multiply"
        />
        <Image
          src="/assets/landing-bikes.png"
          objectPosition="center"
          objectFit="cover"
          h="100vh"
          maxW="fit-content"
          transform={{
            base: "rotate(-6.12deg) scaleX(-1)",
            xl: "rotate(-6.12deg)",
          }}
          blendMode="multiply"
        />
      </Box>

      <Box
        position="absolute"
        bg="white"
        width={{ xl: "200px" }}
        height={{ md: "100px", xl: "auto" }}
        borderRight={{ xl: "1px solid #ECECEC" }}
        borderBottom={{ md: "1px solid #ECECEC" }}
        top={{ md: -50, xl: -100 }}
        bottom={{ xl: -100 }}
        left={{ md: -100, xl: -100 }}
        right={{ md: -100 }}
        transform={{ md: "rotate(-4deg)", xl: "rotate(10deg)" }}
      />
    </GridItem>
  );
}
