import { useMutation } from "@apollo/client";
import {
  Box,
  Center,
  Divider,
  Fade,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import { CheckoutForm } from "./CheckoutForm";
import { CheckoutContext } from "./Context";
import { CheckoutSession } from "../../api/leases";
import {
  CREATE_SPOKE_CHECKOUT_SESSION_MUTATION,
  SpokeCheckoutSessionCreateInput,
  SpokeCheckoutSessionCreatePayload,
} from "../../api/spokeCheckoutSession";
import Card from "../../components/Card";
import CurrencyText from "../../components/CurrencyText";
import { HomeLoader } from "../../components/HomeLoader";
import Layout from "../../components/layout/LayoutV2";
import { TermLengthType } from "../../types";

export function Payment() {
  const { sessionToken } = useParams<{ sessionToken: string }>();
  const { customer, termLength, spokeId, setTotalMonthlyPayment } =
    useContext(CheckoutContext);
  const [isError, setIsError] = useState(false);
  const [checkoutSessionData, setCheckoutSessionData] = useState<
    CheckoutSession | undefined
  >(undefined);
  const toast = useToast();
  const [createCheckoutSession] = useMutation<
    SpokeCheckoutSessionCreatePayload,
    SpokeCheckoutSessionCreateInput
  >(CREATE_SPOKE_CHECKOUT_SESSION_MUTATION);

  const handleCheckoutSessionCreate = useCallback(
    async (token: string, length: TermLengthType) => {
      const { data, errors } = await createCheckoutSession({
        variables: {
          sessionToken: token,
          termLength: length,
        },
      });

      if (errors) {
        toast({
          title: "Something went wrong! Please try again.",
          status: "error",
          duration: 6000,
          isClosable: true,
        });

        // eslint-disable-next-line no-console
        console.error(errors);
        setIsError(true);

        return;
      }

      if (data) {
        setCheckoutSessionData(data.spokeCheckoutSessionCreate);
        setTotalMonthlyPayment(data.spokeCheckoutSessionCreate.total.amount);
      }
    },
    [createCheckoutSession, setTotalMonthlyPayment, toast],
  );

  useEffect(() => {
    if (!!sessionToken && !!termLength && !checkoutSessionData) {
      handleCheckoutSessionCreate(sessionToken, termLength);
    }
  }, [
    checkoutSessionData,
    handleCheckoutSessionCreate,
    sessionToken,
    termLength,
  ]);

  if (!sessionToken || isError) {
    return <Redirect to="/" />;
  }

  if (!customer || !termLength || !spokeId) {
    return <Redirect to={`/c/${sessionToken}`} />;
  }

  if (!checkoutSessionData) {
    return <HomeLoader />;
  }

  const { clientSecret, clientSecretType, taxAmount, discount, total } =
    checkoutSessionData;

  const invoiceLines = { taxAmount, discount, total };

  return (
    <Layout>
      <Center>
        <VStack align="start" spacing={3}>
          <Text textStyle="sub-heading" color="darkGray">
            Step 2/2
          </Text>
          <Text textStyle="h1">Payment</Text>
          <Text textStyle="sub-heading">
            Enter your payment information to finalize your lease
          </Text>
          <Text textStyle="sub-heading">
            Lease term will begin when payment is collected. Payment will recur
            monthly until lease term end.
          </Text>
          <Spacer py={0.8} />
          <Box w="full">
            <Text fontWeight="semibold" mb={2}>
              Lease payment
            </Text>
            <Card px={5} py={4}>
              <VStack align="start" spacing={8}>
                <Fade in={!!invoiceLines}>
                  {invoiceLines && (
                    <SimpleGrid
                      columns={2}
                      spacingY={2}
                      justifyContent="center"
                    >
                      <Text>Monthly payment</Text>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <CurrencyText
                          value={
                            parseFloat(invoiceLines.total.amount) -
                            parseFloat(invoiceLines.taxAmount?.amount || "0") +
                            parseFloat(invoiceLines.discount?.amount || "0")
                          }
                          decimals={2}
                        />
                      </Flex>
                      {invoiceLines.taxAmount && (
                        <>
                          <Text>{invoiceLines.taxAmount.displayName}</Text>
                          <Flex justifyContent="flex-end" alignItems="center">
                            <CurrencyText
                              value={parseFloat(invoiceLines.taxAmount.amount)}
                              decimals={2}
                            />
                          </Flex>
                        </>
                      )}
                      {invoiceLines.discount && (
                        <>
                          <Text>{invoiceLines.discount.displayName}</Text>
                          <Flex justifyContent="flex-end" alignItems="center">
                            <Text color="pandaRed.500">
                              -
                              <CurrencyText
                                value={parseFloat(invoiceLines.discount.amount)}
                                decimals={2}
                              />
                            </Text>
                          </Flex>
                        </>
                      )}
                      <Divider w="full" />
                      <Divider w="full" />
                      <Text fontWeight="semibold">Total monthly payment</Text>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <strong>
                          <CurrencyText
                            value={parseFloat(invoiceLines.total.amount)}
                            decimals={2}
                          />
                        </strong>
                      </Flex>
                    </SimpleGrid>
                  )}
                </Fade>
              </VStack>
            </Card>
          </Box>
          <CheckoutForm
            clientSecret={clientSecret}
            clientSecretType={clientSecretType}
            customer={customer}
            spokeId={spokeId}
          />
          <Center w="full" pt={3}>
            <Text>
              You will be charged{" "}
              <CurrencyText
                value={parseFloat(invoiceLines.total.amount)}
                decimals={2}
              />
            </Text>
          </Center>
        </VStack>
      </Center>
    </Layout>
  );
}

export default Payment;
