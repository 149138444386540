import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";

import Card from "../../../components/Card";
import SectionContainer from "../../../components/layout/SectionContainer";

export default function ComparePlansTab({
  handleTabsChange,
}: {
  handleTabsChange: (tabIndex: number, sectionToBrowse: string) => void;
}) {
  const showSubscriptionTab = useCallback(async () => {
    handleTabsChange(0, "vehiclesSection");
  }, [handleTabsChange]);

  const showLeaseToOwnTab = useCallback(async () => {
    await handleTabsChange(1, "leaseToOwnExplanationSection");
  }, [handleTabsChange]);

  const cardStyle = {
    padding: { base: 0, md: "2rem" },
    borderWidth: { base: 0, md: "1px" },
    bg: { base: "none", md: "white" },
  };

  return (
    <SectionContainer
      py={8}
      position="relative"
      innerProps={{ id: "containerdummy" }}
    >
      <Grid
        mt={8}
        gap="2rem"
        gridTemplateAreas={{
          base: `"subscription-plan" "lease-to-own-plan"`,
          lg: `"subscription-plan lease-to-own-plan"`,
        }}
      >
        <GridItem colSpan={3} area="subscription-plan">
          <Card sx={cardStyle}>
            <VStack gap="1rem" align="baseline">
              <Box>
                <Text
                  color="pandaRed.500"
                  textTransform="uppercase"
                  textStyle="small-semibold"
                >
                  Ridepanda
                </Text>
                <Text textStyle="h2">Subscription Plan</Text>
              </Box>
              <Text height="6.5rem">
                Our tried and true month-to-month subscription plan. Choose from
                top brands. Swap anytime. Leaving your employer or city? Opt out
                for free.
              </Text>
              <Divider />
              <VStack gap={0.5} align="baseline" w="100%">
                <Text textStyle="label" textTransform="uppercase">
                  Plan Basics
                </Text>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Get vehicle from</Text>
                  <Text>Ridepanda</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Plan type</Text>
                  <Text>Month-to-month Subscription</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Cancelation</Text>
                  <Text>Allowed with a fee</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Vehicle buyout</Text>
                  <Text>Available on a case-by-case basis</Text>
                </HStack>
              </VStack>
              <Divider />
              <VStack gap={0.5} align="baseline" w="100%">
                <Text textStyle="label" textTransform="uppercase">
                  Vehicles
                </Text>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Browse vehicles</Text>
                  <Text
                    textStyle="regular-link"
                    fontWeight="normal"
                    onClick={showSubscriptionTab}
                    cursor="pointer"
                  >
                    Via our online catalog
                  </Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Available types</Text>
                  <Text>Pedal bikes, E-Bikes, E-Scooters</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Vehicle condition</Text>
                  <Text>New / Like new</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Vehicle swap</Text>
                  <Text>1st month free / after for a fee</Text>
                </HStack>
              </VStack>
              <Divider />
              <VStack gap={0.5} align="baseline" w="100%">
                <Text textStyle="label" textTransform="uppercase">
                  Included Packages
                </Text>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Theft insurance</Text>
                  <Text textStyle="regular-link">
                    Included w/ $500 deductible
                  </Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Tune ups & repair</Text>
                  <Text>Free labor, you pay for parts</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Accessories</Text>
                  <Text>Helmet, lock, pump, welcome kit</Text>
                </HStack>
              </VStack>
              <Divider />
              <Button size="md" width="100%" onClick={showSubscriptionTab}>
                Browse our vehicles
              </Button>
            </VStack>
          </Card>
        </GridItem>
        <GridItem colSpan={3} area="lease-to-own-plan" mt={{ base: 8, md: 0 }}>
          <Card sx={cardStyle}>
            <VStack gap="1rem" align="baseline">
              <Box>
                <Text
                  color="pandaRed.500"
                  textTransform="uppercase"
                  textStyle="small-semibold"
                >
                  Partner bike shop
                </Text>
                <Text textStyle="h2">Lease-to-own Plan</Text>
              </Box>
              <Text height="6.5rem">
                We’ve partnered with a network of local bike shops to bring you
                an even larger selection of high quality vehicles. Save more
                with a customized 12-, 24-, or 36-month lease.
              </Text>
              <Divider />
              <VStack gap={0.5} align="baseline" w="100%">
                <Text textStyle="label" textTransform="uppercase">
                  Plan Basics
                </Text>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Get vehicle from</Text>
                  <Text>Partner bike shops</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Plan type</Text>
                  <Text>Lease-to-own</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Minimum length</Text>
                  <Text>12/24/36 months</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Cancelation</Text>
                  <Text>Not allowed</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Vehicle buyout</Text>
                  <Text>Required</Text>
                </HStack>
              </VStack>
              <Divider />
              <VStack gap={0.5} align="baseline" w="100%">
                <Text textStyle="label" textTransform="uppercase">
                  Vehicles
                </Text>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Browse vehicles</Text>
                  <Text>Visit shop in person</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Available types</Text>
                  <Text
                    textStyle="regular-link"
                    fontWeight="normal"
                    onClick={showLeaseToOwnTab}
                    cursor="pointer"
                  >
                    Varies per shop
                  </Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Vehicle condition</Text>
                  <Text>New</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Vehicle swap</Text>
                  <Text>No</Text>
                </HStack>
              </VStack>
              <Divider />
              <VStack gap={0.5} align="baseline" w="100%">
                <Text textStyle="label" textTransform="uppercase">
                  Included Packages
                </Text>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Theft insurance</Text>
                  <Text textStyle="regular-link">
                    Included w/ $500 deductible
                  </Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Tune ups & repair</Text>
                  <Text>$100 yearly allowance covers labor</Text>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <Text fontWeight="semibold">Accessories</Text>
                  <Text>Choose at the bike shop</Text>
                </HStack>
              </VStack>
              <Divider />
              <Button size="md" width="100%" onClick={showLeaseToOwnTab}>
                View shops in your area
              </Button>
            </VStack>
          </Card>
        </GridItem>
      </Grid>
    </SectionContainer>
  );
}
