import { gql } from "@apollo/client";

export default gql`
  query getVehicles($hubId: ID!) {
    vehicles(hubId: $hubId) {
      brand
      chargeTime
      details
      coreModel
      timeToFulfillWeeks
      highlights
      inStock
      id
      maxLoad
      model
      range
      recommendationAttribution
      recommendationQuote
      shortDescription
      topSpeed
      variants {
        id
        color
        colorCode
        size
        inStock
        image {
          url
        }
        vehicle {
          id
        }
      }
      weight
      images {
        url
      }
      plan {
        id
        price {
          amount
          currencyCode
        }
        postSubsidyPrice {
          amount
          currencyCode
        }
      }
    }
  }
`;
