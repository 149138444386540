import { Box, Button, Center, Image, Text, VStack } from "@chakra-ui/react";

import Card from "../../components/Card";
import FunPandaLogo from "../../components/FunPandaLogo";
import Grid from "../../components/layout/Grid";
import GridItem from "../../components/layout/Grid/GridItem";
import Layout from "../../components/layout/LayoutV2";
import useApp from "../../contexts/AppContext";
import useAuth from "../../hooks/useAuth";
import { SuccessVehicle } from "../../types";

interface SuccessMessageProps {
  timeToFulfillWeeks: string;
}

function SuccessMessage({ timeToFulfillWeeks }: SuccessMessageProps) {
  return (
    <Text as="span" textStyle="sub-heading">
      You will receive an email when it is ready for pick up.
      <br />
      <Text as="span" textStyle="sub-heading">
        This should take <b>{timeToFulfillWeeks}</b>.
      </Text>
    </Text>
  );
}

function DirectShipSuccessMessage({
  timeToFulfillWeeks,
}: {
  timeToFulfillWeeks: string;
}) {
  return (
    <Text as="span" textStyle="sub-heading">
      You will receive an email when your subscription begins.
      <br />
      <Text as="span" textStyle="sub-heading">
        Delivery expected in
        <b>
          <i>{timeToFulfillWeeks}</i>!
        </b>
      </Text>
    </Text>
  );
}

interface CheckoutSuccessProps {
  successVehicle: SuccessVehicle;
}

export function CheckoutSuccess({ successVehicle }: CheckoutSuccessProps) {
  const { setShowLoginModal } = useAuth();
  const { expectedFulfillmentTime } = useApp();
  const { brand, model, variantImageUrl, color, size, directShip } =
    successVehicle;

  return (
    <Layout>
      <Grid alignItems="center">
        <GridItem colStart={[0, 2]} colSpan={[6, 4]}>
          <Center>
            <FunPandaLogo boxSize={192} alt="Happy Ridepanda logo" />
          </Center>
        </GridItem>
        <GridItem colStart={[0, 2]} colSpan={[6, 4]}>
          <VStack spacing="0.75rem" textAlign="center">
            <Text as="h2" textStyle={{ base: "h1", md: "display" }}>
              Vehicle Ordered!
            </Text>
            {directShip ? (
              <DirectShipSuccessMessage
                timeToFulfillWeeks={expectedFulfillmentTime || ""}
              />
            ) : (
              <SuccessMessage
                timeToFulfillWeeks={expectedFulfillmentTime || ""}
              />
            )}
          </VStack>
        </GridItem>
        <GridItem colStart={[0, 2]} colSpan={[6, 4]}>
          <Card paddingY={4}>
            <VStack align="center" spacing={6}>
              <Image src={variantImageUrl} px={10} pt={4} w={["100%", "60%"]} />
              <Box paddingBottom={6} textAlign="center">
                <Text as="h3" textStyle="h3" mb={2}>
                  {brand} {model}
                </Text>
                <Text>
                  {size} • {color}
                </Text>
              </Box>
            </VStack>
          </Card>
        </GridItem>
        <GridItem colStart={[0, 3]} colSpan={[6, 2]}>
          <Center>
            <Card width="60%" paddingY={1}>
              <Button
                onClick={() => setShowLoginModal(true)}
                variant="link"
                colorScheme="black"
                w="100%"
              >
                <Text as="span">Log in to your account</Text>
              </Button>
            </Card>
          </Center>
        </GridItem>
      </Grid>
    </Layout>
  );
}

export default CheckoutSuccess;
