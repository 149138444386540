import { Box, Flex, Text } from "@chakra-ui/react";

import Grid from "../layout/Grid";
import { PerkItem } from "../PerkItem";

type Props = { showTheftInsurance: boolean };

export function SpokePerksBanner({ showTheftInsurance }: Props) {
  return (
    <Box>
      <Flex direction="column">
        <Text as="h2" textStyle="h2" textAlign="center">
          Lease includes
        </Text>
        <Text textStyle="sub-heading" mt={2} textAlign="center">
          Additional included perks and services
        </Text>
      </Flex>

      <Grid mt="64px" gap="48px">
        {showTheftInsurance && (
          <PerkItem
            title="Theft insurance"
            content="Use secure storage and the provided lock to reduce the chance of theft. If your vehicle is stolen, the cost will be covered after a $500 deductible."
            imageSrc="/assets/perks/theft-insurance.svg"
          />
        )}
        <PerkItem
          title="Maintenance"
          content="$100 yearly PandaCare credit toward labor at your preferred local shop."
          imageSrc="/assets/perks/maintenance.svg"
        />
        <PerkItem
          title="Expertly assembled vehicle"
          content="All of our vehicles are assembled, tuned and tested by our experienced mechanics."
          imageSrc="/assets/perks/assembly.svg"
        />
        <PerkItem
          title="Onboarding & accessories"
          content="Add any additional accessories available at the selected partner shop. Their experts will help you set them up alongside your vehicle and guide you towards your first ride!"
          imageSrc="/assets/perks/helmet.svg"
        />
      </Grid>
    </Box>
  );
}

export default SpokePerksBanner;
