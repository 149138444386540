/* eslint-disable import/no-cycle */
import {
  Box,
  Center,
  Flex,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Card from "../../components/Card";
import EmailLogin from "../../components/EmailLogin";
import PandaLogo from "../../components/PandaLogo";
import SmsLogin from "../../components/SmsLogin";
import { MAIL_TO_SUPPORT, SUPPORT_EMAIL } from "../../constants/mailing";
import useAuth from "../../hooks/useAuth";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "../../services/firebase";

function HomePageImage() {
  const history = useHistory();

  return (
    <Center>
      <PandaLogo
        boxSize={105}
        cursor="pointer"
        onClick={() => history.push("/")}
      />
    </Center>
  );
}

export default function Login() {
  const history = useHistory();
  const { user, loginWithEmail } = useAuth();
  const toast = useToast();
  const routeParams = useParams<any>();

  async function handleLoginLink(email: any) {
    try {
      await signInWithEmailLink(email, window.location.href);
    } catch (ex: any) {
      let title = "Something went wrong, try again";
      if (ex instanceof FirebaseError) {
        if (
          ex.code === "auth/invalid-action-code" ||
          ex.code === "auth/missing-email"
        ) {
          title =
            "Login link already used. Please enter your email to generate a new one.";
        }
      }
      toast({
        title,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    if (user) {
      history.replace("/");
    }
  }, [user, history]);

  useEffect(() => {
    if (isSignInWithEmailLink(window.location.href)) {
      handleLoginLink(atob(routeParams.email || ""));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box minH="100vh" bg="white">
      <SimpleGrid
        columns={1}
        row={1}
        alignItems="center"
        justifyItems="center"
        py={4}
        h="100vh"
      >
        <Card
          width={{ base: "90%", md: 850, xl: 1000 }}
          height={{ md: 600 }}
          overflow="hidden"
          innerProps={{ height: "100%" }}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            h="100%"
            spacing={{ base: 4, md: 0 }}
          >
            <Flex
              flex={1}
              alignItems="center"
              justify="center"
              p={{ base: 8, md: 0 }}
              position="relative"
            >
              {loginWithEmail && (
                <Stack align="left" spacing={4} px="2.5rem">
                  <HomePageImage />
                  <EmailLogin />
                </Stack>
              )}
              {!loginWithEmail && (
                <Stack align="left" spacing={4} px="2.5rem">
                  <HomePageImage />
                  <SmsLogin />
                </Stack>
              )}
              <Box style={{ position: "absolute", bottom: "0.5rem" }}>
                <Text fontSize="xs" color="gray.600">
                  Trouble logging in? E-mail&nbsp;
                  <Link href={MAIL_TO_SUPPORT} variant="pandaRed">
                    {SUPPORT_EMAIL}
                  </Link>
                </Text>
              </Box>
            </Flex>
            <Flex flex={1} display={{ base: "none", md: "inherit" }}>
              <Image
                alt="Login Image of a vehicle rider"
                objectFit="cover"
                src="/assets/loginBackground.jpg"
              />
            </Flex>
          </Stack>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
