import {
  Box,
  Center,
  Divider,
  HStack,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import Card from "../../../components/Card";
import BikeIcon from "../../../components/icons/BikeIcon";
import CalculatorIcon from "../../../components/icons/CalculatorIcon";
import CheckUpListIcon from "../../../components/icons/CheckUpListIcon";
import LicenseIcon from "../../../components/icons/LicenseIcon";
import ListDetailsIcon from "../../../components/icons/ListDetailsIcon";
import MapPinIcon from "../../../components/icons/MapPinIcon";
import Layout from "../../../components/layout/LayoutV2";
import { PandaPeek } from "../../../components/PandaLogo";
import { LeaseToOwnAgreementURL } from "../../../constants/spokes";
import { Spoke } from "../../../types";

interface Props {
  spoke: Spoke;
}

export function SignUpSuccess({ spoke }: Props) {
  return (
    <Layout>
      <Center>
        <Box w="lg" pb={{ base: "3rem", md: "5rem" }}>
          <VStack spacing={5}>
            <PandaPeek w="10rem" h="12rem" alt="Excited Ridepanda logo" />
            <VStack spacing={3}>
              <Heading as="h1" fontSize="5xl">
                Way to go!
              </Heading>
              <Text textAlign="center" fontSize="lg" fontWeight="500">
                Check your email for your activation code and see your next
                steps below.
              </Text>
            </VStack>
            <Box w="full">
              <VStack
                w="full"
                backgroundColor="#FAFAFA"
                border="1px solid #ECECEC"
                borderRadius={16}
                padding={6}
                spacing={68}
                alignItems="flex-start"
              >
                <HStack>
                  {spoke.vehicleCategories.map((category) => (
                    <Tag backgroundColor="#ECECEC" key={category.name}>
                      {category.name}
                    </Tag>
                  ))}
                </HStack>
                <VStack alignItems="flex-start" spacing={0}>
                  <Heading as="h2" fontSize="3xl">
                    {spoke.name}
                  </Heading>
                  <Text fontSize="sm">{spoke.fullAddress}</Text>
                </VStack>
              </VStack>
              <Text mt={4} textAlign="center">
                You can also visit any other location.{" "}
                <Link
                  as={RouterLink}
                  to="/"
                  color="pandaRed.500"
                  fontWeight="normal"
                  textDecoration="underline"
                >
                  See all partner locations.
                </Link>
              </Text>
            </Box>
            <Card w="full">
              <List>
                <ListItem p={4}>
                  <ListIcon
                    boxSize={6}
                    mr={4}
                    color="pandaRed.500"
                    as={ListDetailsIcon}
                  />
                  Save the activation code we have sent to your email
                </ListItem>
                <Divider />
                <ListItem p={4}>
                  <ListIcon
                    boxSize={6}
                    mr={4}
                    color="pandaRed.500"
                    as={MapPinIcon}
                  />
                  Check in with the code at any of our partner locations
                </ListItem>
                <Divider />
                <ListItem p={4}>
                  <ListIcon
                    boxSize={6}
                    mr={4}
                    color="pandaRed.500"
                    as={CheckUpListIcon}
                  />
                  Select a vehicle & up to $300 of accessories
                </ListItem>
                <Divider />
                <ListItem p={4}>
                  <ListIcon
                    boxSize={6}
                    mr={4}
                    color="pandaRed.500"
                    as={CalculatorIcon}
                  />
                  Calculate your savings with our{" "}
                  <Link
                    as={RouterLink}
                    to="/spokecalculator"
                    color="pandaRed.500"
                    fontWeight="normal"
                    textDecoration="underline"
                  >
                    lease calculator
                  </Link>
                </ListItem>
                <Divider />
                <ListItem p={4} display="flex" alignItems="center">
                  <ListIcon
                    boxSize={6}
                    mr={4}
                    color="pandaRed.500"
                    as={LicenseIcon}
                  />
                  <Text>
                    Start your customized lease-to-own plan. Lease will begin
                    when you check out at our partner shop. Payment will be
                    collected on lease start and recur monthly until lease term
                    end.{" "}
                    <Link
                      fontWeight={400}
                      textDecoration="underline"
                      color="pandaRed.500"
                      href={LeaseToOwnAgreementURL}
                    >
                      Click here
                    </Link>{" "}
                    to review Terms and Conditions.
                  </Text>
                </ListItem>
                <Divider />
                <ListItem p={4}>
                  <ListIcon
                    color="pandaRed.500"
                    as={BikeIcon}
                    boxSize={6}
                    mr={4}
                  />
                  Ride off to your next adventure!
                </ListItem>
              </List>
            </Card>
          </VStack>
        </Box>
      </Center>
    </Layout>
  );
}

export default SignUpSuccess;
