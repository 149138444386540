/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { useMemo } from "react";

import useApp from "../../contexts/AppContext";

type Faq = {
  question: string;
  answer: string;
};

function InnerFaqs({ items }: { items: Array<Faq> }) {
  return (
    <Accordion allowToggle>
      {items.map((item) => (
        <AccordionItem key={item.question}>
          <AccordionButton py={6} px={0} _hover={{ bg: "inherit" }}>
            <Text flex="1" textAlign="left" fontSize="lg" fontWeight="semibold">
              {item.question}
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel
            dangerouslySetInnerHTML={{ __html: item.answer }}
            px={0}
            pt={0}
            pb={6}
            sx={{
              ul: {
                marginInlineStart: "1rem",
              },
            }}
          />
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default function Faqs() {
  const { organization } = useApp();
  const organizationFaqs = useMemo(
    () => organization?.faqs || [],
    [organization],
  );

  if (!organizationFaqs || !organizationFaqs.length) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Box sx={{ a: { textDecor: "underline" } }}>
      <Text as="h2" textStyle="h2">
        Program FAQs
      </Text>
      <Text textStyle="sub-heading" mb={7} mt={2}>
        Learn more about our ride subscription program
      </Text>

      <InnerFaqs items={organizationFaqs} />
    </Box>
  );
}
