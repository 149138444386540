import { useQuery } from "@apollo/client";
import {
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

import InvoiceRow from "./InvoiceRow";
import { GET_INVOICES } from "../../../../api/invoices";
import Card from "../../../../components/Card";
import { InvoiceData } from "../../../../types";

export function Invoices() {
  const { data, loading } = useQuery<InvoiceData>(GET_INVOICES);

  if (loading) {
    return (
      <VStack spacing={3} py={4} mb={4}>
        <Skeleton h={16} w="100%" />
        <Skeleton h={16} w="100%" />
        <Skeleton h={16} w="100%" />
      </VStack>
    );
  }

  return (
    <Card>
      <TableContainer>
        <Table>
          <Thead
            bg="gray.50"
            borderBottomWidth={2}
            borderBottomColor="gray.200"
            borderBottomStyle="solid"
          >
            <Tr>
              <Th>Date</Th>
              <Th>Amount</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {data?.invoices.map((invoice) => (
              <InvoiceRow invoice={invoice} key={`invoice-${invoice.id}`} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default Invoices;
