/* eslint-disable import/no-cycle */
import { Box, ChakraProps, Flex } from "@chakra-ui/react";

import { SelectOption } from "../../../components/PandaSelect";
import hexToHSL from "../../../utils/hexToHSL";

type ColorOption = SelectOption<any> & { colorCode: string };
type Props = {
  colorOptions: ColorOption[];
  currentColor: string | undefined;
  onClick(newValue: string): void;
  id: string;
} & ChakraProps;

export default function ColorOptions({
  currentColor,
  colorOptions,
  onClick,
  ...rest
}: Props) {
  return (
    <Flex direction="row" gap={2} {...rest}>
      {colorOptions.map((option) => {
        const { colorCode, value, isDisabled } = option;
        const isActive = currentColor === value;
        const closerToWhite = hexToHSL(colorCode)[2] > 90;

        if (isDisabled) {
          return undefined;
        }

        const border = `${closerToWhite ? 1 : 3}px solid ${
          closerToWhite ? "#ECECEC" : colorCode
        };`;

        return (
          <Box
            position="relative"
            cursor="pointer"
            key={value}
            bg={colorCode}
            boxSize={50}
            onClick={() => onClick(value)}
            rounded="full"
            sx={
              isActive
                ? {
                    border,
                    boxShadow: `inset 0px 0px 0px 3px #FFFFFF`,
                  }
                : { border }
            }
            _after={
              closerToWhite && isActive
                ? {
                    border: "3px solid #edededb3",
                    content: `" "`,
                    position: "absolute",
                    top: 1,
                    left: 1,
                    right: 1,
                    bottom: 1,
                    rounded: "full",
                  }
                : undefined
            }
          />
        );
      })}
    </Flex>
  );
}
