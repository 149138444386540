import {
  Button,
  Flex,
  Image,
  Stack,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";

import GridItem from "../../../../components/layout/Grid/GridItem";
import { Spoke } from "../../../../types";

type Props = { spoke: Spoke; onSignUp: () => void };

export default function SpokeItem({ spoke, onSignUp }: Props) {
  return (
    <GridItem
      colSpan={3}
      bg="offWhite"
      border="1px solid"
      borderColor="lightgray"
      borderRadius="xl"
    >
      <Flex direction="column" py={12} px={20} textAlign="center">
        <Text as="h2" textStyle="h2">
          {spoke.name}
        </Text>
        <Text>{spoke.fullAddress}</Text>

        <Stack
          direction="row"
          flexWrap="wrap"
          gap={2}
          justifyContent="center"
          mt={8}
          overflow="auto"
        >
          {spoke.vehicleCategories.map((category) => (
            <Tag
              key={category.name}
              borderRadius="full"
              variant="solid"
              bg="lightgray"
              color="black"
              px={3}
              py={1}
              textTransform="uppercase"
              fontSize="xs"
            >
              <TagLabel>{category.name}</TagLabel>
            </Tag>
          ))}
        </Stack>
      </Flex>
      <Flex
        py={6}
        px={8}
        gap={10}
        overflow="auto"
        justifyContent="space-evenly"
      >
        {spoke.brands.map((brand) => (
          <Image key={brand.name} src={brand.logo.url} h="20px" />
        ))}
      </Flex>
      <Flex p={8}>
        <Button onClick={onSignUp} size="lg" w="100%">
          Sign up for a visit
        </Button>
      </Flex>
    </GridItem>
  );
}
