import DirectShipContent from "./DirectShipContent";
import PandaHubContent from "./PandaHubContent";
import useApp from "../../../../contexts/AppContext";
import { Organization } from "../../../../types";

export default function SubscriptionTab({ wizardProps }: any) {
  const { organization, hasDirectShipFlow } = useApp() as {
    organization: Organization;
    hasDirectShipFlow: boolean;
  };

  const onBrowseVehicles = async () => {
    const element = document.getElementById("vehiclesSection");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return hasDirectShipFlow ? (
    <DirectShipContent
      wizardProps={wizardProps}
      onBrowseVehicles={onBrowseVehicles}
    />
  ) : (
    <PandaHubContent
      wizardProps={wizardProps}
      onBrowseVehicles={onBrowseVehicles}
      organization={organization}
    />
  );
}
