import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Container,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import HubCard from "./HubCard";
import { Invoices } from "./Invoices";
import { LeaseCard } from "./LeaseCard";
import { VehicleDetails } from "./VehicleDetails";
import { Customer, CustomerOrder, SubscriptionState } from "../../../api/user";

interface SubscriptionDetailsProps {
  customer: Customer;
  state?: SubscriptionState;
  variant: CustomerOrder["variant"];
  plan: CustomerOrder["plan"];
  startDate?: string;
  currentPeriodEnd?: string;
}

export function SubscriptionDetails({
  variant,
  state,
  customer,
  plan,
  startDate,
  currentPeriodEnd,
}: SubscriptionDetailsProps) {
  const isActiveSubscription =
    state === SubscriptionState.ACTIVE && !!startDate && !!currentPeriodEnd;

  const { hub } = customer.location;

  return (
    <Container maxW="container.xl">
      <Text as="h1" textStyle="h1" py={4}>
        Your Subscription
      </Text>

      <Tabs>
        <TabList>
          <Tab
            fontWeight="semibold"
            _selected={{
              borderBottomWidth: 3,
              borderBottomColor: "pandaRed.500",
            }}
          >
            Overview
          </Tab>
          <Tab
            fontWeight="semibold"
            _selected={{
              borderBottomWidth: 3,
              borderBottomColor: "pandaRed.500",
            }}
          >
            Invoices
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={0}>
            <Grid
              gridTemplateAreas={{
                base: `"resume" "vehicle"`,
                lg: `"vehicle resume"`,
              }}
              gridTemplateColumns={{
                base: "1fr",
                lg: "1fr 2fr",
              }}
              gap="6"
            >
              <GridItem area="vehicle">
                <VehicleDetails {...variant} />
              </GridItem>

              <GridItem area="resume">
                {!isActiveSubscription && (
                  <Alert
                    status="info"
                    borderColor="blue.300"
                    borderWidth={2}
                    mb={3}
                    rounded="md"
                  >
                    <AlertIcon />
                    <Box>
                      <AlertTitle>Subscription not yet started</AlertTitle>
                      <AlertDescription>
                        Your month-to-month subscription commitment will start
                        the day you get your vehicle. Your next billing will be
                        one month from that day and will be automatically billed
                        to the credit card on file.
                      </AlertDescription>
                    </Box>
                  </Alert>
                )}

                {isActiveSubscription && (
                  <LeaseCard
                    isActive={isActiveSubscription}
                    card={customer.card}
                    price={plan.price}
                    startDate={startDate}
                    currentPeriodEnd={currentPeriodEnd}
                  />
                )}

                <HubCard hub={hub} showServiceButton={isActiveSubscription} />
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel px={0}>
            <Invoices />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}

export default SubscriptionDetails;
