import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import Card from "../../components/Card";
import CurrencyText from "../../components/CurrencyText";
import { LeasePrice, TermLength } from "../../types";

interface TermLengthSelectCardProps {
  length: TermLength;
  description: string;
  prices: LeasePrice;
  onActionClick: () => void;
}

function TermLengthSelectCard({
  length,
  description,
  prices: { leaseMonthlyPriceCents, buyoutPriceCents },
  onActionClick,
}: TermLengthSelectCardProps) {
  return (
    <Card width="100%" borderRadius="2xl">
      <Flex py={4} px={5}>
        <VStack align="start" spacing={-1.5}>
          <Text>{description}</Text>
          <Text textStyle="h3">{length} months</Text>
        </VStack>
        <Spacer />
        <Center>
          <Button onClick={onActionClick}>Select</Button>
        </Center>
      </Flex>
      <Divider />
      <Box py={4} px={5}>
        <Flex>
          <Text>Your monthly lease</Text>
          <Spacer />
          <Text>
            <CurrencyText
              currencyDisplay="narrowSymbol"
              value={Number(leaseMonthlyPriceCents) / 100}
              decimals={2}
            />
          </Text>
        </Flex>
        <Flex>
          <Text>Buyout price</Text>
          <Spacer />
          <Text>
            <CurrencyText
              currencyDisplay="narrowSymbol"
              value={Number(buyoutPriceCents) / 100}
              decimals={2}
            />
          </Text>
        </Flex>
      </Box>
    </Card>
  );
}

export default TermLengthSelectCard;
