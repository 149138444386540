import { Text } from "@chakra-ui/react";

function AvailabilityContent({
  inStock,
  timeToFulfillWeeks,
  onsiteDeliveryEnabled,
  hubName,
  directShipEnabled,
}: {
  inStock: boolean;
  timeToFulfillWeeks: string;
  onsiteDeliveryEnabled: boolean;
  directShipEnabled: boolean;
  hubName: string;
}) {
  if (directShipEnabled) {
    return (
      <Text>
        Selected variant is available for order with{" "}
        <b>delivery expected in {timeToFulfillWeeks}</b>.
      </Text>
    );
  }

  if (onsiteDeliveryEnabled && inStock) {
    return (
      <Text>
        Selected variant is available now -{" "}
        <b>schedule pickup at {hubName} PandaHub or office delivery today!</b>
      </Text>
    );
  }

  if (onsiteDeliveryEnabled && !inStock) {
    return (
      <Text>
        Selected variant is available for order with{" "}
        <b>pickup or office delivery in {timeToFulfillWeeks}</b>.
      </Text>
    );
  }

  if (!onsiteDeliveryEnabled) {
    return (
      <Text textStyle="regular">
        Selected variant is available for order with{" "}
        <Text as="span" fontWeight="semibold">
          {inStock && "pickup in 1-2 days."}

          {!inStock && `pickup in ${timeToFulfillWeeks}.`}
        </Text>
      </Text>
    );
  }

  return null;
}

export default AvailabilityContent;
