/* eslint-disable import/no-cycle */
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

import useApp from "../../../contexts/AppContext";
import useAuth from "../../../hooks/useAuth";
import PandaLogo from "../../PandaLogo";
import RidepandaTextIcon from "../../RidepandaTextIcon";

export const NAVBAR_HEIGHT = 92;
export const NAVBAR_MOBILE_HEIGHT = 64;

type Props = { hideCurrentSelection: boolean };

function NavBar({ hideCurrentSelection }: Props) {
  const { slug } = useParams<any>();
  const history = useHistory();
  const { organization, selectedLocation, isLoading, appLogout, customer } =
    useApp();
  const { user, setShowLoginModal } = useAuth();

  return (
    <Box
      h={{ base: `${NAVBAR_MOBILE_HEIGHT}px`, md: `${NAVBAR_HEIGHT}px` }}
      transition="height .1s ease"
    >
      <Grid gridTemplateColumns="1fr 1fr" h="100%" alignItems="center">
        <GridItem>
          <HStack>
            <PandaLogo
              cursor="pointer"
              onClick={() => history.push("/")}
              boxSize={10}
              ml="-4px"
            />
            <Box
              as="button"
              borderBottom="1px solid transparent"
              _hover={{ borderBottom: "1px solid #221203" }}
              onClick={() => slug && history.push(`/${slug}`)}
            >
              <RidepandaTextIcon width="auto" height="24px" />
            </Box>
          </HStack>
        </GridItem>
        {!hideCurrentSelection && (
          <GridItem>
            {!isLoading && (
              <HStack justify="end" spacing={2}>
                <Flex alignItems="center">
                  {organization?.logo?.url ? (
                    <Image
                      src={organization?.logo.url}
                      alt={organization?.name}
                      height="36px"
                      objectFit="cover"
                    />
                  ) : (
                    <Text as="b" fontSize="sm">
                      {organization?.name}
                    </Text>
                  )}
                </Flex>

                {selectedLocation?.name && (
                  <Text
                    fontSize="sm"
                    color="darkGray"
                    display={{ base: "none", md: "inherit" }}
                  >
                    |
                  </Text>
                )}

                {selectedLocation?.name && !user && (
                  <Text
                    fontSize="sm"
                    color="darkGray"
                    display={{ base: "none", md: "inherit" }}
                  >
                    {selectedLocation?.name}
                  </Text>
                )}

                {!user && (
                  <Button
                    size="sm"
                    variant="outline"
                    colorScheme="gray"
                    onClick={() => setShowLoginModal(true)}
                  >
                    Login
                  </Button>
                )}

                {user && customer && (
                  <>
                    <Text fontSize="md">
                      {customer?.firstName} {customer?.lastName}
                    </Text>
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="gray"
                      onClick={appLogout}
                    >
                      Sign Out
                    </Button>
                  </>
                )}
              </HStack>
            )}
          </GridItem>
        )}
      </Grid>
    </Box>
  );
}

export default NavBar;
