import { gql } from "@apollo/client";

import { SpokeLease } from "../types";

export type SpokeLeaseCheckPayload = {
  spokeLease: Pick<SpokeLease, "id">;
};

export interface SpokeLeaseCheckInput {
  customerId: string;
}

export const SPOKE_LEASE_CHECK = gql`
  query SpokeLease($customerId: ID) {
    spokeLease(customerId: $customerId) {
      id
    }
  }
`;

export default SPOKE_LEASE_CHECK;
