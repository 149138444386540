/* eslint-disable react/require-default-props */
import Select, { Props } from "react-select";

export type SelectOption<T> = {
  value: T;
  label: string;
  isDisabled?: boolean;
};

export default function PandaSelect(props: Props) {
  return (
    <Select
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          height: "48px",
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          width: "0px",
        }),
      }}
      {...props}
    />
  );
}
