import { useLazyQuery } from "@apollo/client";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { StripeError } from "@stripe/stripe-js";
import { ReactNode, useCallback, useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { CheckoutContext } from "./Context";
import {
  SPOKE_LEASE_CHECK,
  SpokeLeaseCheckInput,
  SpokeLeaseCheckPayload,
} from "../../api/spokeLease";
import PaymentMethodForm from "../../components/PaymentMethodForm";
import { StripeCardRefType } from "../../components/PaymentMethodForm/StripeCardForm";
import useInterval from "../../hooks/useInterval";
import { Customer } from "../../types";

type CheckoutFormProps = {
  clientSecret: string;
  clientSecretType: string;
  customer: Customer;
  spokeId: string;
};

export function CheckoutForm({
  clientSecret,
  clientSecretType,
  customer,
  spokeId,
}: CheckoutFormProps) {
  const history = useHistory();
  const { setLeaseId } = useContext(CheckoutContext);
  const [stripeError, setStripeError] = useState<StripeError | undefined>();
  const [errorMessage, setErrorMessage] = useState<
    string | ReactNode | null | undefined
  >(undefined);
  const stripeRef = useRef<StripeCardRefType>();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [leaseCheck] = useLazyQuery<
    SpokeLeaseCheckPayload,
    SpokeLeaseCheckInput
  >(SPOKE_LEASE_CHECK);

  const processPayment = useCallback(async () => {
    if (!stripeRef.current) {
      return;
    }

    setStripeError(undefined);
    setErrorMessage(null);
    setIsLoading(true);
    setIsProcessing(false);

    const stripeData: any = {
      payment_method: {
        billing_details: {
          name: `${customer.firstName} ${customer.lastName}`,
          phone: customer.phone,
          email: customer.email,
        },
        metadata: {
          firstName: customer.firstName,
          lastName: customer.lastName,
          spokeId,
        },
      },
    };

    const result =
      clientSecretType === "setup_intent"
        ? await stripeRef.current.confirmCardSetup(stripeData)
        : await stripeRef.current.confirmCardPayment({
            ...stripeData,
            receipt_email: customer.email,
            setup_future_usage: "off_session",
          });

    if (result.error) {
      setStripeError(result.error);
      setIsLoading(false);
    } else {
      setIsProcessing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(
    async () => {
      const { data, error } = await leaseCheck({
        variables: { customerId: customer.id },
      });

      if (error) {
        setErrorMessage("Couldn't fetch lease information");
        setIsProcessing(false);
        // eslint-disable-next-line no-console
        console.error(error);

        return;
      }

      if (data?.spokeLease) {
        setIsProcessing(false);
        setLeaseId(data.spokeLease.id);
        history.push("./success");
      }
    },
    isProcessing ? 5000 : null,
  );

  return (
    <>
      <PaymentMethodForm
        clientSecret={clientSecret}
        stripeError={stripeError}
        onFormSubmit={processPayment}
        ref={stripeRef}
        submitLabel="Start lease"
        isLoading={isLoading}
        initialEmail={customer.email}
        initialFirstName={customer.firstName}
        initialLastName={customer.lastName}
        initialPhoneNumber={customer.phone}
        buttonStyle={{
          width: "full",
        }}
      />
      <Modal isOpen={!!errorMessage} onClose={() => setErrorMessage(undefined)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sorry!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button size="md" onClick={() => setErrorMessage(undefined)}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CheckoutForm;
