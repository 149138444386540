import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { CheckoutContext } from "./Context";
import Card from "../../components/Card";
import CurrencyText from "../../components/CurrencyText";
import FunPandaLogo from "../../components/FunPandaLogo";
import Grid from "../../components/layout/Grid";
import GridItem from "../../components/layout/Grid/GridItem";
import Layout from "../../components/layout/LayoutV2";
import useAuth from "../../hooks/useAuth";
import { TermLength } from "../../types";

export default function Success() {
  const history = useHistory();
  const { setShowLoginModal } = useAuth();
  const { leaseId, vehicle, termLength, totalMonthlyPayment } =
    useContext(CheckoutContext);

  if (!leaseId || !vehicle || !totalMonthlyPayment || !termLength) {
    history.push("/");
    return null;
  }

  return (
    <Layout>
      <Grid alignItems="center">
        <GridItem colStart={[0, 2]} colSpan={[6, 4]}>
          <Center>
            <FunPandaLogo boxSize={144} alt="Happy Ridepanda logo" />
          </Center>
        </GridItem>
        <GridItem colStart={[0, 2]} colSpan={[6, 4]}>
          <VStack spacing="0.75rem" textAlign="center">
            <Text as="h2" textStyle={{ base: "h2", md: "display" }}>
              Enjoy your ride
            </Text>
            <Text as="p" fontWeight={500}>
              Congrats! You’ve just started your new lease. We’re sure you’re
              gonna love it!
            </Text>
          </VStack>
        </GridItem>
        <GridItem colStart={[0, 2]} colSpan={[6, 4]}>
          <Card width="100%" backgroundColor="offWhite" borderRadius="2xl">
            <Box py={4} paddingX={5}>
              <Text>{vehicle.brand}</Text>
              <Text textStyle="h3">{vehicle.model}</Text>
            </Box>
            <Divider />
            <Text py={4} paddingX={5}>
              {vehicle.color} • {vehicle.size}
            </Text>
            <Divider />{" "}
            <Box py={4} paddingX={5}>
              <Flex>
                <Text>Lease length</Text>
                <Spacer />
                <Text>{`${TermLength[termLength]}`} months</Text>
              </Flex>
              <Flex>
                <Text>Monthly payment</Text>
                <Spacer />
                <Text>
                  <CurrencyText
                    currencyDisplay="narrowSymbol"
                    value={parseFloat(totalMonthlyPayment)}
                    decimals={2}
                  />
                </Text>
              </Flex>
            </Box>
          </Card>
        </GridItem>
        <GridItem colStart={[0, 2]} colSpan={[6, 4]}>
          <Text textAlign="center" fontSize="sm">
            You’ll receive a confirmation email shortly. Log into your Ridepanda
            account to see full details of your lease and change payment method.
          </Text>
        </GridItem>
        <GridItem colStart={[0, 2]} colSpan={[6, 4]}>
          <Center>
            <Card width={{ base: "full", md: "60%" }} paddingY={1}>
              <Button
                onClick={() => setShowLoginModal(true)}
                variant="link"
                colorScheme="black"
                w="100%"
              >
                <Text as="span">Log in to your account</Text>
              </Button>
            </Card>
          </Center>
        </GridItem>
        <GridItem colStart={[0, 2]} colSpan={[6, 4]}>
          <Text textAlign="center" fontSize="sm">
            For any other questions or suggestions reach out to our team at{" "}
            <Link
              href="mailto:support@ridepanda.com"
              color="pandaRed.400"
              textDecoration="underline"
            >
              support@ridepanda.com
            </Link>
            .
          </Text>
        </GridItem>
      </Grid>
    </Layout>
  );
}
