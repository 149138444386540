/* eslint-disable import/no-cycle */
import { Icon } from "@chakra-ui/icons";
import { Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { RiMoneyDollarCircleFill } from "react-icons/ri";

import PaymentMethod from "./PaymentMethod";
import Card from "../../../components/Card";
import { Card as CardType, Price } from "../../../types";

function formatDate(date: Date) {
  return date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
}

function formatCurrency(
  amount: number | string,
  options: Intl.NumberFormatOptions | undefined,
) {
  return Number(amount).toLocaleString("en-US", {
    ...options,
  });
}

type LeaseCardProps = {
  isActive: boolean;
  card: CardType;
  price: Pick<Price, "amount" | "currencyCode">;
  startDate: string;
  currentPeriodEnd: string;
  termLengthMonths?: number;
};

export function LeaseCard({
  isActive,
  card,
  price,
  termLengthMonths,
  startDate,
  currentPeriodEnd,
}: LeaseCardProps) {
  const { amount, currencyCode = "USD" } = price;

  return (
    <Card p={4} mb={4}>
      <Heading as="h3" fontSize="md" fontWeight="semibold">
        Your{" "}
        {termLengthMonths ? `${termLengthMonths}-month lease` : "subscription"}
        {isActive && ` started on ${formatDate(new Date(startDate))}`}
      </Heading>
      <Divider my={4} />

      <Flex
        direction={{
          base: "column",
          md: "row",
        }}
        gap={{ base: 3, md: 9 }}
      >
        <Flex direction="column">
          <Text as="span">Price Per Month:</Text>
          <Flex align="center">
            <Icon
              as={RiMoneyDollarCircleFill}
              color="green.500"
              w={7}
              h={7}
              mr={1}
            />
            <Text as="span" fontSize="2xl" fontWeight="semibold">
              {formatCurrency(amount, {
                minimumFractionDigits: 2,
              })}
              {` ${currencyCode}`}
            </Text>
          </Flex>
        </Flex>

        <Flex direction="column" gap={1}>
          <Flex
            direction={{
              base: "column",
              md: "row",
            }}
          >
            <Text as="span" mr={3}>
              Periodicity:
            </Text>
            <Text as="span" fontWeight="semibold">
              Monthly
            </Text>
          </Flex>

          {isActive && (
            <Flex
              direction={{
                base: "column",
                md: "row",
              }}
            >
              <Text as="span" mr={3}>
                Next Payment:
              </Text>
              <Text as="span" fontWeight="semibold">
                {formatDate(new Date(currentPeriodEnd))}
              </Text>
            </Flex>
          )}
        </Flex>

        <Flex flex={1} justify={{ base: "start", md: "end" }}>
          <PaymentMethod {...card} />
        </Flex>
      </Flex>
    </Card>
  );
}

export default LeaseCard;
