/* eslint-disable import/no-unresolved */
import { Box, Flex, Image } from "@chakra-ui/react";
import { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { Navigation, Zoom } from "swiper/modules";
import { Swiper, type SwiperClass, SwiperSlide } from "swiper/react";

import type { ImageCarouselProps } from "../../../types";

export default function ImageCarousel({
  images,
  loop = true,
  onSwiper = () => {},
}: ImageCarouselProps) {
  const [, setMainSwiper] = useState<SwiperClass>(null);

  const onSwiperLocal = (e: SwiperClass) => {
    setMainSwiper(e);

    onSwiper(e);
  };

  return (
    <Box w="100%">
      <Box rounded="base" position="relative" overflow="hidden">
        <Swiper
          onSwiper={onSwiperLocal}
          modules={[Navigation, Zoom]}
          loop={loop}
          zoom
          slidesPerView={1}
          centeredSlides
          spaceBetween={30}
          allowTouchMove={false}
        >
          {images.map((image: string) => (
            <SwiperSlide key={image} style={{ overflow: "hidden" }}>
              <Flex justifyContent="center" className="swiper-zoom-container">
                <Image
                  src={image}
                  fit="cover"
                  w={{ base: 400, md: 500, xl: 600 }}
                  h={{ base: 300, md: 400, xl: 500 }}
                />
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
}
