import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import SpokeItem from "./SpokeItem";
import { HomeLoader } from "../../../../components/HomeLoader";
import Grid from "../../../../components/layout/Grid";
import GridItem from "../../../../components/layout/Grid/GridItem";
import SectionContainer from "../../../../components/layout/SectionContainer";
import LeaseCalculatorModal from "../../../../components/LeaseCalculatorModal";
import TermSavingsCard from "../../../../components/LeaseCalculatorModal/TermSavingsCard";
import { SpokePerksBanner } from "../../../../components/SpokePerksBanner";
import { LeaseToOwnAgreementURL } from "../../../../constants/spokes";
import useApp from "../../../../contexts/AppContext";
import useSpoke, { SpokeProvider } from "../../../../contexts/SpokeContext";
import { SignUpModal } from "../../../Spoke/SignUpModal";

function LeaseToOwnTabContent() {
  const onBrowseSpokes = () => {
    const element = document.getElementById("spokesSection");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  const { spokes, isLoadingSpokes, selectedSpoke, setSelectedSpoke } =
    useSpoke();

  const { organization } = useApp();

  const {
    isOpen: isCalculatorOpen,
    onOpen: onCalculatorOpen,
    onClose: onCalculatorClose,
  } = useDisclosure();

  return (
    <Box>
      <SectionContainer py={8}>
        <Grid
          mt={8}
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(6, 1fr)" }}
          templateAreas={{
            base: `"image image"
                   "content content"`,
            lg: `"image image image content content content"`,
          }}
        >
          <GridItem
            gridArea="content"
            colSpan={3}
            pl={{ base: 0, lg: 12, xl: "137px" }}
          >
            <Heading id="leaseToOwnExplanationSection">
              Lease-to-own the perfect ride
            </Heading>

            <Text fontSize="lg" mt={4}>
              We&apos;ve partnered with local bike shops to bring you a wide
              selection of top-quality vehicles. Visit any of our partner
              locations to find the ideal bike or scooter for your
              transportation needs.
            </Text>

            <Text fontSize="lg" mt={4}>
              Lease will begin when you check out at our partner shop. Payment
              will be collected on lease start and recur monthly until lease
              term end.{" "}
              <Link
                fontWeight={400}
                textDecoration="underline"
                href={LeaseToOwnAgreementURL}
              >
                Click here
              </Link>{" "}
              to review Terms and Conditions.
            </Text>

            <Button size="lg" onClick={onBrowseSpokes} mt={12}>
              Sign up for a shop visit
            </Button>
          </GridItem>

          <GridItem gridArea="image" colSpan={3}>
            <Image src="/assets/lease-to-own-top.png" borderRadius="xl" />
          </GridItem>
        </Grid>

        <Grid my={{ base: 12, md: 16 }}>
          <GridItem colSpan={6}>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 8, md: 42, lg: 82 }}
            >
              <Flex direction="column" maxW={{ base: "100%", md: 300 }}>
                <Text as="h4" textStyle="h4">
                  <Text as="span" color="pandaRed.500" textStyle="h4" mr={2}>
                    01
                  </Text>
                  Select your ride
                </Text>

                <Text>
                  Sign up online and visit any of our partner locations to find
                  the vehicle that you&apos;re looking for.
                </Text>
              </Flex>
              <Flex direction="column" maxW={{ base: "100%", md: 300 }}>
                <Text as="h4" textStyle="h4">
                  <Text as="span" color="pandaRed.500" textStyle="h4" mr={2}>
                    02
                  </Text>
                  Customize your lease
                </Text>

                <Text>
                  Your organization&apos;s subsidy can save you more with a
                  longer lease! Use our calculator to compare pricing on 12-,
                  24-, and 36-month leases.
                </Text>
              </Flex>
              <Flex direction="column" maxW={{ base: "100%", md: 300 }}>
                <Text as="h4" textStyle="h4">
                  <Text as="span" color="pandaRed.500" textStyle="h4" mr={2}>
                    03
                  </Text>
                  Enjoy your ride
                </Text>

                <Text>
                  Ridepanda lease includes theft insurance and regular
                  maintenance, so you can ride with peace of mind.
                </Text>
              </Flex>
            </Stack>
          </GridItem>
        </Grid>
      </SectionContainer>

      <SectionContainer
        py={{ base: 8, md: 24 }}
        borderTop="1px solid"
        borderColor="gray.200"
      >
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(6, 1fr)" }}
          templateAreas={{
            base: `"image image"
                   "content content"`,
            lg: `"image image image content content content"`,
          }}
        >
          <GridItem
            gridArea="content"
            colSpan={3}
            pl={{ base: 0, lg: 12, xl: "137px" }}
          >
            <Heading>Choose your lease length</Heading>

            <Text fontSize="lg" mt={4}>
              Get the best deal possible with a longer lease, lowering your
              monthly payments and extending the value of your employer subsidy.
              Visit our lease calculator to see exactly how much you can save.
            </Text>

            <Button
              size="lg"
              onClick={onCalculatorOpen}
              mt={12}
              variant="outline"
              colorScheme="black"
              _hover={{
                bg: "lightGray",
              }}
              _active={{ bg: "gray.300" }}
              w={{ base: "100%", md: "initial" }}
            >
              Lease calculator
            </Button>
          </GridItem>

          <GridItem gridArea="image" colSpan={3}>
            <TermSavingsCard borderWidth={0} />
          </GridItem>
        </Grid>
      </SectionContainer>

      <SectionContainer bg="white" py={24} id="spokesSection">
        <Heading textAlign="center">Select a partner shop to visit</Heading>
        <Text textAlign="center" textStyle="sub-heading" mb={16} mt={2}>
          You can visit any of our partner locations to find the vehicle that’s
          right for you
        </Text>

        {isLoadingSpokes && <HomeLoader />}

        {!isLoadingSpokes && (
          <Grid>
            {spokes.map((spoke) => (
              <SpokeItem
                key={spoke.id}
                spoke={spoke}
                onSignUp={() => setSelectedSpoke(spoke)}
              />
            ))}
          </Grid>
        )}

        {!isLoadingSpokes && !spokes.length && (
          <Text textAlign="center">
            bad news 😢 we don&apos;t have any partners in this city
          </Text>
        )}
      </SectionContainer>

      <SectionContainer py={{ base: 10, md: 24 }} bg="offWhite">
        <SpokePerksBanner
          showTheftInsurance={!!organization?.showTheftInsurance}
        />
      </SectionContainer>

      {isCalculatorOpen && (
        <LeaseCalculatorModal
          isOpen={isCalculatorOpen}
          onClose={onCalculatorClose}
        />
      )}

      {!!selectedSpoke && (
        <SignUpModal
          isOpen={!!selectedSpoke}
          onClose={() => setSelectedSpoke(undefined)}
        />
      )}
    </Box>
  );
}

export default function LeaseToOwnTab() {
  return (
    <SpokeProvider>
      <LeaseToOwnTabContent />
    </SpokeProvider>
  );
}
