import { Box, Text } from "@chakra-ui/react";
import { useMemo } from "react";

import useApp from "../../contexts/AppContext";
import Grid from "../layout/Grid";
import GridItem from "../layout/Grid/GridItem";
import TestRideButton from "../TestRideButton";

export default function HubInfoBanner() {
  const { selectedLocation } = useApp();

  const hubImage = useMemo(() => {
    if (!selectedLocation || !selectedLocation.hub) {
      return "";
    }

    let image;

    switch (selectedLocation.hub.name) {
      case "San Francisco":
        image = "san_francisco";
        break;
      case "New York City":
      case "NYC":
        image = "new_york";
        break;
      case "Seattle":
        image = "seattle";
        break;
      case "Washington, DC":
        image = "washington_dc";
        break;
      default:
        break;
    }

    return image;
  }, [selectedLocation]);

  return (
    <Grid
      bg="white"
      borderRadius={{ base: "none", md: "xl" }}
      border={{ base: "none", md: "1px solid lightGray" }}
      overflow="hidden"
      templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(6, 1fr)" }}
      templateAreas={{
        base: `"address"
               "content"`,
        md: `"content address"`,
      }}
    >
      <GridItem
        area="content"
        colSpan={3}
        mr={{ base: 0, xl: "110px" }}
        p={{ base: 6, xl: 12 }}
      >
        <Text mb={2} as="h2" textStyle="h2">
          Visit for a test ride to find the perfect fit!
        </Text>
        <Text mb="64px">
          Schedule a free test ride and a consultation at our PandaHub. Our
          experts will help you get matched with a vehicle that’s right for your
          lifestyle.
        </Text>

        <TestRideButton />
      </GridItem>
      <GridItem
        area="address"
        colSpan={3}
        position="relative"
        sx={{
          backgroundImage: `url(/assets/hub_locations/${hubImage}.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        minH={220}
      >
        <Box>
          <Box
            sx={{
              zIndex: 2,
              position: "absolute",
              bottom: "32px",
              left: "32px",
            }}
          >
            <Text as="h3" textStyle="h3">
              PandaHub {selectedLocation?.hub?.name}
            </Text>
            <Text>{selectedLocation?.hub?.fullAddress}</Text>
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
}
