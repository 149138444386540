import { useLazyQuery } from "@apollo/client";
import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import secureLocalStorage from "react-secure-storage";

import { FulfillmentAlertDialog } from "./Review/FulfillmentAlertDialog";
import {
  CHECKOUT_SESSION_CACHE_KEY,
  type InvoiceLines,
} from "../../api/leases";
import { CHECKOUT_SUCCESS_QUERY, VALIDATE_CHECKOUT } from "../../api/user";
import { VEHICLE_FULFILLMENT_TIME_QUERY } from "../../api/vehicleFulfillmentTime";
import CurrencyText from "../../components/CurrencyText";
import PaymentMethodForm, {
  type PaymentMethodData,
} from "../../components/PaymentMethodForm";
import useApp from "../../contexts/AppContext";
import {
  POLL_ORDER_TIMEOUT_MESSAGE,
  type ValidationCheckoutRestrictionsRequestResult,
} from "../../services/checkoutValidation";
import { addParticipantToGrowsurf } from "../../services/growSurf";
import { trackConversion } from "../../services/linkedin";

import type { StripeCardRefType } from "../../components/PaymentMethodForm/StripeCardForm";
import type { StripeError } from "@stripe/stripe-js";

interface SubmitPaymentLabelProps {
  invoiceLines: InvoiceLines | undefined;
}

function SubmitPaymentLabel({ invoiceLines }: SubmitPaymentLabelProps) {
  if (!invoiceLines) {
    return null;
  }

  return (
    <Text>
      Pay{" "}
      <CurrencyText
        value={Number.parseFloat(invoiceLines.total.amount)}
        decimals={2}
      />
    </Text>
  );
}

interface CheckoutFormProps {
  clientSecret: string;
  clientSecretType: string;
  invoiceLines: InvoiceLines | undefined;
}

function CheckoutForm({
  clientSecret,
  clientSecretType,
  invoiceLines,
}: CheckoutFormProps) {
  const { slug } = useParams() as { slug: string };
  const history = useHistory();
  const {
    leasingTermsAndConditions,
    selectedLocation,
    selectedVariant,
    setSelectedVariant,
    selectedVehicle,
    setSelectedVehicle,
    expectedFulfillmentTime,
    setStaleVehicles,
    customerEmail,
  } = useApp();
  const stripeRef = useRef<StripeCardRefType>();
  const timeoutRef = useRef<number | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<
    string | ReactNode | null | undefined
  >(undefined);
  const [stripeError, setStripeError] = useState<StripeError | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState<
    PaymentMethodData | undefined
  >();
  const [pollOrderRetries, setPollOrderRetries] = useState<number>(0);
  const [outOfStock, setOutOfStock] = useState(false);
  const [newFulfillmentTime, setNewFulfillmentTime] = useState<string>();
  const {
    isOpen: isFulfillmentAlertOpen,
    onClose: onFulfillmentAlertClose,
    onOpen: onFulfillmentAlertOpen,
  } = useDisclosure();
  const cancelRef = useRef(null);

  // Backend calls
  const [getVehicleFulfillmentTime] = useLazyQuery(
    VEHICLE_FULFILLMENT_TIME_QUERY,
  );
  const [checkoutRestrictionsValidation] = useLazyQuery(VALIDATE_CHECKOUT);
  const [checkoutSuccess] = useLazyQuery(CHECKOUT_SUCCESS_QUERY);

  const onCheckoutSuccess = useCallback(() => {
    secureLocalStorage.setItem(CHECKOUT_SESSION_CACHE_KEY, "");
    setSelectedVehicle("");
    setSelectedVariant("");
    history.push(`/${slug}/success`);
  }, [history, setSelectedVariant, setSelectedVehicle, slug]);

  const onVehicleOutofStock = useCallback(() => {
    history.push(`/${slug}/wizard/options`);
  }, [history, slug]);

  const validateCheckout = useCallback(
    async (email: string, phone: string) => {
      const {
        data: {
          validateEmail: emailResult,
          validatePhone: phoneResult,
          validateVariantCanCheckOutAtHub: inStockResult,
        },
      }: ValidationCheckoutRestrictionsRequestResult =
        await checkoutRestrictionsValidation({
          variables: {
            email,
            phone,
            hubId: selectedLocation?.hub.id,
            variantId: selectedVariant?.id,
          },
        });

      if (emailResult.error) {
        setErrorMessage(emailResult.error);
        return false;
      }
      if (phoneResult.error) {
        setErrorMessage(phoneResult.error);
        return false;
      }
      if (inStockResult.error) {
        setOutOfStock(true);
        setErrorMessage(inStockResult.error);
        return false;
      }

      return true;
    },
    [
      checkoutRestrictionsValidation,
      selectedLocation?.hub.id,
      selectedVariant?.id,
    ],
  );

  const processPayment = useCallback(
    async (customer: PaymentMethodData) => {
      if (!stripeRef.current) {
        return;
      }
      const { firstName, lastName, phoneNumber, email } = customer;

      setCustomerData(customer);
      setStripeError(undefined);
      setErrorMessage(null);
      setIsLoading(true);

      const { data, error } = await getVehicleFulfillmentTime({
        variables: {
          hubId: selectedLocation?.hub.id,
          vehicleId: selectedVehicle?.id,
        },
      });

      const currentFulfillmentTime = data.vehicleFulfillmentTime;

      if (!currentFulfillmentTime || error) {
        setErrorMessage("Failed to retrieve current stock");
        setIsLoading(false);
        return;
      }

      if (expectedFulfillmentTime !== currentFulfillmentTime) {
        setNewFulfillmentTime(currentFulfillmentTime);
        setIsLoading(false);
        onFulfillmentAlertOpen();
        return;
      }

      if (!(await validateCheckout(email, phoneNumber))) {
        setIsLoading(false);
        return;
      }

      // biome-ignore lint/suspicious/noExplicitAny: will be implement in the refactor
      const sharedData: any = {
        payment_method: {
          billing_details: {
            name: `${firstName} ${lastName}`,
            phone: phoneNumber,
            email,
            address: {
              line1: customer.address?.line1,
              line2: customer.address?.line2,
              city: customer.address?.city,
              state: customer.address?.state,
              postal_code: customer.address?.postalCode,
              country: customer.address?.country,
            },
          },
          metadata: {
            firstName,
            lastName,
            orgSlug: slug,
          },
        },
      };

      const result =
        clientSecretType === "setup_intent"
          ? await stripeRef.current.confirmCardSetup(sharedData)
          : await stripeRef.current.confirmCardPayment({
              ...sharedData,
              receipt_email: email,
              setup_future_usage: "off_session",
            });

      if (result.error) {
        setStripeError(result.error);
        setIsLoading(false);
      } else {
        setPollOrderRetries(1);

        trackConversion();
      }
    },
    [
      clientSecretType,
      expectedFulfillmentTime,
      getVehicleFulfillmentTime,
      onFulfillmentAlertOpen,
      selectedLocation,
      slug,
      validateCheckout,
      selectedVehicle,
    ],
  );

  useEffect(() => {
    if (pollOrderRetries === 0 || !customerData) {
      return;
    }

    if (pollOrderRetries > 60) {
      setErrorMessage(POLL_ORDER_TIMEOUT_MESSAGE);
      setIsLoading(false);
      return;
    }

    const checkOrder = async () => {
      const {
        data: {
          checkoutSuccess: { success },
        },
      } = await checkoutSuccess({ variables: { email: customerData.email } });

      if (!success) {
        setPollOrderRetries(pollOrderRetries + 1);
        return;
      }

      // Add participant to Growsurf
      await addParticipantToGrowsurf({
        email: customerData.email,
        firstName: customerData.firstName,
        lastName: customerData.lastName,
      });

      onCheckoutSuccess();
    };

    const timeoutId = window.setTimeout(checkOrder, 1000);
    timeoutRef.current = timeoutId;

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      clearTimeout(timeoutRef.current);
    };
  }, [pollOrderRetries, customerData, checkoutSuccess, onCheckoutSuccess]);

  return (
    <>
      <PaymentMethodForm
        clientSecret={clientSecret}
        stripeError={stripeError}
        onFormSubmit={processPayment}
        ref={stripeRef}
        submitLabel={<SubmitPaymentLabel invoiceLines={invoiceLines} />}
        isLoading={isLoading}
        initialEmail={customerEmail}
        addressRequired
      />
      <Text>
        By clicking the button above you also agree with our{" "}
        <Link
          textDecor="underline"
          fontWeight="semibold"
          variant="pandaRed"
          href={leasingTermsAndConditions}
          target="_blank"
        >
          terms of service
        </Link>
        .
      </Text>
      {errorMessage && (
        <Modal
          isOpen
          onClose={() => {
            setErrorMessage(undefined);
            if (outOfStock) {
              setStaleVehicles(true);
              onVehicleOutofStock();
            }
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Sorry!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{errorMessage}</ModalBody>
            <ModalFooter>
              <Button
                size="md"
                onClick={() => {
                  setErrorMessage(undefined);
                  if (outOfStock) {
                    setStaleVehicles(true);
                    onVehicleOutofStock();
                  }
                }}
              >
                OK
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <FulfillmentAlertDialog
        isOpen={isFulfillmentAlertOpen}
        onClose={onFulfillmentAlertClose}
        newFulfillmentTime={newFulfillmentTime}
        cancelRef={cancelRef}
      />
    </>
  );
}

export default CheckoutForm;
