/* eslint-disable import/no-cycle */
import {
  ChakraProps,
  Container,
  ContainerProps,
  GridItem,
} from "@chakra-ui/react";
import React, { ReactNode, forwardRef } from "react";

type Props = {
  id?: string;
  children: ReactNode;
  innerProps?: ContainerProps;
} & ChakraProps;

const SectionContainer = forwardRef(
  (
    { children, innerProps, ...props }: Props,
    ref: React.ForwardedRef<HTMLDivElement | null>,
  ) => {
    return (
      <GridItem {...props} ref={ref}>
        <Container maxW="container.xl" px={{ base: 6, md: 8 }} {...innerProps}>
          {children}
        </Container>
      </GridItem>
    );
  },
);
export default SectionContainer;
