import { useQuery } from "@apollo/client";
import { Box, Divider, Flex, HStack, Text } from "@chakra-ui/react";

import LEASE_PRICE_CALCULATOR, {
  LeasePriceCalculatorDocType,
} from "../../api/leasePriceCalculator";
import { TermLength } from "../../types";
import Card from "../Card";
import CurrencyText from "../CurrencyText";

type TermLengthCardProps = {
  lengthTitle: string;
  length: TermLength;
  vehicleRetailPriceCents: number;
  accessoriesRetailPriceCents: number;
  employerSubsidyAmountCents: number;
};

export const priceCentsDisplayer = (
  leaseData: LeasePriceCalculatorDocType | undefined,
  key: keyof LeasePriceCalculatorDocType["leasePriceCalculator"],
) => {
  if (
    !leaseData?.leasePriceCalculator ||
    Number(leaseData?.leasePriceCalculator[key]) < 0
  ) {
    return "-";
  }
  return (
    <CurrencyText
      currencyDisplay="narrowSymbol"
      value={Number(leaseData?.leasePriceCalculator[key]) / 100}
    />
  );
};

export default function LeaseLengthCard({
  lengthTitle,
  length,
  vehicleRetailPriceCents,
  accessoriesRetailPriceCents,
  employerSubsidyAmountCents,
}: TermLengthCardProps) {
  const termLength = TermLength[length];
  const { data } = useQuery<LeasePriceCalculatorDocType>(
    LEASE_PRICE_CALCULATOR,
    {
      variables: {
        vehicleRetailPriceCents,
        accessoriesRetailPriceCents,
        employerSubsidyAmountCents,
        termLength,
      },
    },
  );

  return (
    <Card width="100%" borderRadius="1rem" overflow="hidden">
      <Box p={4} background="#FAFAFA">
        <Text>{lengthTitle}</Text>
        <Text textStyle="h4">{length} months</Text>
      </Box>
      <Divider />
      <Flex direction="column" gap={1} p={4}>
        <HStack justify="space-between">
          <Text>Your monthly lease</Text>
          <Text minH={7}>
            {priceCentsDisplayer(data, "leaseMonthlyPriceCents")}
          </Text>
        </HStack>
        <HStack justify="space-between">
          <Text>Buyout price</Text>
          <Text minH={7}>{priceCentsDisplayer(data, "buyoutPriceCents")}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="semibold">Your Savings</Text>
          <Text fontWeight="semibold" minH={7}>
            {priceCentsDisplayer(data, "totalSavingsCents")}
          </Text>
        </HStack>
      </Flex>
    </Card>
  );
}
