import { Link, Text } from "@chakra-ui/react";

import { MAIL_TO_SUPPORT, SUPPORT_EMAIL } from "../constants/mailing";
import { ValidationResult } from "../types";

export interface ValidationCheckoutRestrictionsRequestResult {
  data: {
    validateEmail: ValidationResult;
    validatePhone: ValidationResult;
    validateVariantCanCheckOutAtHub: ValidationResult;
  };
}

export const POLL_ORDER_TIMEOUT_MESSAGE = (
  <Text>
    Sorry! Something went wrong. Please contact us at
    <Link href={MAIL_TO_SUPPORT} mx={3} fontWeight="bold">
      {SUPPORT_EMAIL}
    </Link>
  </Text>
);
