import {
  Box,
  Divider,
  Image,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";

type Props = {
  brand: string;
  model: string;
  color: string;
  size: string;
  image?: {
    url: string;
  };
};

export function VehicleDetails({ brand, model, color, size, image }: Props) {
  return (
    <VStack px={4} align="start">
      {image?.url && (
        <Box rounded="2xl" overflow="hidden" mb={3}>
          <Image src={image.url} />
        </Box>
      )}
      <Text fontSize="md" as="span" fontWeight="semibold">
        Vehicle
      </Text>
      <Divider />
      <List w="200px">
        <ListItem>{`${brand} ${model}`}</ListItem>
        <ListItem>{color}</ListItem>
        <ListItem>{size}</ListItem>
      </List>
    </VStack>
  );
}

export default VehicleDetails;
