import { gql } from "@apollo/client";

const GET_ORGANIZATION_QUERY = gql`
  query GetOrganization($slug: String) {
    organization(slug: $slug) {
      id
      name
      slug
      termsAndConditionsUrl
      benefitsTextEnabled
      benefitsText
      benefitsUpdatedAt
      spokeFlowEnabled
      deliveryCopyHubIds
      showTheftInsurance: theftInsuranceEnabled
      logo {
        url
      }
      locations {
        id
        name
        hub {
          id
          name
          testRideUrl
          locationsPageCopy
          googleMapsUrl
          fullAddress
          address {
            city
            countryCode
            line1
            line2
            postalCode
            stateCode
          }
          directShipEnabled
        }
      }
      faqs {
        question
        answer
      }
    }
  }
`;

export default GET_ORGANIZATION_QUERY;
