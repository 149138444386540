const IS_RIDEPANDA_USER = "ridepanda.isRidepandaUser";

const isRidepandaUser = () => {
  // The chrome `extension-env-switcher` sets this value in local storage
  // but only for `get.ridepanda.com` host.
  return !!window.localStorage.getItem(IS_RIDEPANDA_USER);
};

export const identifyUserByEmail = (email) => {
  if (isRidepandaUser()) return;

  window.heap?.identify(email);
};

export const identifyUserByPhone = (phone) => {
  if (isRidepandaUser()) return;

  window.heap?.identify(phone);
};

export const addUserProperties = (properties) => {
  if (isRidepandaUser()) return;

  window.heap?.addUserProperties(properties);
};
