import { gql } from "@apollo/client";

import { apolloMutate } from "../services/apolloClient";
import { SpokeLeaseState } from "../types";

export const CHECKOUT_SUCCESS_QUERY = gql`
  query CheckoutSuccess($email: String!) {
    checkoutSuccess(email: $email) {
      success
    }
  }
`;

export const VALIDATE_CHECKOUT = gql`
  query ValidateCheckout(
    $email: String!
    $phone: String!
    $hubId: ID!
    $variantId: ID!
  ) {
    validateEmail(email: $email) {
      valid
      error
    }
    validatePhone(phone: $phone) {
      valid
      error
    }
    validateVariantCanCheckOutAtHub(hubId: $hubId, variantId: $variantId) {
      valid
      error
    }
  }
`;

export const VALIDATE_SPOKE_CARD_SETUP = gql`
  query ValidateSpokeCardSetup($email: String!, $phone: String!) {
    validateEmail(email: $email) {
      valid
      error
    }
    validatePhone(phone: $phone) {
      valid
      error
    }
  }
`;

export const VALIDATE_USER_EMAIL = gql`
  query ValidateUserEmail($email: String!) {
    validateEmail(email: $email) {
      valid
      error
    }
  }
`;

export type CustomerOrder = {
  id: string;
  variant: {
    description: string;
    brand: string;
    model: string;
    color: string;
    size: string;
    image: {
      url: string;
    };
  };
  plan: {
    price: {
      amount: string;
      currencyCode: string;
    };
  };
};

export const CUSTOMER_ORDER_QUERY = gql`
  query CustomerOrder {
    order {
      id
      variant {
        description
        brand
        model
        color
        size
        image {
          url
        }
      }
      plan {
        price {
          amount
          currencyCode
        }
      }
    }
  }
`;

export enum SubscriptionState {
  LEASE_CREATED = "lease_created",
  VEHICLE_PURCHASED = "vehicle_purchased",
  TRACKING_NUMBER_ASSIGNED = "tracking_number_assigned",
  DELIVERED_TO_HUB = "delivered_to_hub",
  CUSTOMER_HANDOFF_INITIATED = "customer_handoff_initiated",
  ACTIVE = "active",
  CANCELED = "canceled",
}

export type CustomerSubscription = {
  state: SubscriptionState;
  startDate: string;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  variant: {
    description: string;
    model: string;
    brand: string;
    color: string;
    size: string;
    image: {
      url: string;
    };
  };
  plan: {
    price: {
      amount: string;
      currencyCode: string;
    };
  };
};

export type CustomerLease = {
  id: string;
  state: SpokeLeaseState;
  vehicle: {
    model: string;
    size: string;
    color: string;
    brand: {
      name: string;
    };
  };
  currentPeriodStart: string;
  currentPeriodEnd: string;
  termLengthMonths: number;
  monthlyTotalPriceCents: number;
};

export type Customer = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  location: {
    id: string;
    name: string;
    hub: {
      id: string;
      name: string;
      phone: string;
      email: string;
      businessHours: string;
      directShipEnabled: boolean;
    };
  };
  card: {
    brand: string;
    last4: string;
    expirationMonth: string;
    expirationYear: string;
  };
};

export type CustomerInfo = {
  subscription: CustomerSubscription;
  spokeLease: CustomerLease;
  customer: Customer;
};

export const CUSTOMER_INFO_QUERY = gql`
  query CustomerInfo {
    subscription {
      state
      startDate
      currentPeriodStart
      currentPeriodEnd
      variant {
        description
        model
        brand
        color
        size
        image {
          url
        }
      }
      plan {
        price {
          amount
          currencyCode
        }
      }
    }
    spokeLease {
      id
      state
      vehicle {
        model
        size
        color
        brand {
          name
        }
      }
      currentPeriodStart
      currentPeriodEnd
      termLengthMonths
      monthlyTotalPriceCents
    }
    customer {
      id
      email
      phone
      firstName
      lastName
      location {
        id
        name
        hub {
          id
          name
          phone
          email
          businessHours
          directShipEnabled
        }
      }
      card {
        brand
        last4
        expirationMonth
        expirationYear
      }
    }
  }
`;

export async function addToWaitlist(variables: any) {
  const mutation = gql`
    mutation JoinWaitlist(
      $location_id: ID!
      $organization_id: ID!
      $email: String!
    ) {
      joinWaitlist(
        locationId: $location_id
        organizationId: $organization_id
        email: $email
      ) {
        id
        email
      }
    }
  `;

  return apolloMutate({ mutation, variables });
}

export const checkCustomerPhone = async (phone: string) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/login`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    cache: "no-cache",
    body: JSON.stringify({
      phone,
    }),
  });

  return response.json();
};
