import { gql, useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import { Err, Ok, ValidationResult } from "../types";

export type SpokeCustomerValidationResult = {
  validateEmail: ValidationResult;
  validatePhone: ValidationResult;
};

export interface SpokeCustomerValidationInput {
  email: string;
  phone: string;
}

export const VALIDATE_SPOKE_CUSTOMER = gql`
  query ValidateSpokeCustomer($email: String!, $phone: String!) {
    validateEmail(email: $email) {
      valid
      error
    }
    validatePhone(phone: $phone) {
      valid
      error
    }
  }
`;

export function useValidateSpokeCustomer() {
  const [validateSpokeCustomer] = useLazyQuery<
    SpokeCustomerValidationResult,
    SpokeCustomerValidationInput
  >(VALIDATE_SPOKE_CUSTOMER);

  const handleCustomerValidation = useCallback(
    async (variables: SpokeCustomerValidationInput) => {
      try {
        const { error: apolloError, data } = await validateSpokeCustomer({
          variables,
        });

        if (apolloError) return Err(apolloError);
        if (!data) return Err("no data");

        const validationError =
          data.validateEmail.error || data.validatePhone.error;

        if (validationError) {
          const message = validationError
            .split("_")
            .filter((x) => x.length > 0)
            .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
            .join(" ");

          return Err(`Validation error: ${message}`);
        }

        return Ok(true);
      } catch (e) {
        return Err(e);
      }
    },
    [validateSpokeCustomer],
  );

  return handleCustomerValidation;
}

export default VALIDATE_SPOKE_CUSTOMER;
