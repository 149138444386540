import { Grid as ChakraGrid, GridProps } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = { children: ReactNode } & GridProps;

export default function Grid({ children, ...props }: Props) {
  return (
    <ChakraGrid
      templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(6, 1fr)" }}
      gap={{ base: 6, md: 8 }}
      {...props}
    >
      {children}
    </ChakraGrid>
  );
}
