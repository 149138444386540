import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";

import { MAIL_TO_SUPPORT, SUPPORT_EMAIL } from "../../constants/mailing";
import useAuth from "../../hooks/useAuth";
import { identifyUserByEmail } from "../../services/heap";
import { tracker } from "../../services/tracking";

export const sendLoginEmail = async (email: string) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/login`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    cache: "no-cache",
    body: JSON.stringify({
      email,
      newPortal: true,
    }),
  });

  return response.json();
};

export default function EmailLogin() {
  const { error, setLoginWithEmail } = useAuth();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);

  const onGoogleLogin = async (e: any) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setUnauthorized(false);

      identifyUserByEmail(email);
      tracker.trackEvent("Submitted Login", { email });
      const response = await sendLoginEmail(email);

      if (!response.success) {
        setIsLoading(false);

        setUnauthorized(true);

        return;
      }

      setEmailSent(true);

      toast({
        title: "Login email sent",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (ex) {
      toast({
        title: "Something went wrong, try again",
        status: "error",
        duration: 6000,
        isClosable: true,
      });

      setIsLoading(false);
    }
  };

  return (
    <>
      <Heading fontSize="3xl" textAlign="center">
        {emailSent ? "Login email sent!" : "Log in with your email"}
      </Heading>
      <Text fontSize="lg" fontWeight={500} color="black" textAlign="center">
        {emailSent
          ? `A one-time login link has been sent to ${email}, check your inbox.`
          : `Enter your email address below and we'll send you a one-time login link.`}
      </Text>

      <form onSubmit={onGoogleLogin}>
        {error && <p>Bad login</p>}
        {emailSent && (
          <Button
            variant="outline"
            colorScheme="gray"
            fontWeight={600}
            onClick={() => {
              setEmail("");
              setEmailSent(false);
              setIsLoading(false);
            }}
            size="lg"
            width="100%"
          >
            Wrong address?
          </Button>
        )}

        {unauthorized && (
          <Alert
            status="error"
            mb={4}
            maxWidth="md"
            borderRadius="sm"
            colorScheme="pandaRed"
          >
            <AlertIcon />
            <AlertDescription fontSize="sm">
              We could not find an account associated with this e-mail. Please
              email{" "}
              <Link isExternal href={MAIL_TO_SUPPORT} fontWeight="bold">
                {SUPPORT_EMAIL}
              </Link>{" "}
              if you need help.
            </AlertDescription>
          </Alert>
        )}

        {!emailSent && (
          <Stack spacing={4}>
            <FormControl id="email">
              <Input
                _placeholder={{ color: "gray.400" }}
                placeholder="kungfu@ridepanda.com"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                required
                size="lg"
              />
            </FormControl>
            <Stack spacing={4}>
              <Button
                isDisabled={isLoading}
                isLoading={isLoading}
                type="submit"
              >
                Send me login link
              </Button>
            </Stack>
          </Stack>
        )}
      </form>

      <Button
        variant="outline"
        colorScheme="gray"
        fontWeight={600}
        onClick={() => {
          setLoginWithEmail(false);
        }}
        size="lg"
      >
        Log in with your phone
      </Button>
    </>
  );
}
