import { useQuery } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { Maybe } from "graphql/jsutils/Maybe";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import GET_ORGANIZATION_QUERY from "../api/organization";
import GET_SPOKES from "../api/spokes";
import {
  CUSTOMER_INFO_QUERY,
  CUSTOMER_ORDER_QUERY,
  Customer,
  CustomerInfo,
  CustomerLease,
  CustomerOrder,
  CustomerSubscription,
} from "../api/user";
import GET_VEHICLES_QUERY from "../api/vehicles";
import { VariantVehicle } from "../domain/Wizard/VehicleDetails";
import useAuth from "../hooks/useAuth";
import useLocalStorage from "../hooks/useLocalStorage";
import { getSlug } from "../services/apolloClient";
import { addUserProperties } from "../services/heap";
import { setSentryContext } from "../services/sentry";
import { Location, Organization, Spoke } from "../types";
import getEnv from "../utils/getEnv";

const SIX_HOURS = 1000 * 60 * 60 * 6;
const THIRTY_MINUTES = 1000 * 60 * 30;
const storageDurationOverride = window.localStorage.getItem("storage.duration");
const devStorageDuration = storageDurationOverride
  ? parseInt(storageDurationOverride, 10)
  : THIRTY_MINUTES;

const LOCAL_STORAGE_DURATION =
  getEnv("DATA_ENV") === "production" ? SIX_HOURS : devStorageDuration;

interface AppContextType {
  isLoading: boolean;
  setIsLoading(arg0: boolean): void;
  hasSpokeFlow: boolean;
  spokeLease: CustomerLease | null;
  hasDirectShipFlow: boolean;
  subscription: CustomerSubscription | null;
  order: CustomerOrder | null;
  customer: Customer | null;
  availableVehicles: Array<any>;
  leasingTermsAndConditions: any;
  selectedVehicle?: any;
  setSelectedVehicle?: any;
  selectedVariant?: VariantVehicle;
  setSelectedVariant: (variant: VariantVehicle | string) => void;
  waitlistSubscription?: any;
  setWaitlistSubscription?: any;
  onsiteDeliveryEnabled: boolean;
  organization: Organization | undefined;
  staleVehicles: boolean;
  setStaleVehicles: any;
  showTheftInsurance: boolean;
  selectedLocation: Location | undefined;
  setSelectedLocation: (newValue: Location) => void;
  setOnsiteDeliveryEnabled(newValue: boolean): void;
  imagePreviewUrl: string | null;
  setImagePreviewUrl: (newValue: string | null) => void;
  appLogout: any;
  customerEmail: string | undefined;
  setCustomerEmail(newValue: string): void;
  availableSpokes: Spoke[];
  isLoadingSpokes: boolean;
  expectedFulfillmentTime: string | undefined;
  setExpectedFulfillmentTime: (fulfillmentTime: string | undefined) => void;
}

const AppContext = createContext<AppContextType>({} as AppContextType);

export function AppProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const sessionExpiredParam = searchParams.get("sessionExpired");
  const sessionExpiredEffectRun = useRef(false);

  const { user, logout } = useAuth();

  const [subscription, setSubscription] = useState<CustomerSubscription | null>(
    null,
  );
  const [order, setOrder] = useState<CustomerOrder | null>(null);
  const [spokeLease, setSpokeLease] = useState<CustomerLease | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [availableVehicles, setAvailableVehicles] = useState([]);
  const [leasingTermsAndConditions, setLeasingTermsAndConditions] =
    useState<Maybe<string>>(undefined);
  const [selectedVehicle, setSelectedVehicle] = useLocalStorage(
    "ridepanda.lease.vehicle",
    "",
  );
  const [selectedVariant, setSelectedVariant] = useLocalStorage(
    "ridepanda.lease.variant",
    "",
  );
  const [waitlistSubscription, setWaitlistSubscription] =
    useState<any>(undefined);
  const [selectedLocation, setSelectedLocation] = useLocalStorage(
    "ridepanda.lease.location",
    null,
  );
  const [customerEmail, setCustomerEmail] = useLocalStorage(
    "ridepanda.lease.customerEmail",
    "",
  );
  const [imagePreviewUrl, setImagePreviewUrl] = useLocalStorage(
    "ridepanda.lease.imagePreviewUrl",
    "",
  );
  const [expectedFulfillmentTime, setExpectedFulfillmentTime] = useLocalStorage(
    "ridepanda.lease.expectedFulfillmentTime",
    undefined,
  );
  const [onsiteDeliveryEnabled, setOnsiteDeliveryEnabled] =
    useState<boolean>(false);
  const [organization, setOrganization] = useState<Organization | undefined>();
  const [hasSpokeFlow, setHasSpokeFlow] = useState<boolean>(
    organization?.spokeFlowEnabled || false,
  );
  const [hasDirectShipFlow, setHasDirectShipFlow] = useState<boolean>(
    selectedLocation?.hub?.directShipEnabled || false,
  );
  const [staleVehicles, setStaleVehicles] = useState(false);
  const [showTheftInsurance, setShowTheftInsurance] = useState(false);

  //  From AuthContext we cannot clear subscription & order
  // we need the logout to clear those states variables
  const appLogout = useCallback(() => {
    setSubscription(null);
    setOrder(null);
    setCustomer(null);
    setSpokeLease(null);
    setWaitlistSubscription(undefined);
    setCustomerEmail(null);
    logout();
  }, [logout, setCustomerEmail]);

  const orgSlug = getSlug();

  const {
    loading: organizationLoading,
    error: organizationError,
    data: organizationData,
  } = useQuery<{ organization: Organization }>(GET_ORGANIZATION_QUERY, {
    variables: { slug: orgSlug },
    skip: !orgSlug && !user,
  });

  const {
    loading: userInfoLoading,
    error: userInfoError,
    data: userInfoData,
  } = useQuery<CustomerInfo>(CUSTOMER_INFO_QUERY, {
    skip: !user,
    notifyOnNetworkStatusChange: true,
  });

  const { loading: orderLoading, data: orderData } = useQuery<{
    order: CustomerOrder;
  }>(CUSTOMER_ORDER_QUERY, {
    skip: !user || userInfoLoading || !!userInfoData?.subscription,
    notifyOnNetworkStatusChange: true,
  });

  const { loading: spokesLoading, data: spokesData } = useQuery<{
    spokes: Spoke[];
  }>(GET_SPOKES, {
    skip: !selectedLocation?.id || !organization?.spokeFlowEnabled,
    variables: { locationId: selectedLocation?.id },
  });

  const hubId = selectedLocation?.hub?.id;

  const {
    loading: vehiclesLoading,
    data: vehiclesData,
    refetch: refetchVehicles,
  } = useQuery(GET_VEHICLES_QUERY, {
    variables: { hubId },
    skip: !hubId || !!user,
    notifyOnNetworkStatusChange: true,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Loading Effect
  useEffect(() => {
    const newState =
      organizationLoading ||
      userInfoLoading ||
      orderLoading ||
      vehiclesLoading ||
      spokesLoading;

    if (newState !== isLoading) {
      setIsLoading(newState);
    }
  }, [
    spokesLoading,
    isLoading,
    orderLoading,
    organizationLoading,
    userInfoLoading,
    vehiclesLoading,
  ]);

  /** Vehicles Effect * */
  const parsePriceIntegers = (plan: any) =>
    plan && {
      ...plan,
      price: {
        ...plan.price,
        amount: parseInt(plan.price.amount, 10),
      },
      postSubsidyPrice: {
        ...plan.postSubsidyPrice,
        amount: parseInt(plan.postSubsidyPrice.amount, 10),
      },
    };

  useEffect(() => {
    if (vehiclesData === undefined) {
      return;
    }

    const { vehicles } = vehiclesData;

    const sortedVehicles = vehicles
      .map((vehicle: any) => ({
        ...vehicle,
        plan: parsePriceIntegers(vehicle.plan),
      }))
      .sort((vehicleA: any, vehicleB: any) => {
        if (vehicleA.coreModel === vehicleB.coreModel) {
          return vehicleA.plan.price.amount - vehicleB.plan.price.amount;
        }

        return vehicleA.coreModel ? -1 : 1;
      });

    setAvailableVehicles(sortedVehicles);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesData, vehiclesLoading]);

  /** User Info Effect * */
  useEffect(() => {
    if (userInfoData === undefined && userInfoError === undefined) {
      return;
    }

    const unauthorizedError =
      userInfoError?.message.toLocaleLowerCase().indexOf("unauthorized") !== -1;

    if (
      (!user && unauthorizedError) ||
      (user && !userInfoData && userInfoError)
    ) {
      logout();

      return;
    }

    const customerOrder = orderData?.order || null;
    const customerSubscription = userInfoData?.subscription || null;
    const customerLease = userInfoData?.spokeLease || null;
    const customerData = userInfoData?.customer || null;

    setOrder(customerOrder);
    setSubscription(customerSubscription);
    setSpokeLease(customerLease);
    setCustomer(customerData);

    const activeCustomer = !!customerSubscription || !!customerLease;

    if (user && !(activeCustomer || orderLoading || !!customerOrder)) {
      logout();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoData, userInfoError, orderData, orderLoading]);

  /** Organization Effect * */
  useEffect(() => {
    const organizationResponse = organizationData?.organization;

    if (organizationError) {
      const pathName = window.location.pathname;
      if (pathName !== "/404" && orgSlug && orgSlug !== "login") {
        history.replace("/404");
      }

      return;
    }

    if (!organizationResponse) {
      return;
    }

    setHasSpokeFlow(organizationResponse.spokeFlowEnabled);
    setLeasingTermsAndConditions(organizationResponse.termsAndConditionsUrl);
    setOrganization(organizationResponse);
    setShowTheftInsurance(organizationResponse.showTheftInsurance);

    addUserProperties({ "Organization Name": organizationResponse.name });
  }, [history, orgSlug, organizationData, organizationError]);

  useEffect(() => {
    if (!organization || !hubId) {
      return;
    }

    const orgHubIdsWithOnsiteEnabled = organization.deliveryCopyHubIds;
    const onsiteDeliveryEnabledForOrg =
      orgHubIdsWithOnsiteEnabled.includes(hubId);

    setOnsiteDeliveryEnabled(onsiteDeliveryEnabledForOrg);
  }, [organization, hubId]);

  /** Trigger re-fetchs Effect * */
  useEffect(() => {
    if ((staleVehicles || orgSlug) && hubId) {
      refetchVehicles();
    }
  }, [hubId, orgSlug, refetchVehicles, staleVehicles]);

  /** Location selected Effect * */
  useEffect(() => {
    if (selectedLocation) {
      addUserProperties({ "Selected City": selectedLocation.name });
      setHasDirectShipFlow(selectedLocation.hub?.directShipEnabled || false);
    }
  }, [selectedLocation]);

  // localStorage Effect
  useEffect(() => {
    const storageCheck = setInterval(() => {
      const updatedAtValue = secureLocalStorage.getItem("updatedAt") as string;
      const updatedAt = updatedAtValue ? new Date(updatedAtValue) : new Date(0);

      if (new Date().getTime() - updatedAt.getTime() > LOCAL_STORAGE_DURATION) {
        secureLocalStorage.clear();
        window.location.replace(
          `${history.location.pathname}?sessionExpired=true`,
        );
      }
    }, 1000 * 10);

    return () => clearInterval(storageCheck);
  }, [history]);

  // Show session expired exactly once
  useEffect(() => {
    if (!sessionExpiredEffectRun.current && sessionExpiredParam === "true") {
      toast({
        title: "Your session has expired",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      // Remove the session expired param from the URL after showing the toast
      searchParams.delete("sessionExpired");
      history.replace({
        search: searchParams.toString(),
      });
    }

    // eslint-disable-next-line consistent-return
    return () => {
      sessionExpiredEffectRun.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedLocation) {
      return;
    }

    if (!organization) {
      return;
    }

    const locationInOrg = organization.locations.find(
      (value) => value.name === selectedLocation.name,
    );

    if (!locationInOrg) {
      setSelectedLocation(null);
    }
  }, [selectedLocation, organization, setSelectedLocation]);

  useEffect(() => {
    setSentryContext("extra", {
      orgSlug,
      selectedVehicle: selectedVehicle?.id,
      selectedVariant: selectedVariant?.id,
      selectedLocation: selectedLocation?.id,
    });
  }, [selectedVehicle, selectedVariant, selectedLocation, orgSlug]);

  const contextValues = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      hasSpokeFlow,
      hasDirectShipFlow,
      subscription,
      spokeLease,
      order,
      customer,
      availableVehicles,
      leasingTermsAndConditions,
      selectedVehicle,
      setSelectedVehicle,
      selectedVariant,
      setSelectedVariant,
      waitlistSubscription,
      setWaitlistSubscription,
      organization,
      staleVehicles,
      setStaleVehicles,
      showTheftInsurance,
      selectedLocation,
      setSelectedLocation,
      appLogout,
      customerEmail,
      setCustomerEmail,
      imagePreviewUrl,
      setImagePreviewUrl,
      onsiteDeliveryEnabled,
      setOnsiteDeliveryEnabled,
      availableSpokes: spokesData?.spokes || [],
      isLoadingSpokes: spokesLoading,
      expectedFulfillmentTime,
      setExpectedFulfillmentTime,
    }),
    [
      appLogout,
      availableVehicles,
      customer,
      customerEmail,
      spokeLease,
      spokesData?.spokes,
      spokesLoading,
      hasSpokeFlow,
      hasDirectShipFlow,
      imagePreviewUrl,
      isLoading,
      leasingTermsAndConditions,
      onsiteDeliveryEnabled,
      order,
      organization,
      selectedLocation,
      selectedVariant,
      selectedVehicle,
      setCustomerEmail,
      setImagePreviewUrl,
      setSelectedLocation,
      setSelectedVariant,
      setSelectedVehicle,
      showTheftInsurance,
      staleVehicles,
      subscription,
      waitlistSubscription,
      expectedFulfillmentTime,
      setExpectedFulfillmentTime,
    ],
  );

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
}

export default function useApp() {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }

  return context;
}
