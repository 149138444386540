import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router";

import useApp from "../../../contexts/AppContext";

interface FulfillmentAlertDialogProps {
  isOpen: boolean;
  onClose: () => void;
  cancelRef: React.MutableRefObject<null>;
  newFulfillmentTime: string | undefined;
}

export function FulfillmentAlertDialog({
  isOpen,
  cancelRef,
  onClose,
  newFulfillmentTime,
}: FulfillmentAlertDialogProps) {
  const history = useHistory();
  const { slug } = useParams() as { slug: string };
  const { setExpectedFulfillmentTime, setStaleVehicles } = useApp();

  const handleBackToCatalog = useCallback(() => {
    setExpectedFulfillmentTime(undefined);
    setStaleVehicles(true);
    onClose();
    history.push(`/${slug}/wizard`);
  }, [setExpectedFulfillmentTime, setStaleVehicles, onClose, slug, history]);

  const handleContinue = useCallback(() => {
    setExpectedFulfillmentTime(newFulfillmentTime);
    onClose();
  }, [newFulfillmentTime, setExpectedFulfillmentTime, onClose]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Vehicle availability has changed
          </AlertDialogHeader>
          <AlertDialogBody>
            <p>
              Since your initial selection, the availability of this vehicle has
              changed to <b>{newFulfillmentTime}</b>.
            </p>

            <br />
            <p>Do you want to continue with this order?</p>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Flex direction="column" w="full" gap={3}>
              <Button onClick={handleContinue} ml={3} p={0} m={0}>
                Continue and pay
              </Button>
              <Button
                colorScheme="gray"
                ref={cancelRef}
                onClick={handleBackToCatalog}
                p={0}
                m={0}
              >
                Browse other vehicles
              </Button>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
