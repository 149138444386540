import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink as firebaseIsSignInWithEmailLink,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  RecaptchaVerifier,
  sendSignInLinkToEmail as firebaseSendSignInLinkToEmail,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
  signInWithPopup,
  signOut as firebaseSignOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { getFirebaseConfig } from "./configs";

const firebaseConfig = getFirebaseConfig();

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Auth
export const auth = getAuth(firebaseApp);

export const onAuthStateChanged = (handler) =>
  firebaseOnAuthStateChanged(auth, handler);

export const providerGoogle = new GoogleAuthProvider();

export const signInWithPopupGoogle = () =>
  signInWithPopup(auth, providerGoogle);

export const recaptchaVerifier = (recaptchaContainerId) =>
  new RecaptchaVerifier(recaptchaContainerId, { size: "invisible" }, auth);

export const sendSignInSmsToPhone = (phoneNumber, appVerifier) =>
  firebaseSignInWithPhoneNumber(auth, phoneNumber, appVerifier);

export const sendSignInLinkToEmail = (email, settings) =>
  firebaseSendSignInLinkToEmail(auth, email, settings);

export const signInWithEmailLink = (email, emailLink) =>
  firebaseSignInWithEmailLink(auth, email, emailLink);

export const isSignInWithEmailLink = (emailLink) =>
  firebaseIsSignInWithEmailLink(auth, emailLink);

export const signOut = async () => await firebaseSignOut(auth);

// Database

const db = getFirestore(firebaseApp);

export const addDocToCollection = async (collectionName, documentData) =>
  await addDoc(collection(db, collectionName), {
    ...documentData,
    createdAt: Timestamp.fromDate(new Date()),
  });
