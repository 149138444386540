import { ReactNode } from "react";
import { Box, ChakraProps } from "@chakra-ui/react";

type Props = { children: ReactNode; isValid: boolean; style: ChakraProps };

export default function StripeElementInputContainer({
  children,
  isValid,
  style,
}: Props) {
  const borderColor = isValid ? "gray.200" : "pandaRed.400";
  const borderColorFocus = isValid ? "#3182ce" : "red.400";
  const boxShadow = isValid ? undefined : "0 0 0 1px #e53e3e";
  const boxShadowFocus = isValid ? "0 0 0 1px #3182ce" : "0 0 0 1px #e53e3e";

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        ".StripeElement": {
          padding: "12px 16px",
          outline: "2px solid transparent",
          outlineOffset: "2px",
          borderRadius: "md",
          boxShadow,
          border: "1px solid",
          borderColor,
          ...style,
        },
        ".StripeElement--focus": {
          zIndex: 10,
          borderColor: borderColorFocus,
          outline: 0,
          boxShadow: boxShadowFocus,
          transition:
            "border-color .15s ease-in-out, box-shadow .15s ease-in-out",
        },
      }}
    >
      {children}
    </Box>
  );
}
