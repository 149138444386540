import getEnv from "../utils/getEnv";

const DEBUGGER_STORAGE_KEY = "ridepanda.debugger";

const getDebuggerStoredValue = () => {
  try {
    const item = window.localStorage.getItem(DEBUGGER_STORAGE_KEY);
    return JSON.parse(item);
  } catch (error) {
    return undefined;
  }
};

export const getBackendConfig = () => {
  const debuggerData = getDebuggerStoredValue();

  if (debuggerData && debuggerData.env !== "") {
    return debuggerData.graphqlHost;
  }

  return getEnv("BACKEND_HOST");
};

export const getFirebaseConfig = () => {
  const debuggerData = getDebuggerStoredValue();

  if (
    debuggerData &&
    debuggerData.env !== "" &&
    debuggerData.firebaseConfig &&
    Object.keys(debuggerData.firebaseConfig).length
  ) {
    return debuggerData.firebaseConfig;
  }

  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
};
