import {
  Box,
  Center,
  Container,
  Grid,
  GridItem,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { Invoices } from "./Invoices";
import { LeaseCard } from "./LeaseCard";
import { VehicleDetails } from "./VehicleDetails";
import { Customer, CustomerLease } from "../../../api/user";
import { MAIL_TO_SUPPORT, SUPPORT_EMAIL } from "../../../constants/mailing";
import { SpokeLeaseState } from "../../../types";

interface LeaseDetailsProps {
  customer: Customer;
  lease: CustomerLease;
}

export function LeaseDetails({ customer, lease }: LeaseDetailsProps) {
  return (
    <Container
      maxW="container.xl"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Text as="h1" textStyle="h1" py={4}>
          Your Lease
        </Text>

        <Tabs>
          <TabList>
            <Tab
              fontWeight="semibold"
              _selected={{
                borderBottomWidth: 3,
                borderBottomColor: "pandaRed.500",
              }}
            >
              Overview
            </Tab>
            <Tab
              fontWeight="semibold"
              _selected={{
                borderBottomWidth: 3,
                borderBottomColor: "pandaRed.500",
              }}
            >
              Invoices
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              <Grid
                gridTemplateAreas={{
                  base: `"resume" "vehicle"`,
                  lg: `"vehicle resume"`,
                }}
                gridTemplateColumns={{
                  base: "1fr",
                  lg: "1fr 2fr",
                }}
                gap="6"
              >
                <GridItem area="vehicle">
                  <VehicleDetails
                    brand={lease.vehicle.brand.name}
                    model={lease.vehicle.model}
                    color={lease.vehicle.color}
                    size={lease.vehicle.size}
                  />
                </GridItem>

                <GridItem area="resume">
                  <LeaseCard
                    isActive={lease.state === SpokeLeaseState.ACTIVE}
                    termLengthMonths={lease.termLengthMonths}
                    startDate={lease.currentPeriodStart}
                    currentPeriodEnd={lease.currentPeriodEnd}
                    card={customer.card}
                    price={{
                      amount: (lease.monthlyTotalPriceCents / 100).toString(),
                      currencyCode: "USD",
                    }}
                  />
                  <Center>
                    <Text>
                      Need help with your lease? Contact us at{" "}
                      <Link
                        isExternal
                        href={MAIL_TO_SUPPORT}
                        variant="pandaRed"
                      >
                        {SUPPORT_EMAIL}
                      </Link>
                      .
                    </Text>
                  </Center>
                </GridItem>
              </Grid>
            </TabPanel>
            <TabPanel px={0}>
              <Invoices />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
}

export default LeaseDetails;
