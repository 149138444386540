import { Redirect, useParams } from "react-router-dom";

import { Spoke, SuccessKind, SuccessVehicle } from "../../types";
import { getSuccessCache } from "../../utils/successCache";
import { SignUpSuccess as SpokeSignUpSuccess } from "../Spoke/SignUpModal/Success";
import { CheckoutSuccess as SubscriptionCheckoutSuccess } from "../Subscription/CheckoutSuccess";

function Success() {
  const { slug } = useParams<any>();

  const cachedSuccess = getSuccessCache();

  if (!cachedSuccess) {
    // eslint-disable-next-line no-console
    console.error("No cache found, redirecting...");
    return <Redirect to={`/${slug}`} />;
  }

  switch (cachedSuccess.type) {
    case SuccessKind.SPOKE_SIGN_UP:
      return <SpokeSignUpSuccess spoke={cachedSuccess.data as Spoke} />;
    case SuccessKind.SUBSCRIPTION_CHECKOUT:
      return (
        <SubscriptionCheckoutSuccess
          successVehicle={cachedSuccess.data as SuccessVehicle}
        />
      );
    default:
      // eslint-disable-next-line no-console
      console.error("Invalid Success type, redirecting...");
      return <Redirect to="/" />;
  }
}

export default Success;
