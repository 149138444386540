/* eslint-disable import/no-cycle */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Link,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";

import { checkCustomerPhone } from "../../api/user";
import { MAIL_TO_SUPPORT, SUPPORT_EMAIL } from "../../constants/mailing";
import useAuth from "../../hooks/useAuth";
import {
  recaptchaVerifier,
  sendSignInSmsToPhone,
} from "../../services/firebase";
import { identifyUserByPhone } from "../../services/heap";
import { tracker } from "../../services/tracking";

const PIN_INPUT_COUNT = 6;

export default function SmsLogin() {
  const { setLoginWithEmail, setShowLoginModal, showLoginModal } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmationResult, setConfirmationResult] = useState<any>({});
  const [confirmationCode, setConfirmationCode] = useState("");
  const [smsSent, setSmsSent] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const toast = useToast();

  const getHumanizedError = (error: any) => {
    let errorMessage = "Something went wrong, try again";
    if (error instanceof FirebaseError) {
      switch (error.code) {
        case "auth/invalid-verification-code":
        case "auth/missing-verification-code":
          errorMessage = "Invalid verification code, try again.";
          break;
        default:
          break;
      }
    }
    return errorMessage;
  };

  const onError = (error: any) => {
    toast({
      title: getHumanizedError(error),
      status: "error",
      duration: 6000,
      isClosable: true,
    });
    setIsLoading(false);
  };

  const onPhoneNumberSubmit = async (e: any) => {
    e.preventDefault();
    const appVerifier = recaptchaVerifier("recaptcha-container");
    try {
      setIsLoading(true);
      setUnauthorized(false);
      identifyUserByPhone(phoneNumber);
      tracker.trackEvent("Submitted SMS Login", { phoneNumber });
      const response = await checkCustomerPhone(phoneNumber);

      if (!response.success) {
        setIsLoading(false);
        setUnauthorized(true);

        return;
      }

      const result = await sendSignInSmsToPhone(phoneNumber, appVerifier);

      setSmsSent(true);
      toast({
        title: "SMS code sent",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setConfirmationResult(result);
      setIsLoading(false);
    } catch (error) {
      onError(error);
      setUnauthorized(true);
    }
  };

  const onConfirmationCodeSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setUnauthorized(false);
      await confirmationResult.confirm(confirmationCode);

      setIsLoading(false);
      if (showLoginModal) {
        setShowLoginModal(false);
      }
    } catch (error) {
      onError(error);
    }
  };

  const formatPhoneNumberIntlToUS = (number: string) => {
    const match = number.match(/^\+1(\d{3})(\d{3})(\d{4})$/);
    if (match === null) {
      return "";
    }

    return `(${match[1]}) ${match[2]}-${match[3]}`;
  };

  return (
    <>
      {!smsSent && (
        <>
          <Heading as="h3" textAlign="center" fontSize="3xl">
            Log in with your phone
          </Heading>
          <Text textAlign="center" fontWeight={500}>
            Enter your phone number below and we&apos;ll send you a one-time 6
            digit login code.
          </Text>
        </>
      )}

      {unauthorized && (
        <Alert status="error" mb={4} maxWidth="md" borderRadius="sm">
          <AlertIcon />
          <AlertDescription fontSize="sm">
            We could not find an account associated with this phone number.
            Please email{" "}
            <Link isExternal href={MAIL_TO_SUPPORT} variant="pandaRed">
              {SUPPORT_EMAIL}
            </Link>{" "}
            if you need help.
          </AlertDescription>
        </Alert>
      )}

      {!smsSent && (
        <form onSubmit={onPhoneNumberSubmit}>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel htmlFor="phoneNumber">Phone number</FormLabel>
              <PhoneInput
                id="phoneNumber"
                name="phoneNumber"
                country="US"
                inputComponent={Input}
                value={phoneNumber}
                onChange={(e: any) => setPhoneNumber(e || "")}
                placeholder="(444) 555-6789"
                isInvalid={
                  phoneNumber.length > 2 && !isValidPhoneNumber(phoneNumber)
                }
              />
            </FormControl>

            <Button
              id="sign-in-button"
              isDisabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Send me SMS code
            </Button>
          </Stack>
          <div id="recaptcha-container" />
        </form>
      )}

      {smsSent && (
        <form onSubmit={onConfirmationCodeSubmit}>
          <Heading as="h3" fontSize="3xl" textAlign="center">
            Enter the login code
          </Heading>

          <Text fontWeight={500} textAlign="center">
            We’ve sent a one-time 6 digit login code to phone number you
            provided: {formatPhoneNumberIntlToUS(phoneNumber)}
          </Text>
          <Stack spacing={4}>
            <FormControl>
              <HStack marginTop={3} justify="center" gap={2}>
                <PinInput
                  type="number"
                  size="lg"
                  placeholder="0"
                  autoFocus
                  onComplete={(value: any) => setConfirmationCode(value)}
                >
                  {[...Array(PIN_INPUT_COUNT)].map(() => (
                    <PinInputField />
                  ))}
                </PinInput>
              </HStack>
            </FormControl>

            <Button isDisabled={isLoading} isLoading={isLoading} type="submit">
              Confirm code
            </Button>
          </Stack>
        </form>
      )}

      <Button
        variant="outline"
        colorScheme="gray"
        fontWeight={600}
        onClick={() => {
          setLoginWithEmail(true);
        }}
      >
        Log in with your email
      </Button>
    </>
  );
}
