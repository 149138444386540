/* eslint-disable import/no-cycle */
import { useLazyQuery } from "@apollo/client";
import { InfoIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import isEmail from "validator/es/lib/isEmail";

import AllVariantsModal from "./AllVariantsModal";
import AvailabilityContent from "./AvailabilityContent";
import ColorOptions from "./ColorOptions";
import ImageCarousel from "./ImageCarousel";
import ImagePreviewModal from "./ImagePreviewModal";
import PhotoGrid from "./PhotoGrid";
import { VALIDATE_USER_EMAIL } from "../../../api/user";
import Card from "../../../components/Card";
import CurrencyText from "../../../components/CurrencyText";
import Faqs from "../../../components/Faqs";
import ChargeTime from "../../../components/icons/ChargeTime";
import MaxLoad from "../../../components/icons/MaxLoad";
import Range from "../../../components/icons/Range";
import Speed from "../../../components/icons/Speed";
import Weight from "../../../components/icons/Weight";
import Grid from "../../../components/layout/Grid";
import GridItem from "../../../components/layout/Grid/GridItem";
import Layout from "../../../components/layout/LayoutV2";
import MotionBox from "../../../components/MotionBox";
import PandaSelect, { SelectOption } from "../../../components/PandaSelect";
import PerksBanner from "../../../components/PerksBanner";
import useApp from "../../../contexts/AppContext";
import { ValidationCheckoutRestrictionsRequestResult } from "../../../services/checkoutValidation";
import { identifyUserByEmail } from "../../../services/heap";
import { addSentryBreadcrumb } from "../../../services/sentry";
import {
  Image,
  SubscriptionCheckoutCache,
  SuccessKind,
  Variant,
  Vehicle,
} from "../../../types";
import { setSuccessCache } from "../../../utils/successCache";
import Breadcrumbs from "../components/Breadcrumbs";
import { ContactSupportCard } from "../ResultsPage/SubscriptionTab/DirectShipContent";

interface VariantMapItems<Value> {
  [key: string]: Value;
}

interface VariantMapGroup<Value> {
  [key: string]: VariantMapItems<Value>;
}

interface VariantsMap {
  colors: VariantMapGroup<boolean>;
  sizes: VariantMapGroup<boolean>;
  colorCodes: VariantMapItems<string>;
}

export type VariantVehicle = Variant & { vehicle: Pick<Vehicle, "id"> };

const generateVariantsMap = (variants: VariantVehicle[]) => {
  const result: VariantsMap = { colors: {}, sizes: {}, colorCodes: {} };
  variants.forEach((variant) => {
    if (!result.colors[variant.color]) {
      result.colors[variant.color] = {};
    }
    if (!result.colorCodes[variant.color]) {
      result.colors[variant.color] = {};
    }
    if (!result.sizes[variant.size]) {
      result.sizes[variant.size] = {};
    }

    result.colors[variant.color][variant.size] = true;
    result.colorCodes[variant.color] = variant.colorCode;
    result.sizes[variant.size][variant.color] = true;
  });

  return result;
};

const generateOptions = (values: Array<string>): SelectOption<any>[] =>
  values.map((value) => ({ value, label: value, isDisabled: false }));

const indexImages = (vehicle: Vehicle | undefined) => {
  if (!vehicle) {
    return {
      variantImages: [],
      closeUpImages: [],
      actionImages: [],
      indexes: [],
    };
  }

  const { variants, images } = vehicle;

  const vehicleImages = images.map(({ url }: Image) => url);
  const variantImages = Array.from(
    new Set(variants.map(({ image }: Variant) => image.url)),
  );

  const positionKeys = variants.reduce<any>(
    (obj: any, { image, size, color }: Variant) => {
      const newValue = obj;
      const key = `${size}_${color}`;

      newValue[key] = variantImages.indexOf(image.url);

      return newValue;
    },
    {},
  );
  const closeUpImages = vehicleImages.slice(0, 4);
  const actionImages = vehicleImages.slice(4);

  return {
    variantImages: [...variantImages],
    closeUpImages,
    actionImages,
    indexes: positionKeys,
  };
};

export function getVehicleFulfillmentTime(vehicle: Vehicle | undefined) {
  if (!vehicle) return undefined;

  return vehicle.inStock ? "1-2 days" : vehicle.timeToFulfillWeeks;
}

function VehicleDetails() {
  const { slug } = useParams<any>();

  const {
    selectedLocation,
    selectedVehicle,
    setCustomerEmail,
    selectedVariant: contextVariant,
    setSelectedVariant: setContextVariant,
    setSelectedVehicle: setStoredVehicle,
    customerEmail,
    organization,
    onsiteDeliveryEnabled,
    availableVehicles,
    hasDirectShipFlow,
    setExpectedFulfillmentTime,
  } = useApp();

  const { timeToFulfillWeeks } = selectedVehicle;

  const {
    isOpen: allVariantsModalOpen,
    onOpen: openAllVariantsModal,
    onClose: allVariantsModalOnClose,
  } = useDisclosure();

  const availableVariants = useMemo(
    (): VariantVehicle[] => selectedVehicle?.variants || [],
    [selectedVehicle],
  );
  const variantsMap = useMemo(
    () => generateVariantsMap(availableVariants),
    [availableVariants],
  );

  const availableSizes = Array.from(
    new Set(availableVariants.map((variant) => variant.size)),
  );
  const availableColors = Array.from(
    new Set(availableVariants.map((variant) => variant.color)),
  );

  const storedVariantAndVehicleMatch: Boolean =
    !!selectedVehicle &&
    !!contextVariant?.id &&
    selectedVehicle.id === contextVariant?.vehicle?.id;

  useEffect(() => {
    if (!selectedVehicle || !contextVariant?.vehicle) {
      return;
    }

    if (contextVariant.vehicle.id === selectedVehicle.id) {
      return;
    }

    setStoredVehicle(
      availableVehicles.find(
        (vehicle) => vehicle.id === contextVariant.vehicle.id,
      ),
    );
  }, [availableVehicles, contextVariant, selectedVehicle, setStoredVehicle]);

  const [color, setColor] = useState<string | undefined>(
    availableColors.length === 1 ? availableColors[0] : contextVariant?.color,
  );
  const [size, setSize] = useState(contextVariant?.size);
  const [localEmail, setLocalEmail] = useState(customerEmail || "");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const history = useHistory();
  const swipperRef = useRef<null | any>(null);

  const indexedImages = useMemo(
    () => indexImages(selectedVehicle),
    [selectedVehicle],
  );

  const variant = availableVariants.find(
    (v) => v.color === color && v.size === size,
  );

  // Backend calls
  const [validateUserEmail] = useLazyQuery(VALIDATE_USER_EMAIL);

  const checkEmail = async () => {
    const {
      data: {
        validateEmail: { error: errorReason },
      },
    }: ValidationCheckoutRestrictionsRequestResult = await validateUserEmail({
      variables: {
        email: localEmail,
      },
    });

    return errorReason;
  };

  const setVehicleStorageItem = () => {
    if (!color || !size) {
      // eslint-disable-next-line no-console
      console.error("Missing color or size");
      return;
    }

    const vehicleData = {
      inStock: variant?.inStock,
      directShip: selectedLocation?.hub.directShipEnabled,
      timeToFulfillWeeks,
      brand: selectedVehicle?.brand,
      model: selectedVehicle?.model,
      variantImageUrl: variant?.image?.url,
      color,
      size,
      createdAt: new Date(),
    };

    setSuccessCache<SubscriptionCheckoutCache>({
      type: SuccessKind.SUBSCRIPTION_CHECKOUT,
      data: vehicleData,
    });
  };

  const onContinueButton = async () => {
    setVehicleStorageItem();

    setIsLoading(true);
    setErrorMessage(undefined);

    const emailError = await checkEmail();

    setIsLoading(false);

    if (emailError) {
      setErrorMessage(emailError);
      return;
    }

    setExpectedFulfillmentTime(getVehicleFulfillmentTime(selectedVehicle));
    setCustomerEmail(localEmail);
    identifyUserByEmail(localEmail);
    addSentryBreadcrumb({
      category: "wizard",
      message: `Set Email: ${localEmail}`,
      level: "info",
    });

    history.push(`/${slug}/wizard/review`);
  };

  const handleSizeChange = (selectedSize: string) => {
    setSize(selectedSize);

    const sizeColors = Object.keys(variantsMap.sizes[selectedSize]);
    if (sizeColors.length === 1) {
      setColor(sizeColors[0]);
    } else if (color && !sizeColors.includes(color)) {
      setColor(undefined);
    }
  };

  useEffect(() => {
    if (!variant || contextVariant?.id === variant.id) {
      return;
    }

    setContextVariant(variant);

    const imageKey = `${variant.size}_${variant.color}`;
    const variantImageIndex = indexedImages.indexes[imageKey];

    if (variantImageIndex === undefined) {
      return;
    }

    // move to image index
    if (swipperRef.current) {
      swipperRef.current.slideTo(variantImageIndex);
    }
  }, [contextVariant?.id, indexedImages.indexes, setContextVariant, variant]);

  // calculate variant options
  const sizeOptions = useMemo(
    () => generateOptions(availableSizes),
    [availableSizes],
  );

  const colorAvailableInSize = useMemo(
    () => (size ? variantsMap.sizes[size] : {}),
    [size, variantsMap.sizes],
  );

  const colorOptions = useMemo(
    () =>
      generateOptions(availableColors).map((colorItem) => {
        const colorVariant = availableVariants.find(
          (v: Variant) => v.color === colorItem.value,
        );

        return {
          ...colorItem,
          colorCode: colorVariant?.colorCode || "black",
          isDisabled:
            size && storedVariantAndVehicleMatch
              ? !colorAvailableInSize[colorItem.value]
              : false,
        };
      }),
    [
      availableColors,
      availableVariants,
      colorAvailableInSize,
      size,
      storedVariantAndVehicleMatch,
    ],
  );
  // end variant options

  if (!selectedVehicle) {
    return <Redirect to={`/${slug}/wizard/vehicles`} />;
  }

  // workaround to handle stored keys from before standardLeasePlan -> Plan rename
  const plan = selectedVehicle.plan || selectedVehicle.standardLeasePlan;

  const subsidyApplied = plan.price.amount !== plan.postSubsidyPrice.amount;

  const canContinue =
    !!selectedVehicle &&
    !!color &&
    !!size &&
    !!localEmail &&
    isEmail(localEmail) &&
    !isLoading;

  return (
    <Layout>
      <MotionBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Box mt={8}>
          <Breadcrumbs justifyContent="start" />
        </Box>

        <Grid mt={6}>
          <GridItem colSpan={{ base: 2, md: 4 }}>
            <ImageCarousel
              images={indexedImages.variantImages}
              loop={false}
              onSwiper={(s: any) => {
                swipperRef.current = s;
              }}
            />

            <Box mt={8}>
              <Text as="h1" textStyle="h1">
                {selectedVehicle.brand} {selectedVehicle.model}
              </Text>
              <Text fontSize="lg" fontWeight={500} mt={3}>
                {selectedVehicle.shortDescription}
              </Text>

              <Text textStyle="large-regular" fontStyle="italic" mt={6}>
                {selectedVehicle.details}
              </Text>
            </Box>

            <Box mt={12}>
              {(selectedVehicle.topSpeed ||
                selectedVehicle.range ||
                selectedVehicle.weight ||
                selectedVehicle.maxLoad ||
                selectedVehicle.chargeTime) && (
                <HStack
                  gap={12}
                  justifyItems="start"
                  justifyContent={{ base: "center", md: "inherit" }}
                  flexWrap="wrap"
                >
                  {selectedVehicle.topSpeed && (
                    <Box textAlign="center">
                      <Speed boxSize="10" />
                      <Text textStyle="h4">{selectedVehicle.topSpeed}</Text>
                      <Text textStyle="label">Speed</Text>
                    </Box>
                  )}
                  {selectedVehicle.range && (
                    <Box textAlign="center">
                      <Range boxSize="10" />
                      <Text textStyle="h4">{selectedVehicle.range}</Text>
                      <Text textStyle="label">Range</Text>
                    </Box>
                  )}
                  {selectedVehicle.chargeTime && (
                    <Box textAlign="center">
                      <ChargeTime boxSize="10" />
                      <Text textStyle="h4">{selectedVehicle.chargeTime}</Text>
                      <Text textStyle="label">Charge Time</Text>
                    </Box>
                  )}
                  {selectedVehicle.weight && (
                    <Box textAlign="center">
                      <Weight boxSize="10" />
                      <Text textStyle="h4">{selectedVehicle.weight}</Text>
                      <Text textStyle="label">Weight</Text>
                    </Box>
                  )}
                  {selectedVehicle.maxLoad && (
                    <Box textAlign="center">
                      <MaxLoad boxSize="10" />
                      <Text textStyle="h4">{selectedVehicle.maxLoad}</Text>
                      <Text textStyle="label">Max Load</Text>
                    </Box>
                  )}
                </HStack>
              )}
            </Box>
          </GridItem>

          <GridItem colSpan={2}>
            <Card>
              <VStack alignItems="flex-start" spacing={6} p={8}>
                <Text as="h4" textStyle="h4">
                  Vehicle options
                </Text>

                <Box w="100%">
                  <Text fontWeight="semibold">
                    Size{" "}
                    {!size && (
                      <Text as="span" color="red">
                        *
                      </Text>
                    )}
                  </Text>

                  <Box mt={2} w="100%">
                    <PandaSelect
                      placeholder="Select Size"
                      id="size-select"
                      inputId="size-select-input"
                      options={sizeOptions}
                      value={sizeOptions.find((o) => o.value === size)}
                      onChange={(v: any) => handleSizeChange(v.value)}
                    />
                  </Box>
                </Box>

                <Box w="100%">
                  <Text fontWeight="semibold">
                    Color{" "}
                    {color ? (
                      <Text as="span" fontWeight={400}>{`(${color})`}</Text>
                    ) : (
                      <Text as="span" color="red">
                        *
                      </Text>
                    )}
                  </Text>

                  <ColorOptions
                    mt={2}
                    id="color-select"
                    colorOptions={colorOptions}
                    currentColor={color}
                    onClick={(newValue) => setColor(newValue)}
                  />
                </Box>
              </VStack>

              <Divider />

              <VStack alignItems="flex-start" spacing={6} p={8}>
                <Text as="h4" textStyle="h4">
                  Availability
                </Text>

                {!!variant && (
                  <AvailabilityContent
                    inStock={variant.inStock}
                    hubName={selectedLocation?.hub.name || ""}
                    timeToFulfillWeeks={timeToFulfillWeeks}
                    onsiteDeliveryEnabled={onsiteDeliveryEnabled}
                    directShipEnabled={
                      selectedLocation?.hub.directShipEnabled || false
                    }
                  />
                )}

                {!hasDirectShipFlow && (
                  <Button
                    variant="outline"
                    colorScheme="gray"
                    w="100%"
                    onClick={openAllVariantsModal}
                  >
                    See all variants
                  </Button>
                )}
              </VStack>

              <Divider />

              <VStack alignItems="flex-start" spacing={6} p={8}>
                <HStack justify="space-between" width="100%">
                  <Text as="h4" textStyle="h4">
                    Subscription details
                  </Text>
                  {subsidyApplied && (
                    <Popover trigger="hover" placement="bottom-start">
                      <PopoverTrigger>
                        <InfoIcon color="darkGray" />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverBody>
                            This is a pre-tax estimate after applying your
                            partner approved credit. To see the post-tax total,
                            click “Continue” to proceed to review page.
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  )}
                </HStack>

                <VStack w="100%" spacing={2}>
                  {subsidyApplied && (
                    <>
                      <HStack justify="space-between" w="100%">
                        <Text textStyle="regular">Subscription amount</Text>
                        <Text textStyle="regular">
                          <CurrencyText
                            value={parseInt(plan.price.amount, 10)}
                            currencyDisplay="narrowSymbol"
                          />
                        </Text>
                      </HStack>

                      <HStack justify="space-between" w="100%">
                        <Text textStyle="regular">Partner credit applied</Text>
                        <Text textStyle="regular">
                          <CurrencyText
                            value={
                              (parseInt(plan.price.amount as string, 10) -
                                parseInt(
                                  plan.postSubsidyPrice.amount as string,
                                  10,
                                )) as number
                            }
                            currencyDisplay="narrowSymbol"
                          />
                        </Text>
                      </HStack>
                    </>
                  )}

                  <HStack justify="space-between" w="100%">
                    <Text textStyle="regular">Monthly payment</Text>
                    <Text textStyle="regular">
                      <CurrencyText
                        value={parseInt(plan.postSubsidyPrice.amount, 10)}
                        currencyDisplay="narrowSymbol"
                      />
                    </Text>
                  </HStack>
                </VStack>
              </VStack>

              <Divider />

              <VStack alignItems="flex-start" spacing={4} p={8}>
                <Box w="100%">
                  {errorMessage && (
                    <Alert status="error" mb={2} variant="left-accent">
                      <AlertDescription>{errorMessage}</AlertDescription>
                    </Alert>
                  )}

                  <FormControl>
                    <FormLabel fontWeight="semibold">Email address</FormLabel>
                    <Input
                      size="lg"
                      mt={2}
                      placeholder="kungfu@ridepanda.com"
                      onChange={(e) =>
                        setLocalEmail(e.target.value.toLowerCase())
                      }
                      value={localEmail}
                      name="customerEmail"
                    />
                  </FormControl>
                </Box>

                <Button
                  data-testid="continue-button"
                  onClick={onContinueButton}
                  isDisabled={!canContinue}
                  isLoading={isLoading}
                  size="lg"
                  w="100%"
                >
                  Continue to payment
                </Button>
              </VStack>
            </Card>
          </GridItem>
        </Grid>

        {!hasDirectShipFlow && (
          <Box mt={24} bg="offWhite" p={12} borderRadius={24}>
            <PerksBanner
              showTheftInsurance={!!organization?.showTheftInsurance}
            />
          </Box>
        )}

        {indexedImages.closeUpImages.length > 0 && (
          <Box mt={24}>
            <PhotoGrid
              title={`${selectedVehicle.model} up close`}
              imageUrls={indexedImages.closeUpImages}
            />
          </Box>
        )}

        {indexedImages.actionImages.length > 0 && (
          <Box mt={24}>
            <PhotoGrid
              title="See it in action"
              imageUrls={indexedImages.actionImages}
              gridProps={{ display: "flex", flexWrap: "wrap" }}
              imageProps={{ height: 230, width: "25%", flex: "1 1" }}
            />
          </Box>
        )}

        {!hasDirectShipFlow && (
          <Flex direction="column" my={24}>
            <Faqs />
          </Flex>
        )}

        {hasDirectShipFlow && (
          <ContactSupportCard backgroundColor="transparent" />
        )}

        {allVariantsModalOpen && (
          <AllVariantsModal
            isOpen={allVariantsModalOpen}
            onClose={allVariantsModalOnClose}
            onVariantClick={(v) => {
              setColor(v.color);
              setSize(v.size);
              allVariantsModalOnClose();
            }}
            variants={availableVariants}
            variantsMap={variantsMap}
            vehicle={selectedVehicle}
            timeToFulfillWeeks={timeToFulfillWeeks}
          />
        )}
        <ImagePreviewModal />
      </MotionBox>
    </Layout>
  );
}

export default VehicleDetails;
