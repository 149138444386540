import { Image, ImageProps } from "@chakra-ui/react";

export default function PandaLogo(props: ImageProps) {
  return (
    <Image
      src="/assets/logos/panda-v2.svg"
      boxSize={{ base: 6, md: 12 }}
      {...props}
    />
  );
}

export function PandaLogoSuccess(props: ImageProps) {
  return (
    <Image
      src="/assets/panda-success.svg"
      boxSize={{ base: 6, md: 12 }}
      {...props}
    />
  );
}

export function PandaLogoExcited(props: ImageProps) {
  return <Image src="/assets/logos/panda-excited.svg" {...props} />;
}

export function PandaPeek(props: ImageProps) {
  return <Image src="/assets/logos/panda-peek.svg" {...props} />;
}
