/* eslint-disable import/no-cycle */
import { Box, Fade, Flex, SimpleGrid, Text } from "@chakra-ui/react";

import { InvoiceLines } from "../../../api/leases";
import CurrencyText from "../../../components/CurrencyText";
import { Price } from "../../../types";

interface InvoiceLineItemsProps {
  lines: InvoiceLines | undefined;
  planPrice: Price;
}

export function InvoiceLineItems({ planPrice, lines }: InvoiceLineItemsProps) {
  return (
    <Box w="full">
      <Fade in={!!lines}>
        {lines && (
          <SimpleGrid
            columns={2}
            px={{ base: 0, md: 8 }}
            spacingY={2}
            justifyContent="center"
          >
            <Text fontSize="lg" fontWeight="semibold" mb={2}>
              Payment
            </Text>
            <Flex />

            <Text>Monthly Subscription</Text>
            <Flex justifyContent="flex-end" alignItems="center">
              <CurrencyText value={parseFloat(planPrice.amount)} decimals={2} />
            </Flex>

            {lines.taxRate && (
              <>
                <Text>{lines.taxRate.displayName}</Text>
                <Flex justifyContent="flex-end" alignItems="center">
                  <CurrencyText
                    value={parseFloat(lines.taxRate.amount)}
                    decimals={2}
                  />
                </Flex>
              </>
            )}

            {lines.discount && (
              <>
                <Text>{lines.discount.displayName}</Text>
                <Flex justifyContent="flex-end" alignItems="center">
                  <Text color="pandaRed.500">
                    -
                    <CurrencyText
                      value={parseFloat(lines.discount.amount)}
                      decimals={2}
                    />
                  </Text>
                </Flex>
              </>
            )}

            <Text fontWeight="semibold">Due Today</Text>
            <Flex justifyContent="flex-end" alignItems="center">
              <strong>
                <CurrencyText
                  value={parseFloat(lines.total.amount)}
                  decimals={2}
                />
              </strong>
            </Flex>
          </SimpleGrid>
        )}
      </Fade>
    </Box>
  );
}

export default InvoiceLineItems;
