import { gql } from "@apollo/client";

export const GET_INVOICES = gql`
  query GetUserInvoices($includeRefunded: Boolean) {
    invoices(includeRefunded: $includeRefunded) {
      id
      amountPaid {
        amount
        currencyCode
      }
      number
      pdfUrl
      status
      createdAt
    }
  }
`;
