import ahoy from "ahoy.js";

import getEnv from "../utils/getEnv";

const baseURL = getEnv("BACKEND_HOST") || "http://localhost:3000";

ahoy.configure({
  urlPrefix: `${baseURL}/panda_trails`,
  visitsUrl: "/visits",
  eventsUrl: "/events",
  visitDuration: 30,
});

ahoy.debug(getEnv("DATA_ENV") !== "production");

export default ahoy;
