import type { Maybe } from "graphql/jsutils/Maybe";
import type { SwiperClass } from "swiper/react";

export interface IObjectKeys {
  [key: string]: string | number | undefined | boolean;
}

export type QueryPayload<ObjectKey extends string, ObjectType> = {
  [key in ObjectKey]: ObjectType;
};

export interface Invoice {
  id: string;
  number: string;
  pdfUrl: string;
  status: string;
  createdAt: string;
  amountPaid: Money;
}

export interface ImageCarouselProps {
  images: string[];
  loop?: boolean;
  onSwiper: (swiper: SwiperClass) => void;
}
export interface InvoiceData {
  invoices: Invoice[];
}

export interface Money {
  amount: string;
  currencyCode: string;
}

export type Address = {
  city: string;
  countryCode: string;
  line1: string;
  line2?: string;
  postalCode: string;
  stateCode: string;
};

export type Hub = {
  id: string;
  locationsPageCopy: string;
  name: string;
  pandacareUrl?: string;
  testRideUrl: string;
  businessHours: string;
  email: string;
  phone: string;
  googleMapsUrl: string;
  fullAddress: string;
  address: Address;
  directShipEnabled: boolean;
};

export type Location = {
  id: string;
  name: string;
  hub: Hub;
};

export type Faq = {
  answer: string;
  question: string;
};

export type Organization = {
  id: string;
  name: string;
  slug: string;
  showTheftInsurance: boolean;
  termsAndConditionsUrl: string;
  spokeFlowEnabled: boolean;
  logo: {
    url: string;
  };
  locations: Location[];
  faqs: Faq[];
  deliveryCopyHubIds: string[];
  benefitsTextEnabled: boolean;
  benefitsText?: string;
  benefitsUpdatedAt?: string;
};

export type Image = {
  id: string;
  url: string;
};

export type Brand = {
  logo: Image;
  name: string;
};

export type SpokeVehicleCategory = {
  icon: Image;
  name: string;
};

export type Spoke = {
  id: string;
  address: Address;
  fullAddress: string;
  name: string;
  brands: Brand[];
  businessHours: string;
  vehicleCategories: SpokeVehicleCategory[];
  email: string;
  googleMapsUrl: string;
  websiteUrl: string;
  locations: Location[];
  logo: Image;
  phone: string;
};

export type SpokeVehicle = {
  model: string;
  size: string;
  color: string;
  brand: Pick<Brand, "name">;
};

export enum SpokeLeaseState {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
}

export enum VehicleCategory {
  Ebike = "EBIKE",
  Escooter = "ESCOOTER",
  PedalBike = "PEDAL_BIKE",
  Test = "TEST",
}

export type SpokeLease = {
  id: string;
  state: SpokeLeaseState;
  customer: Customer;
  vehicle: SpokeVehicle;
  monthlyTotalPriceCents: number;
  currentPeriodStart: Date;
  currentPeriodEnd: Date;
  termLengthMonths: number;
};

export type Customer = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  location: Location;
  card: Card;
  organization: Organization;
};

export type Card = {
  brand: string;
  last4: string;
  expirationMonth: string;
  expirationYear: string;
};

export type Variant = {
  id: string;
  brand: string;
  model: string;
  color: string;
  colorCode: string;
  size: string;
  image: Image;
  inStock: boolean;
};

export type Plan = {
  id: string;
  price: Price;
  postSubsidyPrice: Price;
};

export type Price = {
  amount: string;
  currencyCode: string;
  displayName: string;
};

export type Vendor = {
  maxTimeToFulfill: number;
  minTimeToFulfill: number;
};

export type Vehicle = {
  id: string;
  brand: string;
  model: string;
  coreModel: boolean;
  chargeTime: string;
  details: string;
  highlights: string[];
  images: Image[];
  inStock: boolean;
  maxLoad: string;
  range: string;
  recommendationAttribution: string;
  recommendationQuote: string;
  shortDescription: string;
  topSpeed: string;
  variants: Variant[];
  plan?: Plan;
  weight: string;
  timeToFulfillWeeks: string;
};

export enum TermLength {
  TWELVE = 12,
  TWENTY_FOUR = 24,
  THIRTY_SIX = 36,
}

export type TermLengthType = keyof typeof TermLength;

export type LeasePrice = {
  leaseMonthlyPriceCents: string;
  buyoutPriceCents: string;
  totalSavingsCents: string;
};

export interface ValidationResult {
  valid: boolean;
  error: Maybe<string>;
}

export type Result<T, E = undefined> =
  | { ok: true; value: T; error?: undefined }
  | { ok: false; value?: undefined; error: E | undefined };

export const Ok = <T>(data: T): Result<T, never> => {
  return { ok: true, value: data };
};

export const Err = <E>(error?: E): Result<never, E> => {
  return { ok: false, error };
};

export interface SuccessVehicle {
  variantImageUrl: string | undefined;
  color: string;
  size: string;
  createdAt: Date;
  inStock: boolean | undefined;
  directShip: boolean | undefined;
  timeToFulfillWeeks: string;
  brand: Brand;
  model: string;
}

export enum SuccessKind {
  SUBSCRIPTION_CHECKOUT = "subscription/checkout",
  SPOKE_SIGN_UP = "spoke/signUp",
}

export type SuccessCache<T> = {
  type: SuccessKind;
  data: T;
};

export type SuccessCacheFor<Kind extends SuccessKind, T> = {
  type: Kind;
  data: T;
};

export type SubscriptionCheckoutCache = SuccessCacheFor<
  SuccessKind.SUBSCRIPTION_CHECKOUT,
  SuccessVehicle
>;

export type SpokeSignUpCache = SuccessCacheFor<
  SuccessKind.SPOKE_SIGN_UP,
  Spoke
>;
