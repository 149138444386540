import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";

import useApp from "../../contexts/AppContext";
import { tracker } from "../../services/tracking";
import { Location } from "../../types";
import PandaSelect, { SelectOption } from "../PandaSelect";

type Props = { isOpen?: boolean; onClose?: () => void };

export default function LocationSelectorModal({
  isOpen = false,
  onClose = () => {},
}: Props) {
  const { selectedLocation, setSelectedLocation, organization } = useApp();

  const [city, setCity] = useState({
    value: selectedLocation,
    label: selectedLocation?.name || "",
  });

  const organizationLocations = useMemo(
    () => organization?.locations || [],
    [organization],
  );

  const locationOptions = useMemo(
    () =>
      organizationLocations
        .filter((el) => el.hub)
        .map((location) => ({
          value: location,
          label: location.name,
        })),
    [organizationLocations],
  );

  const onCityChange = async (newValue: SelectOption<Location>) => {
    const location = newValue.value;
    setCity(newValue);

    if (location.name) {
      tracker.trackEvent("Select City", { selected_city: location.name });
    }
  };

  const selectCity = () => {
    if (!city || !city.value) {
      return;
    }

    setSelectedLocation(city.value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Your Location</ModalHeader>
        <ModalBody>
          <Box w="100%">
            <PandaSelect
              id="locationSelector"
              options={locationOptions}
              value={city}
              onChange={onCityChange as any}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={selectCity}
            variant="outline"
            colorScheme="black"
            _hover={{
              bg: "lightGray",
            }}
            _active={{ bg: "gray.300" }}
            isDisabled={!city.value}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
