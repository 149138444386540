import {
  BoxProps,
  Center,
  Divider,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import DialogWithHeader from "./DialogWithHeader";
import Card from "../Card";
import DollarIcon from "../icons/DollarIcon";

export default function TermSavingsCard(props: BoxProps) {
  return (
    <Card
      background="offWhite"
      innerProps={{ h: { base: "10rem", sm: "22rem" } }}
      {...props}
    >
      <Center h={{ base: "15%", sm: "55%" }}>
        <VStack>
          <HStack
            transform={{ base: "scale(0.65)", sm: "none" }}
            sx={{ position: "absolute" }}
          >
            <VStack gap={2}>
              <DialogWithHeader headerTitle="Shorter Lease">
                <HStack>
                  <DollarIcon width="20px" height="30px" />
                  <DollarIcon width="20px" height="30px" />
                  <DollarIcon width="20px" height="30px" />
                </HStack>
              </DialogWithHeader>
              <Divider
                orientation="vertical"
                height={2}
                borderColor="darkGray"
              />
              <Text textStyle="label" color="darkGray">
                12 MONTHS
              </Text>
            </VStack>
            <VStack gap={2}>
              <DialogWithHeader headerTitle="Standard Length">
                <HStack>
                  <DollarIcon width="20px" height="30px" />
                  <DollarIcon width="20px" height="30px" />
                  <DollarIcon
                    width="20px"
                    height="30px"
                    svgProps={{ fill: "#ECECEC" }}
                  />
                </HStack>
              </DialogWithHeader>
              <Divider
                orientation="vertical"
                height={2}
                borderColor="darkGray"
              />
              <Text textStyle="label" color="darkGray">
                24 MONTHS
              </Text>
            </VStack>
            <VStack gap={2}>
              <DialogWithHeader headerTitle="Larger Savings">
                <HStack>
                  <DollarIcon width="20px" height="30px" />
                  <DollarIcon
                    width="20px"
                    height="30px"
                    svgProps={{ fill: "#ECECEC" }}
                  />
                  <DollarIcon
                    width="20px"
                    height="30px"
                    svgProps={{ fill: "#ECECEC" }}
                  />
                </HStack>
              </DialogWithHeader>
              <Divider
                orientation="vertical"
                height={2}
                borderColor="darkGray"
              />
              <Text textStyle="label" color="darkGray">
                36 MONTHS
              </Text>
            </VStack>
            <Divider
              borderColor="darkGray"
              style={{
                position: "absolute",
                bottom: "40px",
                margin: "0px",
                minWidth: "100%",
              }}
            />
          </HStack>
        </VStack>
      </Center>
    </Card>
  );
}
