import { useQuery } from "@apollo/client";
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Link,
  Spacer,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useContext } from "react";
import {
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { CheckoutContext } from "./Context";
import TermLengthInputCard from "./TermLengthSelectCard";
import {
  GET_SPOKE_CHECKOUT_DATA,
  SpokeCheckoutSessionData,
} from "../../api/spokeCheckoutData";
import Card from "../../components/Card";
import CurrencyText from "../../components/CurrencyText";
// eslint-disable-next-line import/no-cycle
import { HomeLoader } from "../../components/HomeLoader";
import PandaCareIcon from "../../components/icons/PandaCareIcon";
import SquareRoundCheckIcon from "../../components/icons/SquareRoundCheckIcon";
import Layout from "../../components/layout/LayoutV2";
import LEGAL_URLS from "../../constants/legalUrls";
import { TermLength, TermLengthType } from "../../types";

export function LeaseLength() {
  const { sessionToken } = useParams<{ sessionToken: string }>();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { setCustomer, setTermLength, setSpokeId, setVehicle } =
    useContext(CheckoutContext);
  const toast = useToast();
  const { data, loading } = useQuery<SpokeCheckoutSessionData>(
    GET_SPOKE_CHECKOUT_DATA,
    {
      variables: { sessionToken },
    },
  );

  if (!sessionToken) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <HomeLoader />;
  }

  if (!data?.spokeCheckoutData) {
    toast({
      title: "Your checkout session has expired",
      status: "error",
      duration: 6000,
      isClosable: true,
    });
    return <Redirect to="/" />;
  }

  const {
    spokeCheckoutData: {
      model,
      size,
      color,
      brand,
      vehicleRetailPriceCents,
      accessoriesPriceCents,
      twelveMonthPrices,
      twentyFourMonthPrices,
      spoke: { id: spokeId },
      thirtySixMonthPrices,
      customer,
    },
  } = data;
  const {
    organization: { showTheftInsurance },
  } = customer;

  const onActionClick = (termLength: TermLengthType) => {
    setCustomer(customer);
    setTermLength(termLength);
    setSpokeId(spokeId);
    setVehicle({ model, size, color, brand: brand.name });

    history.push(`${url}/payment`);
  };

  return (
    <Layout>
      <Center>
        <VStack align="start" spacing={3}>
          <Text textStyle="sub-heading" color="darkGray">
            Step 1/2
          </Text>
          <Text textStyle="h1">Select length of your lease</Text>
          <Text textStyle="sub-heading">
            Please confirm that the selected vehicle information is correct and
            select your lease length
          </Text>
          <Text textStyle="sub-heading">
            Lease term will begin when payment is collected. Payment will recur
            monthly until lease term end.
          </Text>
          <Spacer py={0.8} />
          <Card width="100%" backgroundColor="offWhite" borderRadius="2xl">
            <Box py={4} paddingX={5}>
              <Text>{brand.name}</Text>
              <Text textStyle="h3">{model}</Text>
            </Box>
            <Divider />
            <Text py={4} paddingX={5}>
              {color} • {size}
            </Text>
            <Divider />{" "}
            <Box py={4} paddingX={5}>
              <Flex>
                <Text>Vehicle retail price</Text>
                <Spacer />
                <Text>
                  <CurrencyText
                    currencyDisplay="narrowSymbol"
                    value={vehicleRetailPriceCents / 100}
                    decimals={2}
                  />
                </Text>
              </Flex>
              <Flex>
                <Text>Accessories price</Text>
                <Spacer />
                <Text>
                  <CurrencyText
                    currencyDisplay="narrowSymbol"
                    value={accessoriesPriceCents / 100}
                    decimals={2}
                  />
                </Text>
              </Flex>
            </Box>
            <Divider />
            <Flex py={4} paddingX={5} textStyle="semibold">
              <Text>Total</Text>
              <Spacer />
              <Text>
                <CurrencyText
                  currencyDisplay="narrowSymbol"
                  value={
                    (vehicleRetailPriceCents + accessoriesPriceCents) / 100
                  }
                  decimals={2}
                />
              </Text>
            </Flex>
          </Card>

          <Card width="100%" overflow="hidden" borderRadius="2xl">
            <Flex backgroundColor="pandaRed.500" px={5} pt={5} pb={4}>
              <PandaCareIcon boxSize={6} />
              <Spacer />
              <Text textStyle="h4" color="white">
                PandaCare
              </Text>
              <Spacer />
              <PandaCareIcon boxSize={6} />
            </Flex>
            <VStack align="start" p={5}>
              <HStack spacing={1.5}>
                <SquareRoundCheckIcon alignSelf="center" />
                <Text textStyle="small-regular">Included with every lease</Text>
              </HStack>
              <HStack spacing={1.5}>
                <SquareRoundCheckIcon alignSelf="center" />
                <Text textStyle="small-regular">
                  Yearly maintenance credit of $100
                </Text>
              </HStack>
              {showTheftInsurance && (
                <HStack spacing={1.5}>
                  <SquareRoundCheckIcon alignSelf="center" />
                  <Text textStyle="small-regular">Theft insurance</Text>
                </HStack>
              )}
            </VStack>
          </Card>

          <TermLengthInputCard
            length={TermLength.TWELVE}
            description="Shorter lease"
            prices={twelveMonthPrices}
            onActionClick={() =>
              onActionClick(TermLength[TermLength.TWELVE] as TermLengthType)
            }
          />

          <TermLengthInputCard
            length={TermLength.TWENTY_FOUR}
            description="Standard length"
            prices={twentyFourMonthPrices}
            onActionClick={() =>
              onActionClick(
                TermLength[TermLength.TWENTY_FOUR] as TermLengthType,
              )
            }
          />

          <TermLengthInputCard
            length={TermLength.THIRTY_SIX}
            description="Larger savings"
            prices={thirtySixMonthPrices}
            onActionClick={() =>
              onActionClick(TermLength[TermLength.THIRTY_SIX] as TermLengthType)
            }
          />

          <Text textAlign="center" px="md" fontSize="sm" color="#332B04">
            By continuing you agree with our{" "}
            <Link
              href={LEGAL_URLS.TERMS_OF_SERVICE_LEASE_TO_OWN}
              isExternal
              textDecoration="underline"
              fontWeight="normal"
            >
              Terms of Service
            </Link>{" "}
            and our{" "}
            <Link
              href={LEGAL_URLS.PRIVACY_POLICY}
              isExternal
              textDecoration="underline"
              fontWeight="normal"
            >
              Privacy Policy
            </Link>
          </Text>
        </VStack>
      </Center>
    </Layout>
  );
}

export default LeaseLength;
