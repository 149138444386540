import { As, Icon, IconProps, OmitCommonProps } from "@chakra-ui/react";
import { SVGProps } from "react";

export default function ChargeTime(
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined },
) {
  return (
    <Icon viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 4C20.5435 4 19.6915 4.44766 19.1422 5.14489C18.4586 6.01253 17.6046 7 16.5 7H14.5C13.3954 7 12.5 7.89543 12.5 9V41C12.5 42.1046 13.3954 43 14.5 43H34.5C35.6046 43 36.5 42.1046 36.5 41V9C36.5 7.89543 35.6046 7 34.5 7H32.5C31.3954 7 30.5414 6.01253 29.8578 5.14489C29.3085 4.44766 28.4565 4 27.5 4H21.5Z"
        fill="black"
      />
      <path
        d="M19.7025 24.3885L24.9384 15.6423C25.6231 14.8249 25.9291 14.7478 26.2341 15.6423L24.9384 23.139H28.9843C29.6297 23.2502 29.6219 23.4965 29.2223 24.1386C29.2223 24.1386 24.4626 32.3856 23.7484 33.3846C23.0342 34.3837 22.6677 34.0693 22.7964 33.1347L24.2244 25.8879H19.9405C19.3667 25.4412 19.4283 25.0792 19.7025 24.3885Z"
        fill="#F5F5F5"
      />
    </Icon>
  );
}
