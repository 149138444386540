import { User } from "firebase/auth";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthState } from "react-firehooks/auth";

import { auth, onAuthStateChanged, signOut } from "../services/firebase";
import { identifySentryUserByEmail } from "../services/sentry";
import { tracker } from "../services/tracking";

interface AuthContextType {
  user?: User | null | undefined;
  loading: boolean;
  error?: any;
  logout: () => void;
  showLoginModal: boolean;
  setShowLoginModal: (value: boolean) => void;
  loginWithEmail: boolean;
  setLoginWithEmail: (value: boolean) => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [user, loading, error] = useAuthState(auth);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [loginWithEmail, setLoginWithEmail] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged((authUser: User | undefined) => {
      setLoadingInitial(false);

      if (authUser) {
        identifySentryUserByEmail(authUser.email);
      }

      tracker.setFirebaseId(authUser?.uid);
      tracker.setEmail(authUser?.email);
    });

    return unsubscribe;
  }, []);

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      logout: signOut,
      showLoginModal,
      setShowLoginModal,
      loginWithEmail,
      setLoginWithEmail,
    }),
    // eslint-disable-next-line
    [user, loading, showLoginModal, loginWithEmail, error],
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useCount must be used within a AuthProvider");
  }

  return context;
}
