import { Center, Spinner } from "@chakra-ui/react";

import Layout from "../layout/LayoutV2";

export function HomeLoader() {
  return (
    <Center>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="green.500"
        size="xl"
      />
    </Center>
  );
}

export function FullPageLoader() {
  return (
    <Layout>
      <HomeLoader />
    </Layout>
  );
}

export default HomeLoader;
