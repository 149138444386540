/* eslint-disable import/no-cycle */
import {
  Box,
  ContainerProps as ChakraContainerProps,
  ChakraProps,
  Container,
  Grid,
  GridProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";

import Footer from "./Footer";
import NavBar, { NAVBAR_HEIGHT, NAVBAR_MOBILE_HEIGHT } from "./Navbar";
import SectionContainer from "./SectionContainer";
import useAuth from "../../hooks/useAuth";
import useSticky from "../../hooks/useSticky";
import LoginModal from "../LoginModal";

type ContainerProps = {
  children: ReactNode;
  containerProps?: ChakraContainerProps;
  sectionProps?: ChakraProps;
};
type LayoutProps = {
  children: ReactNode;
  hideCurrentSelection?: boolean;
  hideNavbar?: boolean;
  hideFooter?: boolean;
  contentContainerProps?: ChakraContainerProps;
  contentSectionProps?: ChakraProps;
  gridProps?: GridProps;
};

function NavbarContainer({
  children,
  containerProps,
  sectionProps,
}: ContainerProps) {
  const { sticky, stickyRef } = useSticky();

  // Tricky way to have the sticky header and works with Grid layout
  return (
    <SectionContainer
      ref={stickyRef}
      h={{ base: `${NAVBAR_MOBILE_HEIGHT}px`, md: `${NAVBAR_HEIGHT}px` }}
      innerProps={containerProps}
      {...sectionProps}
    >
      <Box
        bg="white"
        boxShadow={sticky ? "md" : undefined}
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          transition: "all .5s ease",
          zIndex: 10,
        }}
      >
        <Container maxW="container.xl" px={{ base: 6, md: 8 }}>
          {children}
        </Container>
      </Box>
    </SectionContainer>
  );
}
function ContentContainer({
  children,
  containerProps,
  sectionProps,
}: ContainerProps) {
  return (
    <SectionContainer
      innerProps={{
        h: "100%",
        py: 3,
        ...containerProps,
      }}
      {...sectionProps}
    >
      {children}
    </SectionContainer>
  );
}
function FooterContainer({
  children,
  containerProps,
  sectionProps,
}: ContainerProps) {
  return (
    <SectionContainer innerProps={containerProps} {...sectionProps}>
      {children}
    </SectionContainer>
  );
}

export default function Layout({
  children,
  contentContainerProps,
  contentSectionProps,
  hideCurrentSelection = false,
  hideNavbar = false,
  hideFooter = false,
  gridProps,
}: LayoutProps) {
  const { user } = useAuth();

  return (
    <Grid
      minH="100vh"
      bg="white"
      gridTemplateRows="minmax(1em, auto) 1fr minmax(1em, auto);"
      {...gridProps}
    >
      {!hideNavbar && (
        <NavbarContainer>
          <NavBar hideCurrentSelection={hideCurrentSelection} />
        </NavbarContainer>
      )}

      <ContentContainer
        containerProps={contentContainerProps}
        sectionProps={contentSectionProps}
      >
        {children}
      </ContentContainer>

      {!hideFooter && (
        <FooterContainer>
          <Footer />
        </FooterContainer>
      )}
      {!user && <LoginModal />}
    </Grid>
  );
}
