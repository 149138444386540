import { gql } from "@apollo/client";
import { apolloQuery } from "../services/apolloClient";

export const GET_ORGANIZATIONS_WITH_SLUG_QUERY = gql`
  query getOrganizationsWithSlugs {
    organizations {
      name
      slug
    }
  }
`;

export async function getOrganizationsWithSlugs() {
  const query = GET_ORGANIZATIONS_WITH_SLUG_QUERY;

  return await apolloQuery({ query });
}
