import { Global } from "@emotion/react";

function Fonts() {
  return (
    <Global
      styles={`
        @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&family=Barlow+Condensed:wght@800&family=Suez+One&display=swap');
      `}
    />
  );
}

export default Fonts;
