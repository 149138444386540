import React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeDatadogRum } from "./services/datadog";

const renderApp = async () => {
  initializeDatadogRum();

  const container = document.getElementById("root");
  // biome-ignore lint/style/noNonNullAssertion: classic way defined on official docs
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
