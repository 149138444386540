import { Box } from "@chakra-ui/react";

export default function Card({ innerProps, ...props }: any) {
  return (
    <Box borderWidth="1px" borderRadius="lg" bg="white" {...props}>
      <Box p="0" {...innerProps}>
        {props.children}
      </Box>
    </Box>
  );
}
