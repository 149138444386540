import { useLazyQuery } from "@apollo/client";
import {
  Button,
  Container,
  FormControl,
  HStack,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";

import GET_ORGANIZATION_QUERY from "../../../api/organization";
import GridItem from "../../../components/layout/Grid/GridItem";
import {
  NAVBAR_HEIGHT,
  NAVBAR_MOBILE_HEIGHT,
} from "../../../components/layout/Navbar";
import SectionContainer from "../../../components/layout/SectionContainer";
import PandaLogo from "../../../components/PandaLogo";
import RidepandaTextIcon from "../../../components/RidepandaTextIcon";
import useApp from "../../../contexts/AppContext";
import useAuth from "../../../hooks/useAuth";
import { addSentryBreadcrumb } from "../../../services/sentry";
import { tracker } from "../../../services/tracking";
import { Location, Organization, QueryPayload } from "../../../types";

type Props = { organizations: Organization[] };

export default function Form({ organizations }: Props) {
  const { setShowLoginModal } = useAuth();
  const { setSelectedLocation } = useApp();
  const history = useHistory();

  const [organizationSlug, setOrganizationSlug] = useState("");
  const [localLocation, setLocalLocation] = useState("");
  const [availableLocations, setAvailableLocations] = useState<Location[]>([]);

  const [getOrganization, { loading: organizationLoading }] = useLazyQuery<
    QueryPayload<"organization", Organization>
  >(GET_ORGANIZATION_QUERY);

  function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    if (!organizationSlug.length || !localLocation) {
      return;
    }

    const selectedLocation = availableLocations.find(
      (loc) => loc.id === localLocation,
    );

    if (!selectedLocation) {
      return;
    }

    setSelectedLocation(selectedLocation);

    if (selectedLocation.name) {
      tracker.trackEvent("Select City", {
        selected_city: selectedLocation.name,
      });
    }

    addSentryBreadcrumb({
      category: "wizard",
      message: `Selected Organization + City: ${organizationSlug} - ${selectedLocation.name}`,
      level: "info",
    });

    history.push(`/${organizationSlug}`);
  }

  const onOrganizationChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const newOrgValue = e.target.value;

    setOrganizationSlug(newOrgValue);
    setAvailableLocations([]);

    if (newOrgValue === "") {
      return;
    }

    const { data: organizationData } = await getOrganization({
      variables: {
        slug: e.target.value,
      },
    });

    if (!organizationData?.organization.locations) {
      return;
    }
    setAvailableLocations(organizationData.organization.locations);
  };

  return (
    <GridItem area="form" colSpan={3} position="relative" zIndex={1} bg="white">
      <Container h="100%" maxW={{ md: "container.md" }}>
        <SectionContainer
          h={{
            base: `${NAVBAR_MOBILE_HEIGHT}px`,
            md: `${NAVBAR_HEIGHT}px`,
          }}
          innerProps={{ px: 0 }}
          py={3}
          position="absolute"
        >
          <HStack>
            <PandaLogo boxSize={10} />
            <RidepandaTextIcon width="auto" height="24px" />
          </HStack>
        </SectionContainer>

        <Stack
          spacing={6}
          direction="column"
          justify="center"
          h="100%"
          py={{
            base: `${NAVBAR_MOBILE_HEIGHT}px`,
            md: `${NAVBAR_HEIGHT}px`,
          }}
        >
          <Text as="h1" textStyle={{ base: "h1", md: "display" }}>
            Welcome to Ridepanda!
          </Text>
          <Text textStyle="sub-heading" fontSize="md" color="black">
            Get the perfect light electric vehicle for your daily commute!
            Select your organization and location to start browsing.
          </Text>

          <form onSubmit={onSubmit}>
            <Stack spacing={3} direction={{ base: "column", md: "row" }} mt={6}>
              <FormControl id="organizationSlug" maxWidth={{ xl: "200px" }}>
                <Select
                  placeholder="Organization"
                  size="lg"
                  onChange={onOrganizationChange}
                  isDisabled={organizationLoading}
                >
                  {organizations.map((organization: any) => {
                    return (
                      <option value={organization.slug} key={organization.slug}>
                        {organization.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl id="location">
                <Select
                  placeholder="Location"
                  size="lg"
                  disabled={!organizationSlug}
                  onChange={(e) => setLocalLocation(e.target.value)}
                >
                  {availableLocations.map((location: Location) => {
                    return (
                      <option value={location.id} key={location.id}>
                        {location.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <Button
                type="submit"
                isLoading={organizations.length === 0}
                isDisabled={organizationSlug === "" || !localLocation}
                size="lg"
                w={{ base: "100%", md: 250 }}
              >
                Get Started
              </Button>
            </Stack>
          </form>

          <Text fontSize="md" color="darkGray" textAlign="left">
            Already have a vehicle from us?
            <Button
              onClick={() => setShowLoginModal(true)}
              variant="link"
              ml={1}
              textDecor="underline"
            >
              Log in to your account
            </Button>
          </Text>
        </Stack>
      </Container>
    </GridItem>
  );
}
