import {
  Box,
  Button,
  Text,
  useMultiStyleConfig,
  useTab,
} from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";

type CustomTabProps = {
  icon: ReactElement;
  children: ReactNode;
  mobileText?: string;
};

export default React.forwardRef(
  (props: CustomTabProps, ref: React.Ref<HTMLElement>) => {
    const { mobileText, ...rest } = props;
    const tabProps = useTab({ ...rest, variant: "landing", ref });
    const styles = useMultiStyleConfig("Tabs", tabProps);

    return (
      <Button __css={styles.tab} borderTopRadius="xl" {...tabProps}>
        <Box
          as="span"
          alignSelf="center"
          display={{ base: "none", md: "inline-flex" }}
          flexShrink={0}
          marginInlineEnd={2}
        >
          {props.icon}
        </Box>

        <Text as="span" display={{ base: "inherit", md: "none" }}>
          {mobileText}
        </Text>
        <Text as="span" display={{ base: "none", md: "inherit" }}>
          {tabProps.children}
        </Text>
      </Button>
    );
  },
);
