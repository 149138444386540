import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
} from "@apollo/client";

import { getBackendConfig } from "./configs";
import getFeatureFlagId from "./featureFlag";
import { auth } from "./firebase";
import { NON_SLUG_PATHS } from "./Routes";

const uri = `${getBackendConfig()}/graphql`;
const abortController = new AbortController();
const httpLink = new HttpLink({
  uri,
  credentials: "include",
  fetchOptions: {
    mode: "cors",
    signal: abortController.signal,
  },
});

export const getSlug = () => {
  const currentPath = window.location.pathname;
  let isRestrictedPath = false;

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < NON_SLUG_PATHS.length; index++) {
    const path = NON_SLUG_PATHS[index];
    const match = currentPath.indexOf(path) === 0;

    if (match) {
      isRestrictedPath = true;
      break;
    }
  }

  if (isRestrictedPath) {
    return undefined;
  }

  return currentPath.split("/")[1];
};

const authMiddleware = new ApolloLink(async (operation, forward) => {
  const accessToken = await auth.currentUser?.getIdToken();
  const authorization = accessToken ? `Bearer ${accessToken}` : null;
  const orgSlug = auth.currentUser ? "" : getSlug();
  const featureFlagId = getFeatureFlagId();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization,
      "rp-org": orgSlug,
      "rp-ff-id": featureFlagId,
    },
  }));

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});

const isUnauthenticated = (error) => {
  const { errors } = error?.networkError?.result || {};

  return (errors || []).some((e) => e?.extensions?.code === "UNAUTHENTICATED");
};

const retryUnauthenticated = async (func) => {
  try {
    return func();
  } catch (e) {
    if (isUnauthenticated(e)) {
      await auth.currentUser?.getIdToken(true);

      return func();
    }
    throw e;
  }
};

export const apolloQuery = async (options) =>
  retryUnauthenticated(async () => apolloClient.query(options));

export const apolloMutate = async (options) =>
  retryUnauthenticated(async () => apolloClient.mutate(options));
