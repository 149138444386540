/* eslint-disable import/no-cycle */
import { Heading, VStack, Text, Link } from "@chakra-ui/react";
import Lottie from "react-lottie";
import { Link as RouterLink, useParams } from "react-router-dom";
import Card from "../../components/Card";
import animationData from "./34468-process-failed.json";
import Layout from "../../components/layout/LayoutV2";

const Canceled = () => {
  const { slug } = useParams<any>();

  return (
    <Layout>
      <Card p={4}>
        <VStack>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={200}
            width={200}
          />
          <Heading color="red.500">Payment Canceled</Heading>
          <Text textAlign="center">
            You either canceled the payment or something happend with your
            credit card,{" "}
            <Link
              as={RouterLink}
              to={`/${slug}/wizard/review`}
              fontWeight="bold"
              textDecor="underline"
            >
              you can try again
            </Link>
          </Text>
        </VStack>
      </Card>
    </Layout>
  );
};

export default Canceled;
