interface Props extends Intl.NumberFormatOptions {
  value: number;
}

export function formatter(options?: Intl.NumberFormatOptions | undefined) {
  // ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
  return new Intl.NumberFormat(undefined, options);
}

export default function NumberFormat({ value, ...rest }: Props) {
  return <>{formatter(rest).format(value)}</>;
}
