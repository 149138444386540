import { EmailIcon, PhoneIcon, TimeIcon } from "@chakra-ui/icons";
import {
  ChakraProps,
  Heading,
  Link,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";

import { tracker } from "../../services/tracking";

type HubContactProps = {
  label: string;
  phone: string;
  email: string;
  // eslint-disable-next-line react/require-default-props
  businessHours?: string;
};

export default function HubContact({
  label,
  phone,
  email,
  businessHours,
  ...props
}: HubContactProps & ChakraProps) {
  const plainPhone = phone.replaceAll(/[() -]/gi, "");
  return (
    <ListItem my={6} {...props}>
      <Heading as="h3" size="md">
        {label}
      </Heading>

      <List>
        <ListItem>
          <Link
            href={`mailto:${email}`}
            variant="pandaRed"
            onClick={() =>
              tracker.trackEvent("Email Hub", {
                selected_hub: label,
                hub_email: email,
              })
            }
          >
            <EmailIcon mr={2} />
            {email}
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href={`tel:${plainPhone}`}
            variant="pandaRed"
            onClick={() =>
              tracker.trackEvent("Phone Hub", {
                selected_hub: label,
                hub_phone: phone,
              })
            }
          >
            <PhoneIcon mr={2} />
            {phone}
          </Link>
        </ListItem>
        {!!businessHours && (
          <ListItem>
            <Text>
              <TimeIcon mr={2} />
              {businessHours}
            </Text>
          </ListItem>
        )}
      </List>
    </ListItem>
  );
}
