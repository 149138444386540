import { gql } from "@apollo/client";

import { apolloMutate } from "../services/apolloClient";

export default async function createCardUpdateSession() {
  const mutation = gql`
    mutation CardUpdateSessionCreate {
      cardUpdateSessionCreate {
        clientSecret
      }
    }
  `;

  const { data } = await apolloMutate({ mutation });
  return data.cardUpdateSessionCreate;
}
