/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-cycle */
import { useQuery } from "@apollo/client";

import Background from "./components/Background";
import Form from "./components/Form";
import { GET_ORGANIZATIONS_WITH_SLUG_QUERY } from "../../api/organizationsWithSlugs";
import { HomeLoader } from "../../components/HomeLoader";
import Grid from "../../components/layout/Grid";
import Layout from "../../components/layout/LayoutV2";
import useApp from "../../contexts/AppContext";
import Home from "../Home";

export default function Landing() {
  const { isLoading, subscription, order, spokeLease } = useApp();
  const { data } = useQuery(GET_ORGANIZATIONS_WITH_SLUG_QUERY);
  const organizations = data?.organizations || [];

  if (isLoading) {
    return <HomeLoader />;
  }

  if (subscription || order || spokeLease) {
    return <Home />;
  }

  return (
    <Layout
      hideCurrentSelection
      hideNavbar
      hideFooter
      contentContainerProps={{ maxW: "100%", px: 0, py: 0 }}
      gridProps={{
        gridTemplateRows: "1fr",
      }}
    >
      <Grid
        templateAreas={{
          base: `"form form"`,
          md: `"form form form"
               "image image image"`,
          xl: `"form form form image image image"`,
        }}
        gap={0}
        h="100%"
        overflow="hidden"
        templateColumns={{ base: "repeat(2, 1fr)", xl: "repeat(6, 1fr)" }}
        gridTemplateRows={{ md: "min-content auto" }}
        maxH="100vh"
      >
        <Form organizations={organizations} />
        <Background />
      </Grid>
    </Layout>
  );
}
