/* eslint-disable react/no-danger */
import { Box, Flex, Image, Text } from "@chakra-ui/react";

type BenefitsCardProps = {
  logoUrl?: string;
  updatedAt?: string;
  content?: string;
};

export function BenefitsCard({
  logoUrl,
  updatedAt,
  content,
}: BenefitsCardProps) {
  const formattedDate = new Date(updatedAt || "").toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      borderRadius={{ base: "none", md: "xl" }}
      border={{ base: "none", md: "1px solid lightGray" }}
      p={{ base: 6, md: 12 }}
      bg="white"
      gap={{ base: 6, md: 0 }}
    >
      <Flex
        direction="column"
        flex={1}
        justify="space-between"
        flexGrow={1}
        gap={{ base: 8, md: 0 }}
      >
        <Image src={logoUrl} maxW={120} />
        <Box>
          <Text fontSize="sm">Updated {formattedDate}</Text>
          <Text as="h3" fontSize="2rem" fontWeight={700}>
            Your Benefits
          </Text>
        </Box>
      </Flex>
      <Box w={600} fontSize="md">
        <Box
          dangerouslySetInnerHTML={{ __html: content || "" }}
          sx={{ a: { textDecor: "underline" } }}
        />
      </Box>
    </Flex>
  );
}

export default BenefitsCard;
