/* eslint-disable import/no-cycle */
import {
  Button,
  Fade,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeError } from "@stripe/stripe-js";

import { useEffect, useRef, useState } from "react";
import createCardUpdateSession from "../../api/cards";
import StripeCardForm, {
  StripeCardRefType,
} from "../PaymentMethodForm/StripeCardForm";

let stripePromise: any;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
    );
  }
  return stripePromise;
};

function InnerModal({ isOpen, onClose, ...props }: any) {
  const stripeRef = useRef<StripeCardRefType>();
  const [stripeError, setStripeError] = useState<StripeError | undefined>();
  const toast = useToast();
  const [clientSecret, setClientSecret] = useState("");

  const [stripeValid, setStripeValid] = useState(false);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const submitCard = async () => {
    if (!stripeRef.current) {
      return;
    }
    const successUrl = `${window.location.origin}`;
    const data: any = {
      payment_method: {},
      return_url: successUrl,
    };

    setIsSubmittingForm(true);
    const result = await stripeRef.current.confirmCardSetup(data);

    if (result.error) {
      setStripeError(result.error);
      setIsSubmittingForm(false);

      toast({
        title: `Card details cannot be saved. Reason: ${result.error.message}`,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } else {
      setTimeout(() => {
        window.location.href = successUrl;
      }, 1500);

      toast({
        title: "Card updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (clientSecret || !isOpen) {
        return;
      }

      const { clientSecret: responseSecret }: any =
        await createCardUpdateSession();

      setClientSecret(responseSecret);
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const buttonDisabled = !stripeValid || isSubmittingForm;

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update your card</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Fade in={!!clientSecret}>
            {clientSecret && (
              <StripeCardForm
                clientSecret={clientSecret}
                onValid={(isValid: boolean) => setStripeValid(isValid)}
                ref={stripeRef}
                stripeError={stripeError}
              />
            )}
          </Fade>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button onClick={submitCard} isDisabled={buttonDisabled}>
            Save Card
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default function UpdatePaymentMethodModal(props: any) {
  return (
    <Elements stripe={getStripe()}>
      <InnerModal {...props} />
    </Elements>
  );
}
