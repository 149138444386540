import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
  tab: {
    fontWeight: "semibold",
  },
});

const variants = {
  landing: definePartsStyle({
    tab: {
      color: "black",
      border: "1px solid",
      borderTopRadius: "md",
      borderColor: "transparent",
      marginBottom: "-2px",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      py: 4,
      px: 6,
      _selected: {
        borderColor: "inherit",
        borderBottomColor: "offWhite",
        backgroundColor: "offWhite",
      },
    },
  }),
};

export const tabsTheme = defineMultiStyleConfig({ baseStyle, variants });
export default tabsTheme;
