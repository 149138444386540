import { Box, BoxProps, ScaleFade, SimpleGrid, Stack } from "@chakra-ui/react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  ConfirmCardPaymentData,
  ConfirmCardSetupData,
  PaymentIntentResult,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeError,
} from "@stripe/stripe-js";
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { IObjectKeys } from "../../types";
import CardIconDisplay from "./CardIconDisplay";
import StripeElementInputContainer from "./StripeElementInputContainer";

const STRIPE_ELEMENTS_BASE_OPTIONS = {
  style: {
    base: {
      fontSize: "18px",
      fontFamily: '"Work Sans", sans-serif',
      color: "#1A202C",
      "::placeholder": {
        color: "#718096",
      },
    },
  },
};

export type StripeCardRefType = {
  confirmCardSetup(data: ConfirmCardSetupData): Promise<PaymentIntentResult>;
  confirmCardPayment(
    data: ConfirmCardPaymentData,
  ): Promise<PaymentIntentResult>;
};

interface CardElementsFlags extends IObjectKeys {
  cardCvc: boolean;
  cardExpiry: boolean;
  cardNumber: boolean;
}

type StripeElementChangedEvent =
  | StripeCardNumberElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardCvcElementChangeEvent;

type Props = {
  clientSecret: string;
  onValid: (isValid: boolean) => void;
  onChange?: () => void;
  stripeError?: StripeError | null;
} & BoxProps;

function StripeCardForm(
  props: Props,
  ref: Ref<unknown> | undefined,
): JSX.Element {
  const { clientSecret, onChange, onValid, stripeError, ...rest } = props;
  const stripe = useStripe();
  const elements = useElements();
  const cardElement = elements?.getElement(CardNumberElement);
  const cvcElement = elements?.getElement(CardCvcElement);
  const expiryElement = elements?.getElement(CardExpiryElement);
  const [cardBrand, setCardBrand] = useState<string>("");

  const [status, setStatus] = useState<CardElementsFlags>({
    cardCvc: false,
    cardExpiry: false,
    cardNumber: false,
  });

  const [dirtyStatus, setDirtyStatus] = useState<CardElementsFlags>({
    cardCvc: false,
    cardExpiry: false,
    cardNumber: false,
  });

  const onStripeElementChanged = (event: StripeElementChangedEvent) => {
    if (onChange) {
      onChange();
    }

    if (event.elementType === "cardNumber" && event.brand) {
      setCardBrand(event.brand);
    }

    setStatus((prevValue) => ({
      ...prevValue,
      [event.elementType]: event.complete,
    }));
  };

  const onStripeElementFocus = (key: string) => {
    if (!dirtyStatus[key]) {
      setDirtyStatus((prevValue: CardElementsFlags) => ({
        ...prevValue,
        [key]: true,
      }));
    }
  };

  useImperativeHandle(ref, () => ({
    async confirmCardSetup(data: ConfirmCardSetupData) {
      if (!elements || !cardElement) {
        return false;
      }

      const dataWithCard = {
        ...data,
        payment_method: {
          ...(data.payment_method as any),
          card: cardElement,
        },
      };

      return stripe?.confirmCardSetup(clientSecret, dataWithCard);
    },
    async confirmCardPayment(data: ConfirmCardSetupData) {
      if (!elements || !cardElement) {
        return false;
      }

      const dataWithCard = {
        ...data,
        payment_method: {
          ...(data.payment_method as any),
          card: cardElement,
        },
      };

      return stripe?.confirmCardPayment(clientSecret, dataWithCard);
    },
  }));

  useEffect(() => {
    onValid(status.cardNumber && status.cardCvc && status.cardExpiry);
  }, [status, onValid]);

  useEffect(() => {
    if (!stripeError || !stripeError.code) {
      return;
    }

    switch (stripeError.code) {
      case "card_declined":
      case "incomplete_number":
        cardElement?.focus();
        break;
      case "expired_card":
      case "incomplete_expiry":
        expiryElement?.focus();
        break;
      case "incorrect_cvc":
      case "incomplete_cvc":
        cvcElement?.focus();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeError]);

  const showCardIcon = !!cardBrand && cardBrand !== "unknown";

  return (
    <Stack spacing={0} {...rest}>
      <Box position="relative">
        <StripeElementInputContainer
          isValid={!(dirtyStatus.cardNumber && !status.cardNumber)}
          style={{
            borderBottomRightRadius: "none",
            borderBottomLeftRadius: "none",
            borderBottom: "none",
            height: "var(--chakra-sizes-12)",
            width: "full",
            paddingLeft: showCardIcon ? 16 : 5,
          }}
        >
          <ScaleFade initialScale={0.9} in={showCardIcon}>
            {showCardIcon && <CardIconDisplay cardBrand={cardBrand} />}
          </ScaleFade>
          <CardNumberElement
            options={STRIPE_ELEMENTS_BASE_OPTIONS}
            onChange={onStripeElementChanged}
            onFocus={() => onStripeElementFocus("cardNumber")}
          />
        </StripeElementInputContainer>
      </Box>

      <SimpleGrid columns={2}>
        <StripeElementInputContainer
          isValid={!(dirtyStatus.cardExpiry && !status.cardExpiry)}
          style={{
            borderTopRadius: "none",
            borderBottomRightRadius: "none",
            height: "var(--chakra-sizes-12)",
          }}
        >
          <CardExpiryElement
            options={STRIPE_ELEMENTS_BASE_OPTIONS}
            onChange={onStripeElementChanged}
            onFocus={() => onStripeElementFocus("cardExpiry")}
          />
        </StripeElementInputContainer>

        <StripeElementInputContainer
          isValid={!(dirtyStatus.cardCvc && !status.cardCvc)}
          style={{
            borderBottomLeftRadius: "none",
            borderTopRadius: "none",
            height: "var(--chakra-sizes-12)",
          }}
        >
          <CardCvcElement
            options={STRIPE_ELEMENTS_BASE_OPTIONS}
            onChange={onStripeElementChanged}
            onFocus={() => onStripeElementFocus("cardCvc")}
          />
        </StripeElementInputContainer>
      </SimpleGrid>
    </Stack>
  );
}

export default forwardRef(StripeCardForm);
