import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { MdOutlineArrowDownward } from "react-icons/md";

import VehicleListSection from "./VehicleListSection";
import Faqs from "../../../../components/Faqs";
import HubInfoBanner from "../../../../components/HubInfoBanner";
import Grid from "../../../../components/layout/Grid";
import GridItem from "../../../../components/layout/Grid/GridItem";
import SectionContainer from "../../../../components/layout/SectionContainer";
import PerksBanner from "../../../../components/PerksBanner";
import TestRideBanner from "../../../../components/TestRideBanner";
import TestRideButton from "../../../../components/TestRideButton";
import { Organization } from "../../../../types";
import { BenefitsCard } from "../BenefitsCard";

function PandaHubContent({
  wizardProps,
  onBrowseVehicles,
  organization,
}: {
  wizardProps: any;
  onBrowseVehicles: () => void;
  organization: Organization;
}) {
  return (
    <Box>
      <SectionContainer py={8} position="relative">
        <Grid
          mt={8}
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(6, 1fr)" }}
          templateAreas={{
            base: `"image"
                   "content"`,
            lg: `"content image"`,
          }}
        >
          <GridItem
            colSpan={3}
            pr={{ base: 0, lg: 12, xl: "137px" }}
            area="content"
          >
            <Heading>PandaHub vehicle subscription plan</Heading>
            <Text fontSize="lg" mt={4}>
              Our tried and true month-to-month subscription plan. Choose from
              top brands. Swap anytime. Leaving your employer or city? Opt out
              for free.
            </Text>
            <Text fontSize="lg" mt={6}>
              Order online today and pick up at your local PandaHub, or swing by
              for a test ride!
            </Text>

            <Stack mt={12} direction={{ base: "column", lg: "row" }}>
              <Button
                size="lg"
                onClick={onBrowseVehicles}
                rightIcon={<Icon as={MdOutlineArrowDownward} />}
              >
                Browse vehicles
              </Button>

              <TestRideButton />
            </Stack>
          </GridItem>

          <GridItem colSpan={3} area="image">
            <Image src="/assets/happy-riders.png" borderRadius="xl" />
          </GridItem>
        </Grid>

        <Grid my={{ base: 12, md: 16 }}>
          <GridItem colSpan={6}>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 8, md: 42, lg: 82 }}
            >
              <Flex direction="column" maxW={{ base: "100%", md: 300 }}>
                <Text as="h4" textStyle="h4">
                  <Text as="span" color="pandaRed.500" textStyle="h4" mr={2}>
                    01
                  </Text>
                  Select your vehicle
                </Text>

                <Text>
                  Browse and select a vehicle online or schedule a test ride at
                  our PandaHub where our experts will help you pick a ride
                  that&apos;s right for you!
                </Text>
              </Flex>
              <Flex direction="column" maxW={{ base: "100%", md: 300 }}>
                <Text as="h4" textStyle="h4">
                  <Text as="span" color="pandaRed.500" textStyle="h4" mr={2}>
                    02
                  </Text>
                  Schedule a pick up
                </Text>

                <Text>
                  We offer 24-hour pickup on vehicles in stock at our PandaHub.
                  Vehicles on backorder arrive in 2-5 weeks. Office delivery
                  available at some sites - check with your local hub.
                </Text>
              </Flex>
              <Flex direction="column" maxW={{ base: "100%", md: 300 }}>
                <Text as="h4" textStyle="h4">
                  <Text as="span" color="pandaRed.500" textStyle="h4" mr={2}>
                    03
                  </Text>
                  Enjoy your ride
                </Text>

                <Text>
                  Your subscription includes theft insurance and regular
                  maintenance, so you can be sure your ride is always in a good
                  condition.
                </Text>
              </Flex>
            </Stack>
          </GridItem>
        </Grid>
      </SectionContainer>

      {organization?.benefitsTextEnabled && (
        <SectionContainer
          bg="linear-gradient(180deg, #fafafa 50%, #ffffff 50%)"
          innerProps={{ px: { base: 0, md: 8 } }}
        >
          <BenefitsCard
            updatedAt={organization.benefitsUpdatedAt}
            content={organization.benefitsText}
            logoUrl={organization.logo?.url}
          />
        </SectionContainer>
      )}

      <SectionContainer
        innerProps={{ px: { base: 0, md: 8 } }}
        bg={
          organization?.benefitsTextEnabled
            ? "white"
            : "linear-gradient(180deg, #fafafa 50%, #ffffff 50%)"
        }
        pt={organization?.benefitsTextEnabled ? 8 : "0"}
      >
        <HubInfoBanner />
      </SectionContainer>

      <VehicleListSection wizardProps={wizardProps} />

      <SectionContainer
        innerProps={{ px: { base: 0, md: 8 }, pb: { base: 8, md: 24 } }}
        bg="white"
      >
        <TestRideBanner />
      </SectionContainer>
      <SectionContainer py={{ base: 10, md: 24 }} bg="offWhite">
        <PerksBanner showTheftInsurance={!!organization?.showTheftInsurance} />
      </SectionContainer>
      <SectionContainer py={{ base: 10, md: 24 }} bg="white">
        <Faqs />
      </SectionContainer>
    </Box>
  );
}

export default PandaHubContent;
