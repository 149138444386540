import { Heading, Text } from "@chakra-ui/react";

import VehicleItem from "./VehicleItem";
import Grid from "../../../../components/layout/Grid";
import GridItem from "../../../../components/layout/Grid/GridItem";
import SectionContainer from "../../../../components/layout/SectionContainer";
import useApp from "../../../../contexts/AppContext";
import { addSentryBreadcrumb } from "../../../../services/sentry";
import { tracker } from "../../../../services/tracking";
import { Vehicle } from "../../../../types";

type Props = { wizardProps: any };
export default function VehicleListSection({ wizardProps }: Props) {
  const { setSelectedVehicle, hasDirectShipFlow } = wizardProps;
  const {
    organization,
    availableVehicles,
    setSelectedVehicle: setStoredVehicle,
  } = useApp();
  const header = hasDirectShipFlow
    ? "Select your ride"
    : "Select your vehicle online";

  return (
    <SectionContainer py={{ base: 10, md: 24 }} bg="white" id="vehiclesSection">
      <Heading textAlign="center">{header}</Heading>
      <Text textAlign="center" textStyle="sub-heading">
        Choose a vehicle and start your subscription
      </Text>

      <Grid
        mt={16}
        templateColumns={{
          base: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
          lg: "repeat(6, 1fr)",
        }}
      >
        {availableVehicles.map((vehicle: Vehicle) => (
          <GridItem colSpan={2} key={vehicle.id}>
            <VehicleItem
              vehicle={vehicle}
              routePath={`/${organization?.slug}/wizard/options?t&=${vehicle.brand} ${vehicle.model}`}
              onClick={(selectedVehicle: Vehicle) => {
                setStoredVehicle(selectedVehicle);
                setSelectedVehicle(selectedVehicle);
                const { brand, model } = selectedVehicle;
                tracker.trackEvent("Vehicle Viewed", { brand, model });

                addSentryBreadcrumb({
                  category: "wizard",
                  message: `Selected Vehicle: ${brand} - ${model}`,
                  level: "info",
                });
              }}
            />
          </GridItem>
        ))}
      </Grid>
    </SectionContainer>
  );
}
