import { useCallback, useState } from "react";
import secureLocalStorage from "react-secure-storage";

export function getItem<T>(key: string, updateTimestamp = true) {
  if (typeof window === "undefined") return null;

  if (updateTimestamp) {
    secureLocalStorage.setItem("updatedAt", new Date().toISOString());
  }

  const item = secureLocalStorage.getItem(key) as string;
  if (!item) return null;

  try {
    return JSON.parse(item) as T;
  } catch {
    return null;
  }
}

export function setItem(key: any, value: any) {
  secureLocalStorage.setItem(key, JSON.stringify(value));
  secureLocalStorage.setItem("updatedAt", new Date().toISOString());

  return value;
}

export default function useLocalStorage(key: string, initialValue: any) {
  const readValue = useCallback(() => {
    try {
      return getItem(key) || initialValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: any) => {
      try {
        // Allow value to be a function, so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        setItem(key, valueToStore);
      } catch (error) {
        // A more advanced implementation would handle the error case
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    [key, storedValue],
  );

  return [storedValue, setValue];
}
