/* eslint-disable import/no-cycle */
import { useEffect, useLayoutEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { tracker } from "./tracking";
import NotFound from "../components/NotFound";
import Home from "../domain/Home";
import Landing from "../domain/Landing";
import Login from "../domain/Login";
import SpokeCalculator from "../domain/SpokeCalculator";
import Canceled from "../domain/SubscriptionStatus/Canceled";
import Success from "../domain/SubscriptionStatus/Success";
import Wizard from "../domain/Wizard";
import { SpokeRoutes } from "../routes/SpokeRoutes";

export const RESERVED_ROUTE_PATHS = ["/wizard", "/success", "/canceled"];
export const NON_SLUG_PATHS = RESERVED_ROUTE_PATHS.concat([
  "/404",
  "/login",
  "/spokecalculator",
  "/c/",
]);

export default function Routes() {
  const location = useLocation<any>();
  const history = useHistory();
  const background = location.state && location.state.background;

  useLayoutEffect(
    () =>
      history.listen(() => {
        tracker.trackView();
      }),
    [history],
  );

  useEffect(() => {
    // Slugs cannot be a reserved path
    for (let index = 0; index < RESERVED_ROUTE_PATHS.length; index += 1) {
      const path = RESERVED_ROUTE_PATHS[index];
      const match = location.pathname.indexOf(path) === 0;

      if (match) {
        history.replace("/");
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Switch location={background || location}>
      <Route path="/404">
        <NotFound />
      </Route>

      <Route path="/" exact>
        <Landing />
      </Route>
      <Route path="/login/e/:email">
        <Login />
      </Route>
      <Route path="/login">
        <Login />
      </Route>

      <Route
        path="/dealershipcalculator"
        render={() => <Redirect to="/spokecalculator" />}
      />

      <Route path="/spokecalculator">
        <SpokeCalculator />
      </Route>

      <Route path="/c">
        <SpokeRoutes />
      </Route>

      <Route path="/:slug/login" render={() => <Redirect to="/login" />} />

      <Route path="/:slug/success" exact>
        <Success />
      </Route>
      <Route path="/:slug/canceled" exact>
        <Canceled />
      </Route>
      <Route path="/:slug/wizard">
        <Wizard />
      </Route>
      <Route path="/:slug">
        <Home />
      </Route>

      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
