import { Flex, Image, Text } from "@chakra-ui/react";

import GridItem from "./layout/Grid/GridItem";

interface PerkItemProps {
  title: string;
  content: string;
  imageSrc: string;
}

export function PerkItem({ title, content, imageSrc }: PerkItemProps) {
  return (
    <GridItem colSpan={3}>
      <Flex alignItems="flex-start" h="100%">
        <Image src={imageSrc} mr={4} />
        <Flex alignItems="flex-start" direction="column">
          <Text textStyle="h4">{title}</Text>
          <Text mt={2} textStyle="large-regular">
            {content}
          </Text>
        </Flex>
      </Flex>
    </GridItem>
  );
}

export default PerkItem;
