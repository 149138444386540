import { Box, ChakraProps, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useRouteMatch } from "react-router-dom";

import { WIZARD_STEPS } from "..";
import RouterLink from "../../../components/RouterLink";
import useApp from "../../../contexts/AppContext";

function BreadItem({ children, activeIndex, expectedIndex, ...props }: any) {
  const { organization } = useApp();
  const match = useRouteMatch(`/:slug/wizard${props.step.path}`);
  const isActive = match?.isExact || false;
  const isBold = activeIndex >= expectedIndex;
  const textColor = isBold ? "black" : "darkGray";
  const Component = activeIndex < expectedIndex + 1 ? Box : RouterLink;

  return (
    <Component
      fontSize={{ base: "10pt", sm: "inherit" }}
      fontWeight={isBold ? 600 : 400}
      color={isActive ? "pandaRed.500" : textColor}
      to={`/${organization?.slug}/wizard${props.step.path}`}
      {...props}
    >
      {children}
    </Component>
  );
}

type Props = {} & ChakraProps;

export default function Breadcrumbs(props: Props) {
  const steps = WIZARD_STEPS;

  const basePathIndex =
    window.location.pathname.lastIndexOf("wizard") + "wizard".length;
  const basePath = window.location.pathname.substring(basePathIndex);
  const activeIndex = steps.findIndex((step: any) => step.path === basePath);

  return (
    <Box>
      <HStack justifyContent="center" {...props}>
        {steps
          .filter((step, stepIndex) => {
            if (!step.label) return false;

            return activeIndex >= stepIndex;
          })
          .map((step, stepIndex, filteredArray) => {
            return (
              <React.Fragment key={step.label}>
                <BreadItem
                  activeIndex={activeIndex}
                  expectedIndex={stepIndex}
                  step={step}
                >
                  {step.label}
                </BreadItem>
                {stepIndex < filteredArray.length - 1 && (
                  <Text as="span" color="darkGray">
                    /
                  </Text>
                )}
              </React.Fragment>
            );
          })}
      </HStack>
    </Box>
  );
}
