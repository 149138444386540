/* eslint-disable import/no-cycle */

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { SpokeContextProvider } from "../domain/SpokeCheckout/Context";
import { LeaseLength } from "../domain/SpokeCheckout/LeaseLength";
import { Payment } from "../domain/SpokeCheckout/Payment";
import Success from "../domain/SpokeCheckout/Success";

export function SpokeRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} render={() => <Redirect to="/" />} />

      <SpokeContextProvider>
        <Route path={`${path}/:sessionToken/payment`} exact>
          <Payment />
        </Route>
        <Route path={`${path}/:sessionToken`} exact>
          <LeaseLength />
        </Route>
        <Route path={`${path}/:sessionToken/success`} exact>
          <Success />
        </Route>
      </SpokeContextProvider>
    </Switch>
  );
}

export default SpokeRoutes;
