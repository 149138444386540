import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { NumericFormat } from "react-number-format";

import Layout from "../../components/layout/LayoutV2";
import LeaseLengthCard from "../../components/LeaseCalculatorModal/LeaseLengthCard";
import TermSavingsCard from "../../components/LeaseCalculatorModal/TermSavingsCard";
import { LeaseToOwnAgreementURL } from "../../constants/spokes";
import useDebounce from "../../hooks/useDebounce";
import { TermLength } from "../../types";

const formInputStyle = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderLeft: 0,
  paddingLeft: 2,
  height: "3.75rem",
};
const inputLeftAddonStyle = {
  backgroundColor: "white",
  borderRight: "solid 1px #ECECEC",
  height: "3.75rem",
};

type Props = {
  useLayout?: boolean;
};

export default function SpokeCalculator({ useLayout = true }: Props) {
  const [vehiclePrice, setVehiclePrice] = useState<number | undefined>();
  const [accessoriesPrice, setAccessoriesPrice] = useState<
    number | undefined
  >();
  const [employerContribution, setEmployerContribution] = useState<
    number | undefined
  >();

  const debouncedVehiclePrice = useDebounce(vehiclePrice, 800);
  const debouncedAccessoriesPrice = useDebounce(accessoriesPrice, 800);
  const debouncedEmployerContribution = useDebounce(employerContribution, 800);

  const vehiclePriceCents = debouncedVehiclePrice * 100;
  const accessoriesPriceCents = debouncedAccessoriesPrice * 100;
  const employerContributionCents = debouncedEmployerContribution * 100;
  const Wrapper = useLayout ? Layout : Box;
  return (
    <Wrapper>
      <Box>
        <Text as="h2" textStyle="h2">
          Lease Pricing Calculator
        </Text>
        <Text textStyle="sub-heading">
          Esimate how much you can save by leasing with Ridepanda
        </Text>
      </Box>
      <Grid
        marginTop={5}
        gap={5}
        gridTemplateColumns={{
          base: "1fr",
          lg: "2fr 1fr",
        }}
      >
        <GridItem>
          <TermSavingsCard />
        </GridItem>
        <GridItem>
          <VStack spacing="1.5rem" mt={6}>
            <FormControl>
              <FormLabel fontWeight="semibold">Vehicle Price</FormLabel>
              <InputGroup mt={2} size="lg">
                <InputLeftAddon sx={inputLeftAddonStyle}>$</InputLeftAddon>
                <NumericFormat
                  allowNegative={false}
                  value={vehiclePrice}
                  thousandSeparator=","
                  customInput={Input}
                  size="lg"
                  sx={formInputStyle}
                  onValueChange={(values) => {
                    setVehiclePrice(values.floatValue);
                  }}
                  placeholder="0.00"
                />
              </InputGroup>
            </FormControl>

            <FormControl>
              <FormLabel fontWeight="semibold">Accessories Price</FormLabel>
              <InputGroup mt={2} size="lg">
                <InputLeftAddon sx={inputLeftAddonStyle}>$</InputLeftAddon>
                <NumericFormat
                  allowNegative={false}
                  value={accessoriesPrice}
                  thousandSeparator=","
                  customInput={Input}
                  size="lg"
                  sx={formInputStyle}
                  onValueChange={(values) => {
                    setAccessoriesPrice(values.floatValue);
                  }}
                  placeholder="0.00"
                />
              </InputGroup>
            </FormControl>

            <FormControl mt={6}>
              <FormLabel fontWeight="semibold">
                Employer&apos;s Monthly Contribution**
              </FormLabel>
              <InputGroup mt={2} size="lg">
                <InputLeftAddon sx={inputLeftAddonStyle}>$</InputLeftAddon>
                <NumericFormat
                  allowNegative={false}
                  value={employerContribution}
                  thousandSeparator=","
                  customInput={Input}
                  size="lg"
                  sx={formInputStyle}
                  onValueChange={(values) => {
                    setEmployerContribution(values.floatValue);
                  }}
                  placeholder="0.00"
                />
              </InputGroup>
            </FormControl>
          </VStack>
        </GridItem>
      </Grid>
      <Divider my={5} />
      <HStack>
        <Text
          textStyle="label"
          lineHeight="1.3125rem"
          textTransform="uppercase"
        >
          LEASE LENGTH OPTIONS
        </Text>
        <Tooltip
          label="Displayed prices are estimates. Final pricing at checkout might vary slightly."
          aria-label="Tooltip with information about lease length options"
          placement="right"
        >
          <QuestionOutlineIcon boxSize={3} />
        </Tooltip>
      </HStack>
      <Grid
        mt={3}
        gap={5}
        gridTemplateColumns={{
          base: "1fr",
          lg: "1fr 1fr 1fr",
        }}
      >
        <GridItem>
          <LeaseLengthCard
            lengthTitle="Shorter lease"
            length={TermLength.TWELVE}
            vehicleRetailPriceCents={vehiclePriceCents}
            accessoriesRetailPriceCents={accessoriesPriceCents || 0}
            employerSubsidyAmountCents={employerContributionCents || 0}
          />
        </GridItem>
        <GridItem>
          <LeaseLengthCard
            lengthTitle="Standard length"
            length={TermLength.TWENTY_FOUR}
            vehicleRetailPriceCents={vehiclePriceCents}
            accessoriesRetailPriceCents={accessoriesPriceCents || 0}
            employerSubsidyAmountCents={employerContributionCents || 0}
          />
        </GridItem>
        <GridItem>
          <LeaseLengthCard
            lengthTitle="Larger savings"
            length={TermLength.THIRTY_SIX}
            vehicleRetailPriceCents={vehiclePriceCents}
            accessoriesRetailPriceCents={accessoriesPriceCents || 0}
            employerSubsidyAmountCents={employerContributionCents || 0}
          />
        </GridItem>
      </Grid>
      <Flex direction="column" gap={6} mt={6}>
        <Text fontSize="lg">
          Lease will begin when you check out at our partner shop. Payment will
          be collected on lease start and recur monthly until lease term end.{" "}
          <Link
            textDecoration="underline"
            fontWeight={400}
            href={LeaseToOwnAgreementURL}
          >
            Click here
          </Link>{" "}
          to review Terms and Conditions.
        </Text>
        <Text fontSize="lg">
          **Depending on your employer, their contribution will either be
          applied at checkout, or as a direct reimbursement outside of
          Ridepanda.
        </Text>
      </Flex>
    </Wrapper>
  );
}
