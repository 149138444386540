import { Box, Flex, Heading, Image, Link, Stack, Text } from "@chakra-ui/react";

import Card from "../../components/Card";
import { MAIL_TO_SUPPORT, SUPPORT_EMAIL } from "../../constants/mailing";
import { tracker } from "../../services/tracking";

export default function EmptyVehicleList() {
  return (
    <Flex justify="center">
      <Card p={6} maxW="xl">
        <Stack>
          <Flex justify="center">
            <Image
              src="/assets/repair.png"
              alt="Ridepanda on Repair"
              boxSize="250px"
            />
          </Flex>
          <Box>
            <Heading>Thanks for visiting!</Heading>
            <Text mb={3}>
              Unfortunately, we are not offering new leases for members in your
              area at this time. Contact{" "}
              <Link
                href={MAIL_TO_SUPPORT}
                color="teal"
                textDecor="underline"
                onClick={() => tracker.trackEvent("Email lease@")}
              >
                {SUPPORT_EMAIL}
              </Link>{" "}
              to learn more about our program or{" "}
              <Link href="/login" color="teal" textDecor="underline">
                sign in
              </Link>{" "}
              to view your active lease.
            </Text>
          </Box>
        </Stack>
      </Card>
    </Flex>
  );
}
