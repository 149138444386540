import { Image, ImageProps } from "@chakra-ui/react";
import { useCallback, useState } from "react";

const PandaLogos = [
  "/assets/logos/panda-peek.svg",
  "/assets/logos/panda-v2.svg",
  "/assets/logos/panda-default.svg",
  "/assets/logos/panda-happy.svg",
  "/assets/logos/panda-irresponsible.svg",
  "/assets/logos/panda-pride.svg",
];

export default function FunPandaLogo({ boxSize, alt }: ImageProps) {
  const [logoIndex, setLogoIndex] = useState(0);

  const handleImageChange = useCallback(() => {
    setLogoIndex((logoIndex + 1) % PandaLogos.length);
  }, [logoIndex]);

  return (
    <Image
      src={PandaLogos[logoIndex]}
      cursor="pointer"
      onClick={handleImageChange}
      boxSize={boxSize || { base: 6, md: 12 }}
      alt={alt}
    />
  );
}
