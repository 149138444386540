import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { MdOutlineArrowDownward } from "react-icons/md";

import VehicleListSection from "./VehicleListSection";
import Grid from "../../../../components/layout/Grid";
import GridItem from "../../../../components/layout/Grid/GridItem";
import SectionContainer from "../../../../components/layout/SectionContainer";

export function ContactSupportCard({
  backgroundColor = "#F9F7F4",
}: {
  backgroundColor?: string;
}) {
  return (
    <SectionContainer
      py={{ base: 10, md: 24 }}
      backgroundColor={backgroundColor}
    >
      <Flex direction="column" align="center" gap={8}>
        <Box>
          <Heading as="h2" textStyle="h2" mb={4}>
            Questions about our program?
          </Heading>
          <Text textStyle="body" textAlign="center">
            Contact us for any additional information.
          </Text>
        </Box>

        <Link href="mailto:support@ridepanda.com?subject=Direct Ship Inquiry">
          <Button
            variant="outline"
            colorScheme="gray"
            size="lg"
            borderColor="#221203"
          >
            Contact support
          </Button>
        </Link>
      </Flex>
    </SectionContainer>
  );
}

function DirectShipContent({
  wizardProps,
  onBrowseVehicles,
}: {
  wizardProps: any;
  onBrowseVehicles: () => void;
}) {
  return (
    <Box>
      <SectionContainer py={8} position="relative">
        <Grid
          mt={8}
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(6, 1fr)" }}
          templateAreas={{
            base: `"image"
                   "content"`,
            lg: `"content image"`,
          }}
        >
          <GridItem
            colSpan={3}
            pr={{ base: 0, lg: 12, xl: "137px" }}
            area="content"
          >
            <Heading>Your new commute starts today!</Heading>
            <Text fontSize="lg" mt={4}>
              Our tried and true month-to-month subscription plan. High-quality
              vehicles. Quick fulfillment. Leaving your employer or city? Opt
              out for free.
            </Text>
            <Text fontSize="lg" mt={6}>
              Order online today and receive your vehicle in 1-2 weeks!
            </Text>

            <Stack mt={12} direction={{ base: "column", lg: "row" }}>
              <Button
                size="lg"
                onClick={onBrowseVehicles}
                rightIcon={<Icon as={MdOutlineArrowDownward} />}
              >
                Browse vehicles
              </Button>
            </Stack>
          </GridItem>

          <GridItem colSpan={3} area="image">
            <Image src="/assets/happy-riders.png" borderRadius="xl" />
          </GridItem>
        </Grid>

        <Grid my={{ base: 12, md: 16 }}>
          <GridItem colSpan={6}>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 8, md: 42, lg: 82 }}
            >
              <Flex direction="column" maxW={{ base: "100%", md: 300 }}>
                <Text as="h4" textStyle="h4">
                  <Text as="span" color="pandaRed.500" textStyle="h4" mr={2}>
                    01
                  </Text>
                  Select your vehicle
                </Text>

                <Text>Browse our online catalog and place an order today.</Text>
              </Flex>
              <Flex direction="column" maxW={{ base: "100%", md: 300 }}>
                <Text as="h4" textStyle="h4">
                  <Text as="span" color="pandaRed.500" textStyle="h4" mr={2}>
                    02
                  </Text>
                  We ship to you
                </Text>

                <Text>
                  Your ride will arrive in 1-2 weeks. You&apos;ll receive an
                  email to let you know when your subscription has officially
                  begun.
                </Text>
              </Flex>
              <Flex direction="column" maxW={{ base: "100%", md: 300 }}>
                <Text as="h4" textStyle="h4">
                  <Text as="span" color="pandaRed.500" textStyle="h4" mr={2}>
                    03
                  </Text>
                  Enjoy your ride
                </Text>

                <Text>
                  Save your box to send the vehicle back to Ridepanda for repair
                  or replacement.
                </Text>
              </Flex>
            </Stack>
          </GridItem>
        </Grid>
      </SectionContainer>

      <VehicleListSection wizardProps={wizardProps} />

      <ContactSupportCard />
    </Box>
  );
}

export default DirectShipContent;
