import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import getEnv from "../utils/getEnv";

export default function initializeSentry() {
  const environment = getEnv("DATA_ENV");

  if (environment === "development") {
    return;
  }

  Sentry.init({
    dsn: getEnv("SENTRY_DSN"),
    release: getEnv("SENTRY_RELEASE"),
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        networkDetailAllowUrls: [
          window.location.origin,
          `${getEnv("BACKEND_HOST")}/graphql`,
        ],
        networkRequestHeaders: [
          "authorization",
          "rp-org",
          "referrer",
          "sentry-trace",
          "baggage",
        ],
        networkResponseHeaders: ["Server"],
      }),
    ],
    environment,
    // ignore error coming from recaptcha:
    //   https://github.com/getsentry/sentry-javascript/issues/2514
    beforeSend(event, hint) {
      if (hint.originalException === "Timeout") return null;
      return event;
    },
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === "ui.click") {
        return null;
      }

      if (
        breadcrumb.category === "console" &&
        breadcrumb.message.includes("GrowSurf")
      ) {
        return null;
      }

      return breadcrumb.category === "ui.click" ? null : breadcrumb;
    },
    tracePropagationTargets: [getEnv("BACKEND_HOST")],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment === "production" ? 0.2 : 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: environment === "production" ? 0.2 : 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      // We currently use stripe-js for customers to save their credit card data
      // I'm unable to reproduce this error on local, staging nor prod across chrome, safari or firefox
      // Based on https://github.com/stripe/stripe-js/issues/26, it seems like this error is safe to ignore,
      "Failed to load Stripe.js",
    ],
  });
}

export function identifySentryUserByEmail(email) {
  Sentry.setUser({ email });
}

export function setSentryContext(name, context) {
  Sentry.setContext(name, context);
}

export function addSentryBreadcrumb(breadcrumb) {
  Sentry.addBreadcrumb(breadcrumb);
}
