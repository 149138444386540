import { useEffect } from "react";
import {
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { LeaseDetails } from "./components/LeaseDetails";
import { SubscriptionDetails } from "./components/SubscriptionDetails";
import Layout from "../../components/layout/LayoutV2";
import useApp from "../../contexts/AppContext";
import useAuth from "../../hooks/useAuth";

function Home() {
  const { slug } = useParams<any>();
  const { customer, order, subscription, spokeLease } = useApp();
  const { user } = useAuth();
  const history = useHistory();
  const isSlugHome = useRouteMatch({
    path: "/:slug",
    strict: true,
  });

  useEffect(() => {
    if (user) {
      return;
    }

    history.go(0);
  }, [user, history]);

  if (!customer) {
    return <Redirect to={`/${slug}/wizard`} />;
  }

  if (isSlugHome) {
    return <Redirect to="/" />;
  }

  if (spokeLease) {
    return <LeaseDetails lease={spokeLease} customer={customer} />;
  }

  const plan = subscription?.plan || order?.plan;
  const variant = subscription?.variant || order?.variant;

  if (!(plan && variant)) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <SubscriptionDetails
        customer={customer}
        state={subscription?.state}
        plan={plan}
        variant={variant}
        startDate={subscription?.startDate}
        currentPeriodEnd={subscription?.currentPeriodEnd}
      />
    </Layout>
  );
}

export default Home;
