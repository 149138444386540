import { Box, SimpleGrid, Skeleton, VStack } from "@chakra-ui/react";

function CheckoutFormSkeleton() {
  const largeInputHeight = "var(--chakra-sizes-12)";

  return (
    <VStack w="full" alignItems="start" spacing={5}>
      <SimpleGrid w="full" columns={2} spacing={2}>
        <Box>
          <Skeleton height={5} mb={3} />
          <Skeleton height={largeInputHeight} />
        </Box>
        <Box>
          <Skeleton height={5} mb={3} />
          <Skeleton height={largeInputHeight} />
        </Box>
      </SimpleGrid>
      <Box w="full">
        <Skeleton height={5} mb={3} />
        <Skeleton height={largeInputHeight} />
      </Box>
      <Box w="full">
        <Box>
          <Skeleton height={5} mb={3} />
          <Skeleton height={largeInputHeight} />
        </Box>
        <SimpleGrid w="full" columns={2}>
          <Skeleton height={largeInputHeight} />
          <Skeleton height={largeInputHeight} />
        </SimpleGrid>
      </Box>
      <Box w="full" pt={2}>
        <Skeleton height={12} width="13,625rem" mb={2} />
        <Skeleton height={4} />
      </Box>
    </VStack>
  );
}

export default CheckoutFormSkeleton;
