export function addParticipantToGrowsurf(data) {
  return new Promise(async function (resolve) {
    // If not loaded, do nothing
    if (!window.growsurf) {
      return resolve();
    }

    const referrerId = window.growsurf.getReferrerId();

    if (!referrerId) {
      return resolve();
    }

    const participant = await window.growsurf.addParticipant(data);

    resolve(participant);
  });
}
