import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

import useApp from "./AppContext";
import { Spoke } from "../types";

interface SpokeContextType {
  spokes: Spoke[];
  isLoadingSpokes: boolean;
  selectedSpoke?: Spoke;
  setSelectedSpoke: Dispatch<SetStateAction<Spoke | undefined>>;
}

const SpokeContext = createContext<SpokeContextType>({} as SpokeContextType);

export function SpokeProvider({ children }: { children: ReactNode }) {
  const [selectedSpoke, setSelectedSpoke] = useState<Spoke>();
  const { isLoadingSpokes, availableSpokes } = useApp();

  const memoedValue = useMemo(
    () => ({
      spokes: availableSpokes,
      isLoadingSpokes,
      selectedSpoke,
      setSelectedSpoke,
    }),
    [availableSpokes, isLoadingSpokes, selectedSpoke],
  );

  return (
    <SpokeContext.Provider value={memoedValue}>
      {children}
    </SpokeContext.Provider>
  );
}

export default function useSpoke() {
  const context = useContext(SpokeContext);

  if (context === undefined) {
    throw new Error("useSpoke must be used within a SpokeProvider");
  }

  return context;
}
