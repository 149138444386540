import { gql } from "@apollo/client";

import type {
  Brand,
  Customer,
  LeasePrice,
  Spoke,
  VehicleCategory,
} from "../types";

export interface SpokeCheckoutSessionData {
  spokeCheckoutData: SpokeCheckoutData;
}

export interface SpokeCheckoutData {
  accessoriesPriceCents: number;
  brand: Pick<Brand, "name">;
  customer: Customer;
  color: string;
  spoke: Pick<Spoke, "id">;
  vehicleCategory: VehicleCategory;
  model: string;
  size: string;
  vehicleRetailPriceCents: number;
  twelveMonthPrices: LeasePrice;
  twentyFourMonthPrices: LeasePrice;
  thirtySixMonthPrices: LeasePrice;
}

export const GET_SPOKE_CHECKOUT_DATA = gql`
  query SpokeCheckoutData($sessionToken: String!) {
    spokeCheckoutData(sessionToken: $sessionToken) {
      model
      size
      color
      description
      serialNumber
      customer {
        id
        firstName
        lastName
        phone
        email
        organization {
          showTheftInsurance: theftInsuranceEnabled
        }
      }
      token
      brand {
        name
      }
      spoke {
        id
      }
      vehicleCategory
      vehicleRetailPriceCents
      accessoriesPriceCents
      twelveMonthPrices {
        leaseMonthlyPriceCents
        buyoutPriceCents
        totalSavingsCents
      }
      twentyFourMonthPrices {
        leaseMonthlyPriceCents
        buyoutPriceCents
        totalSavingsCents
      }
      thirtySixMonthPrices {
        leaseMonthlyPriceCents
        buyoutPriceCents
        totalSavingsCents
      }
    }
  }
`;

export default GET_SPOKE_CHECKOUT_DATA;
