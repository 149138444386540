import { Box, Center, ChakraProps, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

import Card from "../Card";

type DialogWithHeaderProps = {
  headerTitle: string;
  children: ReactNode;
  cardProps?: ChakraProps;
};

export default function DialogWithHeader({
  headerTitle,
  children,
  cardProps = {},
}: DialogWithHeaderProps) {
  return (
    <Card
      width="8.25rem"
      height="6rem"
      position="relative"
      borderColor="#ECECEC"
      borderTopLeftRadius="lg"
      borderTopRightRadius="lg"
      display="flex"
      id="no1"
      innerProps={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "lg",
        overflow: "hidden",
        id: "no2",
        flex: 1,
      }}
      {...cardProps}
    >
      <Box
        background="#FAFAFA"
        textAlign="center"
        lineHeight="150%"
        border="1px"
        borderTop={0}
        borderLeft={0}
        borderRight={0}
        borderBottomColor="#ECECEC"
      >
        <Text textStyle="small-regular">{headerTitle}</Text>
      </Box>
      <Center
        zIndex={2}
        width="100%"
        borderBottomLeftRadius="lg"
        borderBottomRightRadius="lg"
        position="relative"
        backgroundColor="white"
        flex={1}
      >
        {children}
      </Center>
      <Box
        width="3rem"
        height="3rem"
        border="1px solid #ECECEC"
        left="50%"
        bottom="-25px"
        position="absolute"
        sx={{ transform: "rotate(45deg) translate(-69%, 0)" }}
        borderRadius={4.5}
        bg="white"
        zIndex={0}
      />
    </Card>
  );
}
