import { GridItem as ChakraGridItem, GridItemProps } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = { children?: ReactNode } & GridItemProps;

export default function GridItem({ children, ...props }: Props) {
  return (
    <ChakraGridItem colSpan={{ base: 1, md: 2 }} {...props}>
      {children}
    </ChakraGridItem>
  );
}
