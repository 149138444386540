import { extendTheme } from "@chakra-ui/react";
import { ComponentStyleConfig } from "@chakra-ui/theme";

import Tabs from "./components/Tabs";

const Button: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: "pandaRed",
  },
};

const Menu: ComponentStyleConfig = {
  baseStyle: {
    list: {
      rounded: "md",
      borderColor: "white",
      boxShadow: "0px 6px 16px rgb(47 52 58 / 10%)",
      padding: 3,
    },
    item: {
      borderRadius: "md",
    },
    divider: {
      borderColor: "gray.200",
      mx: "-13px",
    },
  },
};

const Badge: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: "pandaRed",
  },
};

const RadioCard: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: "pandaRed",
  },
  baseStyle: {
    colorScheme: "pandaRed",
    bg: "pandaRed.500",
    borderColor: "pandaRed.600",
  },
};

export default extendTheme({
  fonts: {
    heading: "'Work Sans', sans-serif;",
    body: "'Work Sans', sans-serif;",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "2rem",
    "4xl": "2.5rem",
    "5xl": "3.5rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.1,
    short: 1.2,
    base: 1.5,
    tall: 1.6,
    taller: 2,
  },
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    pandaRed: {
      50: "#FCEAE9",
      100: "#fac8b9",
      200: "#f0a68f",
      300: "#E97B72",
      400: "#E3564A",
      500: "#E05F36", // Default
      600: "#c7471f",
      700: "#9c3617",
      800: "#58130E",
      900: "#2C0A07",
    },
    darkGray: "#585858",
    lightGray: "#ECECEC",
    offWhite: "#FAFAFA",
  },
  textStyles: {
    display: {
      fontSize: "5xl",
      lineHeight: "shorter",
      fontWeight: "bold",
    },
    h1: {
      fontSize: "4xl",
      lineHeight: "shorter",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "3xl",
      lineHeight: "short",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "2xl",
      lineHeight: "short",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "xl",
      lineHeight: "short",
      fontWeight: "semibold",
    },
    h5: {
      fontSize: "md",
      lineHeight: "short",
      fontWeight: "semibold",
    },
    regular: {
      fontSize: "md",
      lineHeight: "tall",
    },
    "regular-link": {
      fontSize: "md",
      lineHeight: "tall",
      textDecor: "underline",
    },
    semibold: {
      fontSize: "md",
      lineHeight: "tall",
      fontWeight: "semibold",
    },
    "semibold-link": {
      fontSize: "md",
      lineHeight: "tall",
      fontWeight: "semibold",
      textDecor: "underline",
    },
    "large-regular": {
      fontSize: "lg",
      lineHeight: "tall",
    },
    "large-semibold": {
      fontSize: "lg",
      lineHeight: "tall",
      fontWeight: "semibold",
    },
    "small-regular": {
      fontSize: "sm",
      lineHeight: "base",
    },
    "small-regular-link": {
      fontSize: "sm",
      lineHeight: "tall",
      textDecor: "underline",
    },
    "small-semibold": {
      fontSize: "sm",
      lineHeight: "tall",
      fontWeight: "semibold",
    },
    "small-semibold-link": {
      fontSize: "sm",
      lineHeight: "tall",
      fontWeight: "semibold",
      textDecor: "underline",
    },
    "sub-heading": {
      fontSize: "lg",
      fontWeight: "medium",
    },
    label: {
      fontSize: "xs",
      lineHeight: "21px",
      fontWeight: "medium",
    },
  },
  components: {
    Button,
    RadioCard,
    Badge,
    Menu,
    Link: {
      variants: {
        pandaRed: {
          textStyle: "semibold-link",
          color: "pandaRed.500",
        },
      },
      baseStyle: {
        fontWeight: "semibold",
      },
    },
    Tabs,
    Text: {
      baseStyle: {
        textStyle: "regular",
      },
    },
  },
});
