import { gql } from "@apollo/client";

export type LeasePriceCalculatorDocType = {
  leasePriceCalculator: {
    buyoutPriceCents: string;
    leaseMonthlyPriceCents: string;
    totalSavingsCents: string;
  };
};

const LEASE_PRICE_CALCULATOR = gql`
  query calculator(
    $vehicleRetailPriceCents: Int!
    $accessoriesRetailPriceCents: Int!
    $employerSubsidyAmountCents: Int!
    $termLength: TermLength!
  ) {
    leasePriceCalculator(
      vehicleRetailPriceCents: $vehicleRetailPriceCents
      accessoriesRetailPriceCents: $accessoriesRetailPriceCents
      termLength: $termLength
      employerSubsidyAmountCents: $employerSubsidyAmountCents
    ) {
      buyoutPriceCents
      leaseMonthlyPriceCents
      totalSavingsCents
    }
  }
`;

export default LEASE_PRICE_CALCULATOR;
