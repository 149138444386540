import {
  Box,
  Button,
  Center,
  HStack,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

import LEGAL_URLS from "../../../constants/legalUrls";
import useAuth from "../../../hooks/useAuth";
import RidepandaTextIcon from "../../RidepandaTextIcon";
import Grid from "../Grid";
import GridItem from "../Grid/GridItem";

export default function Footer() {
  const { slug } = useParams<any>();
  const history = useHistory();
  const { user, setShowLoginModal } = useAuth();

  return (
    <Grid py={6} my={6} fontSize="sm">
      <GridItem>
        <HStack>
          <Box
            as="button"
            borderBottom="1px solid transparent"
            _hover={{ borderBottom: "1px solid #221203" }}
            onClick={() => history.push(`/${slug}`)}
          >
            <RidepandaTextIcon width="auto" height="18px" />
          </Box>
        </HStack>
      </GridItem>
      <GridItem>
        <Center h="100%">
          <Text
            textAlign={{ base: "right", md: "center" }}
            textStyle="small-regular"
          >
            All Rights Reserved © by Ridepanda
          </Text>
        </Center>
      </GridItem>
      <GridItem>
        <Stack
          alignItems={{ base: "start", md: "center" }}
          direction={{ base: "column", md: "row" }}
          h="100%"
          justify="end"
          spacing={{ base: 3, md: 6 }}
        >
          <Link href="https://www.ridepanda.com/about-us/" isExternal>
            About
          </Link>
          <Link href="https://ridepanda.breezy.hr/" isExternal>
            Careers
          </Link>
          <Link href={LEGAL_URLS.PRIVACY_POLICY} isExternal>
            Privacy
          </Link>
          {!user && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Button
              onClick={() => setShowLoginModal(true)}
              variant="link"
              colorScheme="black"
              fontSize="sm"
            >
              Login
            </Button>
          )}
        </Stack>
      </GridItem>
    </Grid>
  );
}
