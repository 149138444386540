import { Box, Image, Text, VStack } from "@chakra-ui/react";

import Card from "../Card";
import Grid from "../layout/Grid";
import GridItem from "../layout/Grid/GridItem";
import TestRideButton from "../TestRideButton";

export default function TestRideBanner() {
  return (
    <Card
      overflow="hidden"
      borderRadius={{ base: "none", md: "xl" }}
      border={{ base: "none", md: "1px solid lightGray" }}
    >
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(6, 1fr)" }}
        templateAreas={{
          base: `"image"
                 "content"`,
          lg: `"content image"`,
        }}
      >
        <GridItem colSpan={{ base: 2, lg: 4 }} area="image">
          <Box
            h="100%"
            display={{ base: "none", md: "block" }}
            position="relative"
          >
            <Image
              src="/assets/bikes-gradient-r.png"
              h="100%"
              objectFit="cover"
            />
            <Box
              position="absolute"
              w={50}
              top={0}
              right={0}
              bottom={0}
              bg="linear-gradient(90deg, rgba(237,206,176,0) 0%, rgba(255,255,255,1) 100%)"
            />
          </Box>

          <Box
            h={264}
            display={{ base: "block", md: "none" }}
            position="relative"
          >
            <Image
              src="/assets/bikes-gradient-b.png"
              h="264px"
              w="100%"
              objectFit="cover"
            />
            <Box
              position="absolute"
              h={50}
              left={0}
              right={0}
              bottom={0}
              bg="linear-gradient(180deg, rgba(237,206,176,0) 0%, rgba(255,255,255,1) 100%)"
            />
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, lg: 2 }} p={8} area="content">
          <VStack spacing={4} alignItems="start">
            <Text as="h3" textStyle="h3" textAlign="left">
              Not sure which vehicle is right for you?
            </Text>
            <Text>
              Book a test ride and a consultation with one of our experts to get
              you matched with a vehicle that&lsquo;s right for your lifestyle!
            </Text>
            <TestRideButton />
          </VStack>
        </GridItem>
      </Grid>
    </Card>
  );
}
