import { gql } from "@apollo/client";

import { CheckoutSession as LeaseCheckoutSession } from "./leases";
import { TermLengthType } from "../types";

export interface SpokeCheckoutSessionCreateInput {
  sessionToken: string;
  termLength: TermLengthType;
}

export type SpokeCheckoutSessionCreatePayload = {
  spokeCheckoutSessionCreate: LeaseCheckoutSession;
};

export const CREATE_SPOKE_CHECKOUT_SESSION_MUTATION = gql`
  mutation SpokeCheckoutSessionCreate(
    $sessionToken: String!
    $termLength: TermLength!
  ) {
    spokeCheckoutSessionCreate(
      sessionToken: $sessionToken
      termLength: $termLength
    ) {
      clientSecret
      clientSecretType
      total {
        displayName
        amount
      }
      taxAmount {
        displayName
        amount
      }
    }
  }
`;

export default CREATE_SPOKE_CHECKOUT_SESSION_MUTATION;
