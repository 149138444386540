import { datadogRum } from "@datadog/browser-rum";

import getEnv from "../utils/getEnv";

function initializeDatadogRum() {
  const environment = getEnv("DATA_ENV");

  if (environment === "development") {
    return;
  }

  // TODO: Add Datadog RUM configuration to ENV vars
  // This is just temporal to try the services

  datadogRum.init({
    applicationId: "f534564d-49ab-4064-b7a8-11fbab383e35",
    clientToken: "pub64e0b3d009b5e3916a98bbdef51bef92",
    site: "us5.datadoghq.com",
    service: "portal-classic",
    env: environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [/https:\/\/.*\.ridepanda\.com/],
    traceSampleRate: 100,
  });
}

export default initializeDatadogRum;
export { initializeDatadogRum };
