import {
  Box,
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

import Card from "../../../../components/Card";
import CurrencyText from "../../../../components/CurrencyText";
import RouterLink from "../../../../components/RouterLink";
import { Plan, Vehicle } from "../../../../types";

function PriceLabel({ plan }: { plan: Plan | undefined }) {
  if (!plan) {
    return <>-</>;
  }

  const { price, postSubsidyPrice } = plan;
  const subsidyApplied = postSubsidyPrice?.amount !== price?.amount;

  const primaryPrice = subsidyApplied ? postSubsidyPrice.amount : price.amount;

  return (
    <Flex textStyle="small-semibold">
      <CurrencyText
        value={parseInt(primaryPrice, 10)}
        currencyDisplay="narrowSymbol"
        suffix="/month"
      />

      {subsidyApplied && (
        <Box textDecor="line-through" color="gray" ml={2}>
          <CurrencyText
            value={parseInt(price.amount, 10)}
            currencyDisplay="narrowSymbol"
            suffix="/mo"
          />
        </Box>
      )}
    </Flex>
  );
}

type Props = {
  vehicle: Vehicle;
  routePath: string;
  onClick(selectedVehicle: Vehicle): void;
};

export default function VehicleItem({ vehicle, routePath, onClick }: Props) {
  return (
    <LinkBox
      as={Card}
      p={6}
      h="100%"
      innerProps={{ h: "100%" }}
      onClick={() => onClick(vehicle)}
      className="vehicle-item"
    >
      {vehicle.inStock && (
        <Box
          bg="linear-gradient(110deg, #E05F36, #E05F36 90%, #ffffff00 90%, #ffffff00)"
          position="absolute"
          left={0}
          top={17}
          pl={3}
          pr={6}
          py={1}
        >
          <Text
            color="white"
            textTransform="uppercase"
            fontWeight={500}
            fontSize="xs"
          >
            pick up in 1-2 days
          </Text>
        </Box>
      )}
      {vehicle.coreModel && (
        <Box
          bg="linear-gradient(110deg, #2E2A27, #2E2A27 90%, #ffffff00 90%, #ffffff00)"
          position="absolute"
          left={0}
          top={vehicle.inStock ? "2.71875rem" : 17}
          pl={3}
          pr={6}
          py={1}
        >
          <Text
            color="white"
            textTransform="uppercase"
            fontWeight={500}
            fontSize="xs"
          >
            Most popular
          </Text>
        </Box>
      )}
      <Flex direction="column" justify="space-between" h="100%">
        <Flex w="100%" direction="column">
          <Image
            src={vehicle.variants[0].image.url}
            boxSize="150px"
            objectFit="contain"
            rounded="base"
            w="100%"
            h={230}
          />
        </Flex>
        <Flex direction="column" justify="space-between" flex={1} mt={7}>
          <Text as="h3" textStyle="h3" textAlign="center">
            <LinkOverlay
              as={RouterLink}
              to={routePath}
              title={`${vehicle.brand} ${vehicle.model}`}
              fontWeight="bold"
            >
              {vehicle.brand} {vehicle.model}
            </LinkOverlay>
          </Text>
          <Stack
            align="center"
            justifyContent="center"
            fontSize="sm"
            mt={1}
            direction="column"
          >
            <Text as="span" fontSize="xs" textTransform="uppercase">
              {vehicle.shortDescription}
            </Text>
            <PriceLabel plan={vehicle.plan} />
          </Stack>
        </Flex>
      </Flex>
    </LinkBox>
  );
}
