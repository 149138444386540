/* eslint-disable react/no-unescaped-entities */
import { Heading, Text, Button, Link, Center } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import PandaLogo from "../PandaLogo";

export default function NotFound() {
  return (
    <Center textAlign="center" py={10} px={6} flexDirection="column">
      <PandaLogo boxSize={12} my={4} />

      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, green.400, green.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color="darkGray" mb={6}>
        The page you're looking for does not seem to exist
      </Text>

      <Link as={RouterLink} to="/" _hover={{ textDecoration: "none" }}>
        <Button
          bgGradient="linear(to-r, green.400, green.500, green.600)"
          color="white"
          variant="solid"
          onClick={() => {}}
        >
          Go to Home
        </Button>
      </Link>
    </Center>
  );
}
