import { Button } from "@chakra-ui/react";
import { openPopupWidget } from "react-calendly";

import useApp from "../../contexts/AppContext";
import { tracker } from "../../services/tracking";

const openCalendlyLink = (testRideUrl: string | undefined) => {
  if (!testRideUrl || !testRideUrl.length) {
    return;
  }

  tracker.trackEvent("Vehicle Test Ride Calendly Popup Clicked");
  openPopupWidget({ url: testRideUrl });
};

export default function TestRideButton() {
  const { selectedLocation } = useApp();

  return (
    <Button
      size="lg"
      variant="outline"
      colorScheme="black"
      _hover={{
        bg: "lightGray",
      }}
      _active={{ bg: "gray.300" }}
      onClick={() => openCalendlyLink(selectedLocation?.hub.testRideUrl)}
      w={{ base: "100%", lg: "inherit" }}
    >
      Schedule a test ride
    </Button>
  );
}
