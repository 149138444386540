import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import SpokeCalculator from "../../domain/SpokeCalculator";

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export default function LeaseCalculatorModal({ isOpen, onClose }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="container.lg">
        <ModalCloseButton
          border="1px solid #ECECEC"
          borderRadius="full"
          top={8}
          right={8}
          boxSize="44px"
          display={{ base: "none", sm: "inherit" }}
        />

        <ModalBody my={5} mx={3}>
          <SpokeCalculator useLayout={false} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
